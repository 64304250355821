import React from 'react';
import { useSelector } from 'react-redux';
import { portalServiceFeeFreeMonth } from '../../../config';
import { strings } from '../../../constants/localization';

import { Button } from 'reactstrap';
import {
  formatDanishWithoutFractionDigits,
  getPaymentFreeMonths,
} from '../../../constants/utils';
import { ReactComponent as ArrowLink } from '../../../resources/icons/arrow_restructure.svg';

type PaymentFreeMonthAgreement = {
  onNextStep: () => void;
  closeModal?: () => void;
};
interface SundhedCreditProps {
  creditFacilityData: {
    status: string;
    amountAvailable: number;
    expirationDate: string;
    interestRate: number;
    amount: number;
    dunning: boolean;
    startDateOfFirstLoanInSeries: string;
    numberOfPaidMonthsSinceTheStartDateOfFirstLoanInSeries: number;
    creditCardData: {
      status: string;
      expirationDate: string;
    };
    numberOfReepayInvoicesToday: number;
    installmentLoanDataList: [
      {
        number: string;
        status: string;
      }
    ];
    paymentData: {
      latestPaymentAmount: number;
      nextPaymentAmount: number;
    };
  };
  clientData: {
    uuid: string;
  };
}

const PaymentFreeMonthAgreement: React.FC<PaymentFreeMonthAgreement> = ({
  onNextStep,
  closeModal,
}) => {
  const creditInformation = useSelector(
    (state: { memberPortal: { clientDataInformation: SundhedCreditProps } }) =>
      state.memberPortal?.clientDataInformation
  );

  const {
    startDateOfFirstLoanInSeries,
    numberOfPaidMonthsSinceTheStartDateOfFirstLoanInSeries,
  } = creditInformation.creditFacilityData || {};
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
  };
  const longOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
  };
  const firstDayCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).toLocaleDateString('da-DK', options);
  const firstDayNextMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  ).toLocaleDateString('da-DK', options);
  const firstDayFollowingMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 2,
    1
  ).toLocaleDateString('da-DK', options);
  const upcomingMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  ).toLocaleDateString('da-DK', longOptions);

  return (
    <div className='step-content'>
      <h1 className='step-title'>{strings.mitPaymentFreeHeadline}</h1>
      <div className='subtitle'>
        <p>{strings.formatString(strings.mitPaymentFreeP1, <br />)}</p>
      </div>

      <div className='free-month-info'>
        <p className='headline'>
          {strings.formatString(
            strings.mitPaymentFreeQuestion,
            <b>{upcomingMonth}</b>
          )}
        </p>
        <div className='free-month-info-content'>
          <ArrowLink />
          <div className='date-boxes'>
            <div className='date-box'>{firstDayCurrentMonth || '-'}</div>
            <div className='date-box middle'>{firstDayNextMonth || '-'}</div>
            <div className='date-box'>{firstDayFollowingMonth || '-'}</div>
          </div>
        </div>
      </div>

      <div className='free-month-banner'>
        <span>{strings.mitPaymentFreeBanner}</span>
        <span>
          {strings.formatString(
            strings.mitPaymentFreeBannerCounter,
            getPaymentFreeMonths(
              startDateOfFirstLoanInSeries,
              numberOfPaidMonthsSinceTheStartDateOfFirstLoanInSeries
            ) ?? '-'
          )}
        </span>
      </div>

      <div className='subtitle'>
        <p>{strings.mitPaymentFreeP2}</p>
        <br />
        <p>
          {strings.formatString(
            strings.mitPaymentFreeP3,
            formatDanishWithoutFractionDigits(portalServiceFeeFreeMonth)
          )}
        </p>
      </div>
      <div className='step-actions'>
        <Button color='light' className='reject' onClick={() => closeModal()}>
          {strings.mitPaymentFreeCancelBtn}
        </Button>
        <Button color='blue' className='continue' onClick={() => onNextStep()}>
          {strings.mitPaymentFreeNextBtn}
        </Button>
      </div>
    </div>
  );
};

export default PaymentFreeMonthAgreement;
