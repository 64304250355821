import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FileSaver from 'file-saver';
import { moment, momentFormat } from '../../../constants';
import KebabMenu from '../../common/KebabMenu/KebabMenu';
import { ReactComponent as UserIcon } from '../../../resources/icons/user-alt-5.svg';
import { ReactComponent as BarChart } from '../../../resources/icons/lni_lni-bar-chart-1.svg';
import { ReactComponent as Crown } from '../../../resources/icons/download 1.svg';
import { ReactComponent as ApproveIcon } from '../../../resources/icons/lni_lni-checkmark.svg';
import { ReactComponent as DenyIcon } from '../../../resources/icons/lni_lni-thumbs-down.svg';
import { ReactComponent as InfoCircle } from '../../../resources/icons/info-circle.svg';
import { ReactComponent as SearchDbIcon } from '../../../resources/icons/search-database.svg';
import { ReactComponent as RotateIcon } from '../../../resources/icons/rotate-right.svg';
import { applicationStatusMap } from '../../../constants/mappings';
import { strings } from '../../../constants/localization';
import { Sundhedplus, config } from '../../../config';
import { getReasonFromString } from '../../../constants/utils';
import {
  getEskatDataPreviewLink,
  getNoitsoApplicationData,
  getMonthioApplicationData,
  setCurrentApplicationItem,
  updateApplicationInApplicationList,
  setApplicationLoading,
  downloadMonthioPDF,
  continueCreditApplication,
} from '../../../actions/onboardingApplicationsActions';
import { lookupClientCreditByCpr } from '../../../actions/onboardingActions';
import {
  toggleServerModalError,
  setServerModalError,
} from '../../../actions/uiElementsActions';
import { ROUTE_LEADS } from '../../../constants/routes';
import ActivateCreditModal from './modals/ActivateCreditModal';
import DenyCreditModal from './modals/DenyCreditModal';
import ApplicationTimeline from './ApplicationTimeline';
import ApplicationEndpointRequestLoadingModal from './modals/ApplicationEndpointRequestLoadingModal';
import ApplicationEndpointRequestFailedModal from './modals/ApplicationEndpointRequestFailedModal';
import ApplicationEndpointRequestSuccessModal from './modals/ApplicationEndpointRequestSuccessModal';

const RequestStatus = {
  Idle: 'idle',
  Loading: 'loading',
  Success: 'success',
  Failed: 'failed',
};

const ApplicationMasterData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [eskatDataPreviewLink, setEskatDataPreviewLink] = useState('');
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const {
    clientData,
    creditApplication,
    payslips,
    budgetInformation,
    paymentRemarks,
  } = activeApplicationListItem || {};
  const {
    creditApplicationId,
    creditApplicationType,
    creditApplicationStatus,
    createdDate,
    creditApplicationUuid,
    reason,
  } = creditApplication || {};
  const { finalBudget, caseId, revisedBudget } = budgetInformation || {};
  const { cpr, leadUuid, email } = clientData || {};
  const [statusReasonData, setStatusReasonData] = useState(
    getReasonFromString(reason) || ''
  );
  const localTime = moment.utc(createdDate).local();
  const applicationCreatedDate = localTime.format(momentFormat);
  const [paymentRemarksStatus, setPaymentRemarksStatus] = useState(
    RequestStatus.Idle
  );
  const [downloadPDFStatus, setDownloadPDFStatus] = useState(
    RequestStatus.Idle
  );
  const [continueApplicationStatus, setContinueApplicationStatus] = useState(
    RequestStatus.Idle
  );

  useEffect(() => {
    setStatusReasonData(getReasonFromString(reason));
  }, [reason]);

  useEffect(() => {
    if (payslips && payslips?.length > 0) {
      dispatch(getEskatDataPreviewLink(cpr, creditApplicationUuid))
        .then((res) => {
          if (res?.data) {
            setEskatDataPreviewLink(res.data);
          }
        })
        .catch((err) => {
          console.log('Could not retrieve eskat data preview link', err);
        });
    }
  }, [payslips]);

  const refreshApplicationData = () => {
    dispatch(setApplicationLoading(true));

    if (creditApplicationType === null || creditApplicationType === 'MONTHIO') {
      dispatch(getMonthioApplicationData(creditApplicationUuid))
        .then((res) => {
          const newApplicationStatus =
            res?.data?.creditApplication?.creditApplicationStatus;
          dispatch(setCurrentApplicationItem(res?.data || {}));
          if (newApplicationStatus !== creditApplicationStatus) {
            dispatch(
              updateApplicationInApplicationList(
                res?.data?.creditApplication?.creditApplicationStatus
              )
            );
          }
        })
        .finally(() => {
          dispatch(setApplicationLoading(false));
        });
    } else {
      dispatch(getNoitsoApplicationData(creditApplicationUuid))
        .then((res) => {
          const newApplicationStatus =
            res?.data?.creditApplication?.creditApplicationStatus;
          dispatch(setCurrentApplicationItem(res?.data || {}));
          if (newApplicationStatus !== creditApplicationStatus) {
            dispatch(
              updateApplicationInApplicationList(
                res?.data?.creditApplication?.creditApplicationStatus
              )
            );
          }
        })
        .finally(() => {
          dispatch(setApplicationLoading(false));
        });
    }
  };

  const renderMenuBudgetUrlBasedOnType = () => {
    // if the application is a monthio application, we need to check if the case id is present
    if (
      (creditApplicationType === null || creditApplicationType === 'MONTHIO') &&
      caseId
    ) {
      return (
        <div
          type='button'
          color='light'
          className='KebabMenu-item application'
          onClick={() => {
            const monthioUrl = config.monthioBudgetDataUrl.replace(
              '$0',
              caseId
            );
            window.open(`${monthioUrl}`, '_blank');
          }}
        >
          <span className='icon'>
            <i className='lnir lnir-reply' />
          </span>
          <span className='text'>{strings.applicationKebabMenuItem2v2}</span>
        </div>
      );
    } else if (
      finalBudget &&
      finalBudget?.length > 0 &&
      budgetInformation?.applicationId !== null
    ) {
      return (
        <div
          type='button'
          color='light'
          className='KebabMenu-item application'
          onClick={() => {
            const noitsoUrl = Sundhedplus.noitsoLink.replace(
              '$0',
              budgetInformation?.applicationId
            );
            window.open(`${noitsoUrl}`, '_blank');
          }}
        >
          <span className='icon'>
            <BarChart />
          </span>
          <span className='text'>{strings.applicationKebabMenuItem2}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const continueApplication = () => {
    if (creditApplicationStatus === 'IN_PROGRESS') {
      return (
        <div
          type='button'
          color='light'
          className='KebabMenu-item application'
          onClick={() => {
            setContinueApplicationStatus(RequestStatus.Loading);
            dispatch(continueCreditApplication(creditApplicationUuid))
              .then(() => {
                setContinueApplicationStatus(RequestStatus.Success);
              })
              .catch((error) => {
                dispatch(setServerModalError(error.response));
                dispatch(toggleServerModalError());
                setContinueApplicationStatus(RequestStatus.Failed);
              });
          }}
        >
          <span className='icon'>
            <RotateIcon />
          </span>
          <span className='text'>{strings.applicationKebabMenuItem6}</span>
        </div>
      );
    }
    return null;
  };

  const renderCheckPaymentRemarks = () => {
    if (
      leadUuid &&
      creditApplicationUuid &&
      paymentRemarks === null &&
      revisedBudget?.length > 0
    ) {
      return (
        <div
          type='button'
          color='light'
          className='KebabMenu-item application'
          onClick={() => {
            setPaymentRemarksStatus(RequestStatus.Loading);
            dispatch(
              lookupClientCreditByCpr(null, creditApplicationUuid, leadUuid)
            )
              .then(() => {
                setPaymentRemarksStatus(RequestStatus.Success);
              })
              .catch((error) => {
                dispatch(setServerModalError(error.response));
                dispatch(toggleServerModalError());
                setPaymentRemarksStatus(RequestStatus.Failed);
              });
          }}
        >
          <span className='icon'>
            <SearchDbIcon style={{ marginLeft: '-3px' }} />
          </span>
          <span className='text'>{strings.applicationKebabMenuItem7}</span>
        </div>
      );
    }
    return null;
  };

  const renderDownloadMonthioPDF = () => {
    if (revisedBudget?.length > 0) {
      return (
        <div
          type='button'
          color='light'
          className='KebabMenu-item application'
          onClick={() => {
            setDownloadPDFStatus(RequestStatus.Loading);
            dispatch(downloadMonthioPDF(creditApplicationUuid))
              .then((response) => {
                setDownloadPDFStatus(RequestStatus.Success);
                if (response.data) {
                  const blob = new Blob([response.data], {
                    type: 'application/pdf',
                  });
                  if (blob) {
                    FileSaver(
                      blob,
                      `${creditApplicationUuid}_budgetOutput.pdf`
                    );
                  }
                }
              })
              .catch(() => {
                setDownloadPDFStatus(RequestStatus.Failed);
              });
          }}
        >
          <span className='icon'>
            <BarChart />
          </span>
          <span className='text'>{strings.applicationKebabMenuItem8}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <section
      id='application-master-data'
      className='entity-information-card custom-card card'
    >
      <div className='card-body'>
        <div className='header-information'>
          <div className='header-text'>
            <div className='card-title'>{creditApplicationId}</div>
            <span className='application-created'>
              {strings.applicationItemDate} {applicationCreatedDate}
            </span>
            <div
              className={`application-status ${applicationStatusMap[creditApplicationStatus]?.className}`}
            >
              <span>
                {
                  strings[
                    applicationStatusMap[creditApplicationStatus]?.statusText
                  ]
                }
              </span>
            </div>
            <div className='d-flex actions-wrapper header-actions'>
              <div
                className='refreshButton'
                onClick={() => refreshApplicationData()}
              >
                <i className='lnir lnir-reload icon' />
              </div>
              <KebabMenu>
                {cpr && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item application'
                    onClick={() => {
                      history.push({
                        pathname: ROUTE_LEADS,
                        search: clientData?.leadUuid
                          ? `?q=${cpr}`
                          : `?q=${email}`,
                      });
                    }}
                  >
                    <span className='icon'>
                      <UserIcon />
                    </span>
                    <span className='text'>
                      {strings.documentationMemberProfileButton}
                    </span>
                  </div>
                )}

                {renderMenuBudgetUrlBasedOnType()}
                {renderCheckPaymentRemarks()}
                {renderDownloadMonthioPDF()}

                {eskatDataPreviewLink !== '' && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item application'
                    onClick={() => {
                      window.open(eskatDataPreviewLink, '_blank');
                    }}
                  >
                    <span className='icon'>
                      <Crown />
                    </span>
                    <span className='text'>
                      {strings.applicationKebabMenuItem3}
                    </span>
                  </div>
                )}

                {continueApplication()}

                {[
                  'PENDING_DOCUMENTATION',
                  'PENDING_ACTIVATION',
                  'PENDING_MANUAL',
                  'MANUAL_INTERVENTION',
                ].indexOf(creditApplicationStatus) >= 0 && (
                  <div className='application-last-menu-items'>
                    <div
                      type='button'
                      color='light'
                      className='KebabMenu-item application'
                      onClick={() => {
                        setShowApproveModal(true);
                      }}
                    >
                      <span className='icon'>
                        <ApproveIcon />
                      </span>
                      <span className='text'>
                        {strings.applicationKebabMenuItem4}
                      </span>
                    </div>
                    <div
                      type='button'
                      color='light'
                      className='KebabMenu-item application'
                      onClick={() => {
                        setShowDenyModal(true);
                      }}
                    >
                      <span className='icon'>
                        <DenyIcon />
                      </span>
                      <span className='text'>
                        {strings.applicationKebabMenuItem5}
                      </span>
                    </div>
                  </div>
                )}
                {['IN_PROGRESS'].indexOf(creditApplicationStatus) >= 0 && (
                  <div className='application-last-menu-items'>
                    <div
                      type='button'
                      color='light'
                      className='KebabMenu-item application'
                      onClick={() => {
                        setShowDenyModal(true);
                      }}
                    >
                      <span className='icon'>
                        <DenyIcon />
                      </span>
                      <span className='text'>
                        {strings.applicationKebabMenuItem5}
                      </span>
                    </div>
                  </div>
                )}
              </KebabMenu>
            </div>
          </div>
        </div>

        <ApplicationTimeline />

        {reason && (
          <div className='application-reason-wrapper'>
            <div className='application-status-reason'>
              {creditApplicationStatus !== 'DONE' && (
                <span className='icon'>
                  <InfoCircle className='info-circle' />
                </span>
              )}
              {creditApplicationStatus === 'DONE' && (
                <span className='icon done'>
                  <i className='lnir lnir-checkmark-circle' />
                </span>
              )}

              <span className='reason'>
                {typeof statusReasonData === 'object'
                  ? statusReasonData?.reason
                  : reason}
              </span>
            </div>
            {typeof statusReasonData === 'object' && (
              <div className='metadata'>
                <span>{statusReasonData?.user}</span>
                <span>{statusReasonData?.timestamp}</span>
              </div>
            )}
          </div>
        )}

        {showApproveModal && (
          <ActivateCreditModal
            closeModal={() => setShowApproveModal(false)}
            reloadApplication={() => refreshApplicationData()}
          />
        )}

        {showDenyModal && (
          <DenyCreditModal
            closeModal={() => setShowDenyModal(false)}
            reloadApplication={() => refreshApplicationData()}
          />
        )}

        {/* LOADING RequestStatus */}
        {(paymentRemarksStatus === RequestStatus.Loading ||
          downloadPDFStatus === RequestStatus.Loading ||
          continueApplicationStatus === RequestStatus.Loading) && (
          <ApplicationEndpointRequestLoadingModal />
        )}

        {/* CONTINUE APPLICATION SUCCESS/FAILED */}
        {continueApplicationStatus === RequestStatus.Success && (
          <ApplicationEndpointRequestSuccessModal
            reloadApplication={() =>
              setContinueApplicationStatus(RequestStatus.Idle)
            }
            headline={strings.continueApplicationSuccessHeadline}
            subtitle={strings.continueApplicationSuccessMessage}
          />
        )}
        {continueApplicationStatus === RequestStatus.Failed && (
          <ApplicationEndpointRequestFailedModal
            onClose={() => setContinueApplicationStatus(RequestStatus.Idle)}
            headline={strings.continueApplicationFailedHeadline}
            subtitle={strings.continueApplicationFailedMessage}
          />
        )}

        {/* PAYMENT REMARKS SUCCESS/FAILED */}
        {paymentRemarksStatus === RequestStatus.Success && (
          <ApplicationEndpointRequestSuccessModal
            reloadApplication={() => refreshApplicationData()}
            headline={strings.paymentRemarksSuccessHeadline}
            subtitle={strings.paymentRemarksSuccessMessage}
          />
        )}
        {paymentRemarksStatus === RequestStatus.Failed && (
          <ApplicationEndpointRequestFailedModal
            onClose={() => setPaymentRemarksStatus(RequestStatus.Idle)}
            headline={strings.paymentRemarksFailedHeadline}
            subtitle={strings.paymentRemarksFailedMessage}
          />
        )}
      </div>
    </section>
  );
};

export default ApplicationMasterData;
