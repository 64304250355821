import axios from 'axios';
import xirr from 'xirr';
import Bowser from 'bowser';
import { Buffer } from 'buffer';
import {
  toggleServerModalError,
  setServerModalError,
  incrementOngoingRequestsCounter,
  decrementOngoingRequestsCounter,
} from '../actions/uiElementsActions';
import { store } from '../store';
import { ROUTE_MAIN_LOGIN } from '../constants/routes';

import {
  moment,
  AUTH_USER,
  //expirationTime,
  ROLE_ADMIN,
  ROLE_MANAGER,
  ROLE_DENTIST,
  CURRENCY_FORMAT,
} from '.';
import { timeoutMinutesSessionExpiration } from '../config';
import { strings } from './localization';
import LocalizedStrings from 'react-localization';
import da from '../constants/i18n/da';

export const requestAxios = axios.create();

// Add a request interceptor to increment the ongoingRequests count
requestAxios.interceptors.request.use(
  (config) => {
    store.dispatch(incrementOngoingRequestsCounter());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
requestAxios.interceptors.response.use(
  (response) => {
    store.dispatch(decrementOngoingRequestsCounter());
    return response;
  },
  (error) => {
    if (error.response) {
      const isUserSessionExpired =
        error.response.status === 403 &&
        (JSON.stringify(error.response?.data) === '{}' ||
          error.response?.data === '');

      // verification if server didn't sent an "Unauthorized" response code
      // or if request is empty 403, then the user session has expired
      if (isUserSessionExpired) {
        store.dispatch({ type: AUTH_USER, payload: false });
        localStorage.clear();
        localStorage.setItem('showSessionExpiredWarning', true);
        window.location.replace(ROUTE_MAIN_LOGIN);
      }

      // check for errors and show the details into modal
      if (
        !(
          window.location.pathname === ROUTE_MAIN_LOGIN &&
          (error.response.status === 400 || error.response.status === 423) &&
          isUserSessionExpired
        )
      ) {
        store.dispatch(setServerModalError(error.response));
        store.dispatch(toggleServerModalError(true));
      }
    }
    // check for server down error and show the server error modal, only if the user is not on login page
    else if (error.message === 'Network Error') {
      store.dispatch(setServerModalError(error.message));
    } else {
      store.dispatch(setServerModalError(error.response));
    }

    // decrement the ongoing requests count
    store.dispatch(decrementOngoingRequestsCounter());
    // Do something with response error
    return Promise.reject(error);
  }
);

export function isAuthenticated() {
  const now = moment.now();
  const isAuth = JSON.parse(localStorage.getItem('isAuth'));
  const loginTime = JSON.parse(localStorage.getItem('loginTime'));

  if (isAuth !== null && loginTime !== null) {
    if (
      isAuth === true &&
      now - loginTime < timeoutMinutesSessionExpiration * 60 * 1000
    ) {
      return true;
    }
  }

  if (store !== undefined) {
    store.dispatch({ type: AUTH_USER, payload: false });
  }

  return false;
}

function checkIfUserHasRole(user, userRole) {
  if (user) {
    const activeCompany = user.activeCompany;
    if (activeCompany) {
      for (const [key, value] of Object.entries(user.companyRoles)) {
        if (key.toString() === activeCompany && value.indexOf(userRole) > -1) {
          return true;
        }
      }
    }
  }
  return false;
}

export function isAdmin(user) {
  return checkIfUserHasRole(user, ROLE_ADMIN);
}

export function isManager(user) {
  return checkIfUserHasRole(user, ROLE_MANAGER);
}

export function isDentist(user) {
  return checkIfUserHasRole(user, ROLE_DENTIST);
}

export function isDeletedOrDeprecated(status) {
  return ['DELETED', 'DEPRECATED'].indexOf(status) >= 0;
}

export function isDeletedOrNotAcceptedOrWaiting(status) {
  return (
    ['DELETED', 'NOT_ACCEPTED', 'WAITING_FOR_SIGNATURE'].indexOf(status) >= 0
  );
}

export function showDelete(user, invoiceStatus) {
  if (isAdmin(user)) {
    return true;
  }
  if (isDentist(user) || isManager(user)) {
    if (
      invoiceStatus === 'WAITING_FOR_SIGNATURE' ||
      invoiceStatus === 'NOT_ACCEPTED'
    ) {
      return true;
    }

    return false;
  }

  return false;
}

export function getLoggedInUserDetails() {
  const userObj = JSON.parse(localStorage.getItem('user'));
  return userObj;
}

export function validateEmail(value) {
  const validEmailRegex = RegExp(
    /^(([^<>()\],;:\s@]+([^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i
  );
  return validEmailRegex.test(value);
}

export function numberWithDotAsThousandsSeparator(value) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
  num
) {
  const parts = num
    .toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .split(',');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimalPart = parts[1] ? ',' + parts[1] : '';
  return integerPart + decimalPart;
}

export function newNumberWithDotComaAndDecimals(num) {
  const parts = num
    .toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .split(',');

  let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // If there's a decimal part and it contains a dot, replace it with a comma
  let decimalPart = '';
  if (parts[1]) {
    if (parts[1].includes('.')) {
      decimalPart = ',' + parts[1].replace('.', ',');
    } else {
      decimalPart = ',' + parts[1];
    }
  }

  return integerPart + decimalPart;
}

export function removeCurrencyFormattingForOnFocus(e, setValue) {
  if (e.target.value.trim() === '0,00 kr.' || !e.target.value) {
    setValue('');
  } else {
    const value = e.target.value.toString();
    const currencyFormattedValue = value
      .replace(/ kr.$/, '')
      .replaceAll('.', '');
    const hasDecimalPart = value.includes(',');
    const hasDecimalValue = value.split(',')[1] && value.split(',')[1] !== '00';
    if (hasDecimalPart && hasDecimalValue) {
      setValue(currencyFormattedValue.replace(/,00$/, ''));
    } else if (hasDecimalPart && !hasDecimalValue) {
      setValue(currencyFormattedValue);
    } else {
      setValue(currencyFormattedValue);
    }
  }
}

export function removeCurrencyOnFocus(value) {
  if (value.trim() === '0,00 kr.' || !value) {
    return '';
  } else {
    const currencyFormattedValue = value
      .replace(/ kr.$/, '')
      .replaceAll('.', '');
    const hasDecimalPart = value.includes(',');
    const hasDecimalValue = value.split(',')[1] && value.split(',')[1] !== '00';
    if (hasDecimalPart && hasDecimalValue) {
      return currencyFormattedValue.replace(/,00$/, '');
    } else if (hasDecimalPart && !hasDecimalValue) {
      return currencyFormattedValue;
    } else {
      return currencyFormattedValue;
    }
  }
}

export function formatCreditCardNumber(value) {
  if (value) {
    return value.replace(
      /^(\d{4})(\d{2})\d{2}(\d{4})(\d{4})$/,
      '$1 $2xx xxxx $4'
    );
  }
  return '';
}

export function formatCreditCardWithMaskNumberAlreadyMasked(value) {
  if (value) {
    return value.replace(/(.{4})/g, '$1 ').trim();
  }
  return '';
}

export function currencyFormatDA(
  num,
  currency = true,
  currencyBack = false,
  hasDecimal = true
) {
  let value = 0;
  let addDecimals = true;
  if (num !== null && (num * 100) % 100 === 0 && !hasDecimal) {
    addDecimals = false;
  }
  if (num !== null) {
    // use Math.floor for rounding down
    const factor = 10 ** (addDecimals ? 2 : 0);
    value = Math.round(num * factor) / factor;

    value = value
      .toFixed(addDecimals ? 2 : 0) // ensure two decimal digits
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  // use . as a separator
  if (currency) {
    if (currencyBack) {
      return `${value}\xa0${CURRENCY_FORMAT}`;
    }

    return `${CURRENCY_FORMAT} ${value}`;
  }

  return value;
}

export function loanCalculatorFormatAmounts(num) {
  //(593.5775167638843).toFixed()
  let value = 0;
  if (num !== null) {
    value = num
      .toFixed()
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  return `${value}\xa0${CURRENCY_FORMAT}`;
}
// Form validation
export const required = (value) => (value ? undefined : strings.required);
const fixedLength = (fixed) => (value) => {
  if (value !== undefined && value !== null) {
    return fixed && value.toString().length !== fixed
      ? strings.formatString(strings.numberOfCharacters, fixed)
      : undefined;
  }
  return undefined;
};

export const fixedLength4 = fixedLength(4);
// export const fixedLength8 = fixedLength(8);
export const fixedLength10 = fixedLength(10);
export const letters = (value) =>
  value &&
  /[^a-zA-Z âäàåăâáçêëèéïîìíæÆôöòóûüùúÿµ¢ñțș£¥ÑßŠŒšœŸÀÁÂÃÄÅÂĂÈÉÊËÌÍÎÏÐÇÒÓÔÕÖØÙÚÛÜÝÞãðõøýþȘȚ'`-]+$/i.test(
    value
  )
    ? strings.onlyLetters
    : undefined;
export const lettersSpecialCharacters = (value) =>
  value && /[^a-zA-Z åæéøÅÆÉØ ()/.,?!:@$%&_+#*=-]/i.test(value)
    ? strings.onlyLetters
    : undefined;

export const lettersWithDot = (value) =>
  value && /[^a-zA-Z åæéøÅÆÉØ .]/i.test(value)
    ? strings.onlyLetters
    : undefined;
export const digits = (value) =>
  value && /[^0-9 ,]/i.test(value) ? strings.onlyDigits : undefined;

export const digitsWithDecimal = (value) =>
  value && /[^0-9 .,]/i.test(value)
    ? 'Only digits and a decimal point are allowed'
    : undefined;

export const emailValidation = (value) =>
  value &&
  !/^(?!.*\.\.)[A-Z0-9åæéøÅÆÉØ][A-Z0-9åæéøÅÆÉØ._%+-]*(?<!\.)@[A-Z0-9åæéøÅÆÉØ-]+(?:\.[A-Z]{2,4}){1,2}$/i.test(
    value
  )
    ? strings.invalidEmail
    : undefined;

export const isValidURL = (string) => {
  let res = null;
  if (string) {
    res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    );
  }

  return res !== null;
};

export const getURLFileExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

export function phoneNoFormat(phone) {
  if (hasDefinedValue(phone)) {
    return phone
      .toString()
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{2})/g, '$1 ')
      .trim();
  }
  return phone;
}

export function phoneNoFormatWithPrefix(phone) {
  if (hasDefinedValue(phone)) {
    const prefix = phone.charAt(0) === '+' ? '+' : '';
    const number = phone
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{2})/g, '$1 ')
      .trim();
    return prefix + number;
  }
  return phone;
}

export function isDanishNumber(value) {
  return /^[0-9]{8}$/.test(normalizePhone(value));
}

export function formatZipWithoutLetters(value, maxLength) {
  if (value !== undefined) {
    return value?.replaceAll(/[^0-9]+/g, '').substring(0, maxLength);
  }
}

export function formatDecimalsSpace(value, grouping) {
  if (grouping === undefined) {
    grouping = 2;
  }
  const spacingRegex = `/(.{${grouping}})/g, "$1 "`;
  if (value !== undefined) {
    let valueToTest = value
      ?.replaceAll(/[^\dA-Z]/g, '')
      ?.replaceAll(spacingRegex)
      ?.replaceAll(' ', '');
    return phoneNoFormat(valueToTest);
  }
}

export function formatDecimalsSpaceBetween(value, curentValue) {
  //return value.split(/(\d{2})/).join(' ').trim();
  let valueToTest = '';
  if (value !== undefined) {
    let valueToTest = value
      .replaceAll(/[^\dA-Z]/g, '')
      .replaceAll(/(.{2})/g, '$1 ')
      .replaceAll(' ', '');
    if (valueToTest.replaceAll(' ', '').length < 9) {
      return phoneNoFormat(valueToTest);
    }
  }
  return phoneNoFormat(curentValue) || phoneNoFormat(valueToTest);
}

export function webAddressFormat(webAddress) {
  if (hasDefinedValue(webAddress) && webAddress.length > 4) {
    if (webAddress.startsWith('http') || webAddress.startsWith('https')) {
      return webAddress;
    }
    return `http://${webAddress}`;
  }
  return webAddress;
}

export function websiteCheckPrefix(url) {
  if (url && url.trim()) {
    url = url.trim();
    if (!/^(https?:)?\/\//i.test(url)) {
      return `http://${url}`;
    } else {
      return url;
    }
  } else {
    return '';
  }
}

export function cprNoFormat(data) {
  if (hasDefinedValue(data)) {
    if (data.length > 6) {
      return data
        .replace(/-/g, '')
        .replace(/(^\d{6})/g, '$1-')
        .trim();
    }
  }

  return data;
}

export function normalizePhone(phone) {
  if (phone === null) {
    return '';
  }
  const phoneWithoutSpaces = phone.replace(/\s/g, '');
  // eslint-disable-next-line
  if (!isNaN(phoneWithoutSpaces)) {
    return phoneWithoutSpaces;
  }
  return phone;
}

export function normalizeCpr(data) {
  const cleanData = data?.replace(/-/g, '');

  // eslint-disable-next-line
  if (!isNaN(cleanData)) {
    return cleanData;
  }

  return data;
}

export function normalizeCvr(cvr) {
  if (cvr === null) {
    return '';
  }
  const cleanData = cvr.replace(/-/g, '');

  // eslint-disable-next-line
  if (!isNaN(cleanData)) {
    return cleanData;
  }

  return cvr;
}

export function splitString(string, splitBy) {
  return string.split(splitBy);
}

export function addNotification(
  message,
  title,
  autoDismiss,
  action = undefined
) {
  return {
    message,
    title,
    dismissible: 'button',
    autoDismiss,
    action,
  };
}

export function formatAmount(amount) {
  if (hasDefinedValue(amount)) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return amount;
}

export function normalizeAmount(amount) {
  if (hasDefinedValue(amount)) {
    return amount.replace(/\./g, '');
  }

  return amount;
}

export function formatCurrencyDaStandard(amount) {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
  }).format(amount);
}

export function formatDanishWithoutFractionDigits(amount) {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0,
  }).format(amount);
}

export function formatCurrency(number) {
  // remove leading zeros

  let numberWithoutLeadingZeros = number
    .toString()
    .replace(/^[^1-9,]+|\s/g, '')
    .replace('kr.', '')
    .trim();
  const arrSplit = numberWithoutLeadingZeros.split(',');
  if (arrSplit.length > 1) {
    if (!arrSplit[0]) {
      return '0,' + arrSplit[1] + ' kr.';
    }
    if (!arrSplit[1]) {
      return arrSplit[0] + ',00 kr.';
    }
    return numberWithoutLeadingZeros + ' kr.';
  } else {
    if (!arrSplit[0]) {
      return '0,00 kr.';
    }
    return numberWithoutLeadingZeros + ',00 kr.';
  }
}

export function formatCurrencyWithZeroExisting(number) {
  if (number == null || number === '') {
    return '0,00 kr.'; // Return default value for empty or null numbers
  }
  let numberWithoutLeadingZeros = number
    .toString()
    .replace(/^[^1-9,]+|\s/g, '')
    .replace('kr.', '')
    .trim();
  const arrSplit = numberWithoutLeadingZeros.split(',');
  if (arrSplit.length > 1) {
    if (!arrSplit[0]) {
      return '0,' + arrSplit[1] + ' kr.';
    }
    if (!arrSplit[1]) {
      return arrSplit[0] + ',00 kr.';
    }
    return numberWithoutLeadingZeros + ' kr.';
  } else {
    if (!arrSplit[0]) {
      return '0,00 kr.';
    }
    return numberWithoutLeadingZeros + ',00 kr.';
  }
}

export function removeCurrencyFormating(str) {
  if (!str) {
    return 0;
  }
  if (!/([a-zA-Z])\w+/g.test(str)) {
    return Number(str.replace('.', '').replace(',', '.'));
  }
  const regex = /([0-9])+/g;
  const matches = str.match(regex);
  let result;
  if (matches.length === 1) {
    result = Number(matches[0]);
  } else {
    result = Number(
      matches.slice(0, matches.length - 1).join('') +
        '.' +
        matches[matches.length - 1]
    );
  }
  return result;
}

export function removeDanishCurrencyFormating(str) {
  // Remove dots for thousands separator and replace comma with dot for decimal separator
  const formattedString = str.toString().replace(/\./g, '')?.replace(',', '.');
  const extractedNumber = parseFloat(formattedString);
  if (!isNaN(extractedNumber)) {
    return extractedNumber;
  } else {
    return 0;
  }
}

export function removeCurrency(stringAmount) {
  const str = stringAmount
    .replace('kr.', '')
    .replaceAll('.', '')
    .replaceAll(',', '.');
  const numberAmount = Number(str);
  if (isNaN(numberAmount)) {
    return 0;
  } else {
    return numberAmount;
  }
}

export function hasDefinedValue(params) {
  return params !== undefined && params !== null && params !== '';
}

export function removePercentageOnFocus(value) {
  return value.replace(' %', '');
}

export const paymentListMomentRestructureLoans = (
  startDate,
  interestRateYear,
  loanAmount,
  duration
) => {
  const numberOfDays = moment(startDate).diff(moment().startOf('day'), 'days');
  const customLoanAmount = loanAmount;

  const interestRate = interestRateYear / 100;
  const initInterest = (numberOfDays * customLoanAmount * interestRate) / 360; // it has to be change to 365 at the backend and then fix this
  const newLoanAmount = customLoanAmount + initInterest;
  const monthlyInterest = interestRateYear / 12 / 100;

  let monthlyPayment;
  if (interestRateYear === 0) {
    monthlyPayment = newLoanAmount / duration;
  } else {
    const interm1 = (monthlyInterest + 1) ** duration - 1;
    const interm2 = newLoanAmount * monthlyInterest;
    const interm3 = (monthlyInterest + 1) ** (duration - 1);

    monthlyPayment = (interm2 * interm3) / interm1;
  }

  const transctions = [];

  let singleTransaction = {
    amount: loanAmount * -1,
    when: moment().startOf('day').toDate(),
  };

  transctions.push(singleTransaction);

  for (let i = 1; i <= duration; i += 1) {
    const dateNormal = moment(startDate)
      .add(i - 1, 'months')
      .startOf('month')
      .toDate();
    singleTransaction = {
      amount: monthlyPayment,
      when: dateNormal,
    };
    transctions.push(singleTransaction);
  }

  try {
    const rate = xirr(transctions);
    return {
      monthlyPayment,
      apr: rate * 100,
    };
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {
      monthlyPayment,
      apr: 0,
    };
  }
};

export const paymentListMoment = (
  startDate,
  interestRateYear,
  loanAmount,
  duration,
  setupFee
) => {
  const numberOfDays = moment(startDate).diff(moment().startOf('day'), 'days');
  const customLoanAmount = loanAmount + setupFee;
  const interestRate = interestRateYear / 100;
  const initInterest = (numberOfDays * customLoanAmount * interestRate) / 360;
  const newLoanAmount = customLoanAmount + initInterest;
  const monthlyInterest = interestRateYear / 12 / 100;
  let monthlyPayment;
  if (interestRateYear === 0) {
    monthlyPayment = newLoanAmount / duration;
  } else {
    const interm1 = (monthlyInterest + 1) ** duration - 1;
    const interm2 = newLoanAmount * monthlyInterest;
    const interm3 = (monthlyInterest + 1) ** (duration - 1);
    monthlyPayment = (interm2 * interm3) / interm1;
  }
  const transctions = [];
  let singleTransaction = {
    amount: loanAmount * -1,
    when: moment().startOf('day').toDate(),
  };
  transctions.push(singleTransaction);
  for (let i = 1; i <= duration; i += 1) {
    const dateNormal = moment(startDate)
      .add(i - 1, 'months')
      .startOf('month')
      .toDate();
    singleTransaction = {
      amount: monthlyPayment,
      when: dateNormal,
    };
    transctions.push(singleTransaction);
  }
  try {
    const rate = xirr(transctions);
    return {
      monthlyPayment,
      apr: rate * 100,
    };
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return {
      monthlyPayment,
      apr: 0,
    };
  }
};

export const calculateTotalToBePaidInInterest = (
  remainingBalance,
  interestRate,
  firstPaymentDate
) => {
  let totalInterest = 0;
  let balance = remainingBalance;
  const monthlyInterestRate = interestRate / 100;
  const numberOfDaysUntilFirstPayment = moment(firstPaymentDate).diff(
    moment().startOf('day'),
    'days'
  );

  if (numberOfDaysUntilFirstPayment > 0) {
    const initialInterest =
      (numberOfDaysUntilFirstPayment * balance * monthlyInterestRate) / 360;
    totalInterest += initialInterest;
    balance += initialInterest;
  }

  return totalInterest;
};

function showPosition(position) {
  const {
    coords: { latitude, longitude, accuracy },
  } = position;

  return {
    latitude,
    longitude,
    accuracy,
  };
}

export function getGeolocation(navigator) {
  if (navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(showPosition);
  }
  return {
    latitude: null,
    longitude: null,
    accuracy: null,
  };
}

export function getBrowserType(userAgent) {
  const browser = Bowser.getParser(userAgent);
  return browser.getBrowser();
}

export function getCredentialsFromURL(encodedQueryString) {
  if (!encodedQueryString) {
    return { username: '', password: '' };
  } else {
    const decodedQueryString = Buffer.from(
      encodedQueryString,
      'base64'
    ).toString('utf-8');
    const myRegex = /username=(.*)&password=(.*)/;
    const username = myRegex.exec(decodedQueryString)[1] || '';
    const password = myRegex.exec(decodedQueryString)[2] || '';
    return { username, password };
  }
}

export function getBudgetTypeValue(budget, type, source) {
  if (budget && budget[type] && budget[type][source] !== null && source) {
    return budget[type][source];
  }
  return null;
}

export function typeOnlyNumberKey(evt) {
  // Only ASCII character in that range allowed
  const ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    evt.preventDefault();
    return false;
  }
  return true;
}

export function filterAcumulatorBuilder(collection, predicate) {
  const id = Date.now().toString(16);
  const segment = 100;
  const state = {
    proceed: true,
    done: false,
    results: [],
  };

  const processNext = async (chain, index) => {
    let i = index;
    const nextChain = chain.then((results) => {
      const block = [];
      for (
        ;
        i < collection.length && block.length < segment && state.proceed;
        i++
      ) {
        if (predicate(collection[i])) {
          block.push(collection[i]);
        }
      }
      if (state.proceed) {
        return results.concat(block);
      }
    });

    setTimeout(() => {
      nextChain.then((results) => {
        const done = i >= collection.length;
        if (!done) {
          processNext(nextChain, i);
        }
        state.done = done;
        state.results = results;
      });
    }, 10);
  };

  processNext(Promise.resolve([]), 0);

  return {
    id: id,
    isDone: () => {
      return state.done;
    },
    yeild: () => {
      return state.results;
    },
    drop: () => {
      state.proceed = false;
    },
  };
}

// this function return the timestamp for the filter in customer.io, to be one year ago + one day
export function getCustomerIOLink(currentTimestamp) {
  const oneDayMilliseconds = 24 * 60 * 60 * 1000;
  const oneYearMilliseconds = 365 * oneDayMilliseconds;

  // Calculate end timestamp as today at 23:59:59 plus 24 hours
  const endTimestamp = currentTimestamp;
  endTimestamp.setHours(23, 59, 59, 0);
  endTimestamp.setTime(endTimestamp.getTime() + oneDayMilliseconds);

  // Calculate start timestamp as one year ago from the current date
  // oldUnixTimestamp = unixTimeInSec(endTs + 2 day - 1 year)
  const startTimestamp = new Date(
    endTimestamp.getTime() + 2 * oneDayMilliseconds - oneYearMilliseconds
  );

  // Convert timestamps to seconds
  const endTimestampSeconds = Math.trunc(endTimestamp.getTime() / 1000);
  const startTimestampSeconds = Math.trunc(startTimestamp.getTime() / 1000);

  //return { startTs: startTimestampSeconds, endTs: endTimestampSeconds };
  const customerIOFilter = `sent?endTs=${endTimestampSeconds}&startTs=${startTimestampSeconds}`;
  return customerIOFilter;
}

// format interst rate to use comma instead of dot
export function formatInterestRate(interestRate) {
  if (interestRate !== undefined && interestRate !== null) {
    const interestRateWithComma = interestRate?.toString().replace(/\./g, ',');
    return interestRateWithComma + ' %';
  } else {
    return '-';
  }
}

export function formatReasonWithTimestampAndUser(
  reasonString,
  firstName,
  lastName
) {
  // format to '13.12.2023(Kristoffer Breitenstein): Note for test — '
  const userDetails = `(${firstName} ${lastName?.charAt(0)})`;
  const dateNow = moment(new Date()).format('DD.MM.YYYY');
  return `${dateNow}${userDetails}: ${reasonString} ---`;
}

export function getReasonFromString(formatedString) {
  // regex to match '13.12.2023(Kristoffer Breitenstein): Note for test — '
  const entryRegex = /(\d{2}\.\d{2}\.\d{4})\(([^)]+)\):\s(.*?)\s---/g;
  const match = entryRegex?.exec(formatedString);
  // return only the reason string
  if (match) {
    const timestamp = match[1];
    const user = match[2];
    const reason = match[3];
    return { timestamp, user, reason };
  }
  return formatedString;
}

export function getDocumentsSupportNotesEntries(supportNoteString) {
  // regex to match '2023-10-19 14:14:14(Kristoffer Breitenstein): Note for test — '
  const entryRegex =
    /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\(([^)]+)\):\s(.*?)\s---/g;

  // use the regex to extract entries
  const entries = [];
  // Use a for loop to iterate through the matches
  let match;
  for (
    match = entryRegex.exec(supportNoteString);
    match !== null;
    match = entryRegex.exec(supportNoteString)
  ) {
    const timestamp = match[1];
    const user = match[2];
    const supportNote = match[3];
    entries.push({ timestamp, user, supportNote });
  }
  return entries;
}

export function checkUploadedAllRequiredDocumentation(
  requiredDocumentationArray,
  existingDocuments
) {
  // check there are no rejected documents
  const filteredRejected = existingDocuments?.filter(
    (docSection) => docSection.status === 'REJECTED'
  );
  // 1. if there is required documentation from the budget final response
  // 2. if there is documentation required from the getCreditApplication data public endpoint
  if (requiredDocumentationArray && requiredDocumentationArray.length > 0) {
    // first get all documentation types that are required
    const docTypes = requiredDocumentationArray.map((item) => item.type);
    // Count occurrences of each required documentation type in the uploaded documents array
    const matchedTypesCount = docTypes.reduce((count, type) => {
      if (existingDocuments?.some((item) => item.documentationType === type)) {
        return count + 1;
      }
      return count;
    }, 0);

    // Check if all types from the first array are found in the second array
    return (
      matchedTypesCount === docTypes?.length && filteredRejected.length === 0
    );
  } else if (existingDocuments && existingDocuments.length > 0) {
    const filteredRemainingRequired = existingDocuments.filter(
      (docSection) => docSection.status === 'REQUIRED'
    );
    return (
      filteredRemainingRequired.length === 0 && filteredRejected.length === 0
    );
  }
  return false;
}

export function convertAgesToGroups(ages) {
  if (ages && ages?.length !== 0) {
    const ageGroups = ages?.map((age) => {
      if (age >= 0 && age <= 1) {
        return 'ageGroup0To1';
      } else if (age >= 2 && age <= 6) {
        return 'ageGroup2To6';
      } else if (age >= 7 && age <= 17) {
        return 'ageGroup7To17';
      } else {
        return 'unknownAgeGroup';
      }
    });

    return ageGroups;
  }
  return [];
}
export const getDanishTextForOnboardingStepTitle = (step) => {
  const danishStrings = new LocalizedStrings({
    da,
  });
  switch (step) {
    case 1:
      return danishStrings.onboardingFlowTitle;
    case 2:
      return danishStrings.onboardingMembershipConditionsTitle;
    case 3:
      return danishStrings.onboardingEmailVerificationTitle;
    case 4:
      return danishStrings.onborgindExistingMemberTitle;
    case 5:
      return danishStrings.onboardingMembershipCreatedTitle;
    case 6:
      return danishStrings.onboardingConsentTitle;
    case 7:
      return danishStrings.onboardingConditionsCreditTitle;
    case 8:
      return danishStrings.onboardingCPRNumber;
    case 9:
      return danishStrings.onboardingRequestedAmountTitle;
    case 10:
      return danishStrings.onboardingSpinnerMessageEskatCompletes;
    case 11:
      return danishStrings.onboardingStepFiveTitle;
    case 12:
      return danishStrings.onboardingCivilStatusTitle;
    case 13:
      return danishStrings.onboardingStepSixChooseAgeTitle;
    case 14:
      return danishStrings.onboardingRedirectToMonthioTitle;
    case 15:
      return danishStrings.onboardingEvaluatingBudgetMessage;
    case 16:
      return danishStrings.onboardingCheckDebtorRegisterTitle;
    case 17:
      return danishStrings.manualInterventionTitle;
    default:
      return 'No title';
  }
};

export function generateRandomUuid() {
  if (typeof crypto !== 'undefined' && crypto?.randomUUID) {
    return crypto.randomUUID();
  } else {
    // in case the browser does not support crypto.randomUUID, we will use this fallback
    const generateRandomHex = () => {
      // eslint-disable-next-line no-bitwise
      return (Math.random() * 16) | 0;
    };
    const replaceCharacter = (char) => {
      let randomHex = generateRandomHex();
      // eslint-disable-next-line no-bitwise
      let value = char === 'x' ? randomHex : (randomHex & 0x3) | 0x8;
      return value.toString(16);
    };

    const randomUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      replaceCharacter
    );
    return randomUUID;
  }
}

export function decomposeFullAddress(fullAddress) {
  // extract the zip code and city from the full address
  const match = fullAddress?.match(/^(.+?)\s*,\s*(\d{4})\s*(.+)$/);
  if (match) {
    const address = match[1];
    const zipCode = match[2];
    const city = match[3];
    return {
      address,
      zipCode,
      city,
    };
  }
  return null;
}

export const getOnboardingErrorReasonString = (errorString) => {
  const errorCause = errorString?.trim();
  const regex1 = new RegExp('Bad Request: (.+)');
  const regex2 = new RegExp(
    'com.denti.infrastructure.exception.BusinessException: (.+)'
  );
  try {
    let matchString;
    if (errorCause && typeof errorCause === 'string') {
      const match = errorCause?.match(regex1) || errorCause?.match(regex2);
      matchString = match ? match[1] : '';
    }
    return matchString ? matchString : errorCause;
  } catch (err) {
    console.error('An error occurred:', err?.message);
  }
};

export const getErrorEndpointName = (serverErrorDetails = {}) => {
  const endpointUrl =
    serverErrorDetails?.data?.path ?? serverErrorDetails?.config?.url;

  // extract only the endpoint name from the url
  let endpointName;
  if (endpointUrl) {
    const lastSlashIndex = endpointUrl.lastIndexOf('/');
    const questionMarkIndex = endpointUrl.indexOf('?', lastSlashIndex);
    endpointName =
      questionMarkIndex !== -1
        ? endpointUrl.substring(lastSlashIndex + 1, questionMarkIndex)
        : endpointUrl.substring(lastSlashIndex + 1);
  }

  return endpointName ?? endpointUrl;
};

// used to get previous search list result stored and restore it
export function getTabObjectFromStorage(tabID, storageKey, callback) {
  const existingData = JSON.parse(localStorage.getItem(`tab-${tabID}`)) || {};

  if (Object.hasOwn(existingData, storageKey)) {
    callback(existingData[storageKey]);
  }
}

const updateOrSetInLocalStorage = (tabId, key, value) => {
  // Retrieve existing data for the tab
  const existingData = JSON.parse(localStorage.getItem(`tab-${tabId}`)) || {};
  existingData[key] = value;
  if (Object.entries(existingData)?.length > 0)
    localStorage.setItem(`tab-${tabId}`, JSON.stringify(existingData));
};

export const saveCachedData = (key, value) => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsObj = Object.fromEntries(urlParams);
  const existingTabId = urlParamsObj?.tabID ?? undefined;
  updateOrSetInLocalStorage(existingTabId, key, value);
};

export const setUrlParamIfNotExists = (param, value) => {
  const currentUrl = new URL(window.location.href);
  if (!currentUrl.searchParams.has(param)) {
    currentUrl.searchParams.set(param, value);
    window.history.replaceState({}, '', currentUrl);
  }
};

export const replaceUrlParam = (param, value) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(param, value);
  window.history.replaceState({}, '', currentUrl);
};

export const getCachedSearch = (pageKey) => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsObj = Object.fromEntries(urlParams);
  const tabID = urlParamsObj?.tabID ?? undefined;
  // if the tabID from url is not in session storage, that means the tab was restored and then we get the cached search
  if (tabID) {
    const existingData = JSON.parse(localStorage.getItem(`tab-${tabID}`)) || {};
    if (Object.hasOwn(existingData, pageKey)) {
      return existingData[pageKey] || null;
    }
  }
  return null;
};

export const getPaymentFreeMonths = (
  startDateOfFirstLoanInSeries,
  numberOfPaidMonths
) => {
  if (startDateOfFirstLoanInSeries !== null && numberOfPaidMonths !== null) {
    const [day, month, year] = startDateOfFirstLoanInSeries
      ? startDateOfFirstLoanInSeries.split('-').map(Number)
      : [null, null, null];

    // Create a date object (month is 0-indexed in JS)
    const startDate = new Date(year, month - 1, day);
    const today = new Date();

    // total months since the start date
    const monthsSinceStartDate =
      (today.getFullYear() - startDate.getFullYear()) * 12 +
      (today.getMonth() - startDate.getMonth());

    let paymentFreeMonths;
    if (monthsSinceStartDate > 12) {
      paymentFreeMonths = 12 - numberOfPaidMonths;
    } else {
      paymentFreeMonths = monthsSinceStartDate - numberOfPaidMonths;
    }
    return paymentFreeMonths;
  }
  return '';
};

export const getLatestAcceptedInvoice = (items) => {
  return items
    .filter((item) => item.status === 'ACCEPTED')
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate)) // Sort by date (latest first)
    .at(0);
};
