import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import DentiModal from '../../common/DentiModal';
import { ROUTE_MIGRATE_CREDIT } from 'constants/routes';
import { strings } from '../../../constants/localization';
import { getPaymentFreeMonths } from '../../../constants/utils';
import PaymentFlow from '../../paymentFlow/PaymentFlow';
import { ReactComponent as InformationIcon } from '../../../resources/icons/info-circle.svg';
import RestructurePaymentFlow from 'components/paymentFlow/RestructurePaymentFlow';
import {
  portalServiceFeeCustomizePayment,
  portalServiceFeeFreeMonth,
  Sundhedplus,
} from '../../../config';
import {
  clearMemberPortalData,
  getClientCreditData,
  loadingClientPortalData,
} from 'actions/memberPortalActions';
import useClientPortalCreditCardUpdateLink from '../../../hooks/useClientPortalCreditCardUpdateLink';

interface ServiceActionContextType {
  openPartialPaymentModal: () => void;
  openSettleLoanModal: () => void;
  openRestructureLoanModal: () => void;
  openFreeMonthModal: () => void;
}

const ServiceActionContext = createContext<ServiceActionContextType>({
  openPartialPaymentModal: () => {},
  openSettleLoanModal: () => {},
  openRestructureLoanModal: () => {},
  openFreeMonthModal: () => {},
});

interface SundhedCreditProps {
  creditFacilityData: {
    status: string;
    amountAvailable: number;
    expirationDate: string;
    interestRate: number;
    amount: number;
    dunning: boolean;
    startDateOfFirstLoanInSeries: string;
    numberOfPaidMonthsSinceTheStartDateOfFirstLoanInSeries: number;
    number: string;
    creditCardData: {
      status: string;
      expirationDate: string;
    };
    numberOfReepayInvoicesToday: number;
    installmentLoanDataList: [
      {
        number: string;
        status: string;
      }
    ];
    paymentData: {
      latestPaymentAmount: number;
      nextPaymentAmount: number;
    };
  };
  clientData: {
    uuid: string;
    leadUuid: string;
  };
}

// eslint-disable-next-line no-unused-vars
enum WarningCase {
  // eslint-disable-next-line no-unused-vars
  LIMIT_REACHED = 'paymentReachedLimit',
  // eslint-disable-next-line no-unused-vars
  INVALID_CARD = 'invalidCard',
  // eslint-disable-next-line no-unused-vars
  DUNNING = 'dunning',
  // eslint-disable-next-line no-unused-vars
  RESTRUCTURE_DUNNING = 'restructureDunning',
  // eslint-disable-next-line no-unused-vars
  FREE_MONTH_DUNNING = 'freeMonthDunning',
  // eslint-disable-next-line no-unused-vars
  NOT_PAYED = 'notPayed',
  // eslint-disable-next-line no-unused-vars
  NO_MORE_FREE_MONTHS = 'NO_MORE_FREE_MONTHS',
  // eslint-disable-next-line no-unused-vars
  BRAND_MISMATCH = 'BRAND_MISMATCH',
  // eslint-disable-next-line no-unused-vars
  ZERO_INTEREST_LOAN = 'ZERO_INTEREST_LOAN',
}

export const ServiceActionProvider = ({ setVisibleServiceModal, children }) => {
  const dispatch = useDispatch();
  const clientCreditData = useSelector(
    (state: { memberPortal: { clientDataInformation: SundhedCreditProps } }) =>
      state.memberPortal?.clientDataInformation,
    shallowEqual
  );
  const { leadUuid, email } = clientCreditData?.clientData || {};
  const clientCreditInformation = clientCreditData.creditFacilityData;
  const { brand, subscriptionBrand } = clientCreditInformation || {};
  const paymentData = clientCreditInformation?.paymentData;
  const { latestPaymentAmount, nextPaymentAmount } = paymentData || {};
  const isRestructureActionAllowed = latestPaymentAmount === nextPaymentAmount;
  const {
    startDateOfFirstLoanInSeries,
    numberOfPaidMonthsSinceTheStartDateOfFirstLoanInSeries,
  } = clientCreditInformation || {};

  const { dunning, numberOfReepayInvoicesToday, installmentLoanDataList } =
    clientCreditInformation || {};
  const { expirationDate, status: creditCardStatus } =
    clientCreditInformation.creditCardData;
  const isCardInactive = creditCardStatus !== 'ACTIVE';

  const onlyZeroInterestLoans = installmentLoanDataList.filter(
    (obj) => obj.status === 'ACCEPTED' && obj.interestRate === 0
  );

  const { userAgent } = window.navigator;

  // Set device to "mobile" if "Mobi" exists in userAgent else set device to "desktop"
  const device = userAgent.includes('Mobi') ? 'mobile' : 'desktop';

  const isCreditCardExpired = () => {
    const [month, year] = expirationDate.split('-');
    // Prepend '20' to the year to interpret it as 2099 instead of 1999
    const fullYear = `20${year}`;
    const expDate = moment(`${month}-${fullYear}`, 'MM-YYYY').endOf('month');
    return expDate.isBefore(moment());
  };
  const [partialPaymentModal, setPartialPaymentModal] =
    useState<boolean>(false);
  const [settleLoanModal, setSettleLoanModal] = useState<boolean>(false);
  const [restructureLoanModal, setRestructureLoanModal] =
    useState<boolean>(false);
  const [freeMonthLoanModal, setFreeMonthLoanModal] = useState<boolean>(false);
  const [showWarningNotAllowed, setShowWarningNotAllowed] =
    useState<boolean>(false);
  const [warningCase, setWarningCase] = useState<WarningCase>(
    WarningCase.LIMIT_REACHED
  );
  const updateCardLink = useClientPortalCreditCardUpdateLink();

  const currentUrl =
    window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : `https://${process.env.REACT_APP_DAM_URL}`;

  useEffect(() => {
    setVisibleServiceModal(
      partialPaymentModal ||
        showWarningNotAllowed ||
        freeMonthLoanModal ||
        restructureLoanModal
    );
  }, [
    partialPaymentModal,
    showWarningNotAllowed,
    freeMonthLoanModal,
    restructureLoanModal,
  ]);

  const renderUpdateCardLink = (linkText: string) => {
    return (
      <a href={updateCardLink} target='_blank' rel='noopener noreferrer'>
        {linkText}
      </a>
    );
  };

  const renderWarning = () => {
    type FormatType = string | (string | React.JSX.Element)[];
    let headline = '',
      message: FormatType = '',
      message2: FormatType = '';
    const migrateQueryParams = new URLSearchParams({
      leaduuid: leadUuid,
      clientemail: encodeURIComponent(email),
    }).toString();
    const migrateLink =
      window.location.hostname !== 'localhost'
        ? process.env.REACT_APP_MIGRATE_TO_SUNDHEDPLUS
        : ROUTE_MIGRATE_CREDIT;

    switch (warningCase) {
      case WarningCase.LIMIT_REACHED:
        headline = strings.partialRedemptionTitleWarningLimitError;
        message = strings.partialRedemptionTextWarningMessage1;
        break;
      case WarningCase.INVALID_CARD:
        headline = strings.partialRedemptionTitleWarningCardError;
        message = strings.formatString(
          strings.partialRedemptionTextWarningMessage2,
          renderUpdateCardLink(strings.partialRedemptionTextWarningMessage2Link)
        );
        break;
      case WarningCase.DUNNING:
        headline = strings.partialRedemptionTitleWarningDunningError;
        message = strings.partialRedemptionTextWarningMessage3;
        message2 = strings.formatString(
          strings.partialRedemptionTextWarningMessage4,
          renderUpdateCardLink(strings.partialRedemptionTextWarningMessage4Link)
        );
        break;
      case WarningCase.RESTRUCTURE_DUNNING:
        headline = strings.partialRedemptionTitleWarningDunningError;
        message = strings.clientPortalRestructureTextWarningMessage3;
        message2 = strings.formatString(
          strings.partialRedemptionTextWarningMessage4,
          renderUpdateCardLink(strings.partialRedemptionTextWarningMessage4Link)
        );
        break;
      case WarningCase.FREE_MONTH_DUNNING:
        headline = strings.partialRedemptionTitleWarningDunningError;
        message = strings.clientPortalPaymentFreeTextWarningMessage3;
        message2 = strings.formatString(
          strings.partialRedemptionTextWarningMessage4,
          renderUpdateCardLink(strings.partialRedemptionTextWarningMessage4Link)
        );
        break;
      case WarningCase.NOT_PAYED:
        headline = strings.clientPortalNotPayedHeadline;
        message = strings.clientPortalNotPayedMessage1;
        message2 = strings.formatString(
          strings.clientPortalNotPayedMessage2,
          <a href={`mailto:${Sundhedplus.contactEmail}`}>
            {Sundhedplus.contactEmail}
          </a>,
          <a href={`tel:${Sundhedplus.contactPhone}`}>
            {Sundhedplus.contactPhoneHtmlFormated}
          </a>
        );
        break;
      case WarningCase.NO_MORE_FREE_MONTHS:
        headline = strings.clientPortalNoFreeMonthsHeadline;
        message = strings.clientPortalNoFreeMonthsMessage1;
        message2 = strings.formatString(
          strings.clientPortalNoFreeMonthsMessage2,
          <a href={`mailto:${Sundhedplus.contactEmail}`}>
            {Sundhedplus.contactEmail}
          </a>,
          <a href={`tel:${Sundhedplus.contactPhone}`}>
            {Sundhedplus.contactPhoneHtmlFormated}
          </a>
        );
        break;

      case WarningCase.BRAND_MISMATCH:
        headline = strings.clientPortalBrandMismatchHeadline;
        message = strings.clientPortalBrandMismatchMessage1;
        message2 = strings.formatString(
          strings.clientPortalBrandMismatchMessage2,
          <a
            href={`${migrateLink}?${migrateQueryParams}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {strings.clientPortalBrandMismatchLink}
          </a>
        );
        break;
      case WarningCase.ZERO_INTEREST_LOAN:
        headline = strings.clientPortalZeroInterestLoanHeadline;
        message = strings.clientPortalZeroInterestLoanMessage1;
        message2 = strings.formatString(
          strings.clientPortalZeroInterestLoanMessage2,
          <a href={`mailto:${Sundhedplus.contactEmail}`}>
            {Sundhedplus.contactEmail}
          </a>,
          <a href={`tel:${Sundhedplus.contactPhone}`}>
            {Sundhedplus.contactPhoneHtmlFormated}
          </a>
        );
        break;
      default:
        return '';
    }
    return (
      <DentiModal
        key={1}
        close={() => setShowWarningNotAllowed(false)}
        modalClass='notification-modal warning payment-flow'
        modalClassName='confirm-action'
      >
        <div>
          <div className='icon'>
            <InformationIcon className='warning-icon' />
          </div>
          <div>
            <h3 className='headline'>{headline}</h3>
            <p className='notification-message'>{message}</p>
            {message2 && <p className='notification-message'>{message2}</p>}
          </div>
          <div className='bottom-section action-btn mt-3'>
            <button
              className='submit-btn btn btn-blue center-block'
              type='button'
              onClick={() => setShowWarningNotAllowed(false)}
            >
              <span>{strings.loanDetailsClose}</span>
            </button>
          </div>
        </div>
      </DentiModal>
    );
  };

  const isDifferentBrand = () => {
    return subscriptionBrand !== brand;
  };

  const checkWarningCases = (isSettle, customBehaviourCallback) => {
    if (!isSettle && isDifferentBrand()) {
      setWarningCase(WarningCase.BRAND_MISMATCH);
      setShowWarningNotAllowed(true);
      return;
    }

    if (!isSettle && onlyZeroInterestLoans.length > 0) {
      setWarningCase(WarningCase.ZERO_INTEREST_LOAN);
      setShowWarningNotAllowed(true);
      return;
    }

    if (numberOfReepayInvoicesToday >= 3) {
      setWarningCase(WarningCase.LIMIT_REACHED);
      setShowWarningNotAllowed(true);
    } else if (dunning && !isSettle) {
      // for settle case, we show the flow, but for partial redemption we show warnning
      setWarningCase(WarningCase.DUNNING);
      setShowWarningNotAllowed(true);
    } else if (isCardInactive || isCreditCardExpired()) {
      setWarningCase(WarningCase.INVALID_CARD);
      setShowWarningNotAllowed(true);
    } else {
      customBehaviourCallback();
    }
  };

  const checkRestructuringWarningCases = (
    type: string,
    customBehaviourCallback: () => void
  ) => {
    const paymentFreeMonths = getPaymentFreeMonths(
      startDateOfFirstLoanInSeries,
      numberOfPaidMonthsSinceTheStartDateOfFirstLoanInSeries
    );

    if (isDifferentBrand()) {
      setWarningCase(WarningCase.BRAND_MISMATCH);
      setShowWarningNotAllowed(true);
      return;
    }

    if (dunning) {
      if (type === 'restructure') {
        setWarningCase(WarningCase.RESTRUCTURE_DUNNING);
      } else if (type === 'free-month') {
        setWarningCase(WarningCase.FREE_MONTH_DUNNING);
      }
      setShowWarningNotAllowed(true);
    } else if (!isRestructureActionAllowed) {
      setWarningCase(WarningCase.NOT_PAYED);
      setShowWarningNotAllowed(true);
    } else if (
      type === 'free-month' &&
      paymentFreeMonths &&
      paymentFreeMonths > 1
    ) {
      setWarningCase(WarningCase.NO_MORE_FREE_MONTHS);
      setShowWarningNotAllowed(true);
    } else {
      customBehaviourCallback();
    }
  };

  const openPartialPaymentModal = () => {
    checkWarningCases(false, () => {
      const url = `${currentUrl}/partialRedemption?leadUuid=${leadUuid}&creditFacilityNumber=${clientCreditData?.creditFacilityData?.number}&source=portal&device=${device}`;
      if (device === 'mobile') {
        window.open(url, '_blank');
      } else {
        setPartialPaymentModal(!partialPaymentModal);
      }
    });
  };

  const openSettleLoanModal = () => {
    checkWarningCases(true, () => {
      setSettleLoanModal(!settleLoanModal);
    });
  };

  const openRestructureLoanModal = () => {
    checkRestructuringWarningCases('restructure', () => {
      setRestructureLoanModal(!restructureLoanModal);
    });
  };

  const openFreeMonthModal = () => {
    checkRestructuringWarningCases('free-month', () => {
      setFreeMonthLoanModal(!freeMonthLoanModal);
    });
  };

  const value = useMemo(
    () => ({
      openPartialPaymentModal,
      openSettleLoanModal,
      openRestructureLoanModal,
      openFreeMonthModal,
    }),
    [
      openPartialPaymentModal,
      openSettleLoanModal,
      openRestructureLoanModal,
      openFreeMonthModal,
    ]
  );

  const refreshClientData = () => {
    dispatch(getClientCreditData())
      .then(() => {
        dispatch(loadingClientPortalData(false));
      })
      .catch(() => {
        dispatch(clearMemberPortalData());
        dispatch(loadingClientPortalData(false));
      });
  };

  const handleClosePartialPaymentModal = () => {
    setPartialPaymentModal(false);
    refreshClientData();
  };

  const handleCloseRestructureModal = () => {
    setRestructureLoanModal(false);
    window.location.reload();
  };

  const handleCloseFreeMonthModal = () => {
    setFreeMonthLoanModal(false);
    window.location.reload();
  };

  const handleCloseSettleLoanModal = () => {
    setSettleLoanModal(false);
    refreshClientData();
  };

  return (
    <ServiceActionContext.Provider value={value}>
      {children}
      {/* warning partial payment not allowed */}
      {showWarningNotAllowed && renderWarning()}

      {/* Partial payment modal */}
      {!showWarningNotAllowed && partialPaymentModal && (
        <DentiModal
          key={1}
          close={handleClosePartialPaymentModal}
          modalClass='loan-details-modal modal-dialog-centered'
          modalClassName='partial-redemption-modal'
          closeBtn={true}
        >
          <div className='partial-redemption-styles'>
            <PaymentFlow
              hasLeadUuid={leadUuid}
              hasCreditFacilityNumber={
                clientCreditData?.creditFacilityData?.number
              }
              isClientPortal={true}
              deviceType={device}
              closeModal={handleClosePartialPaymentModal}
              isSettlement={false}
              numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
            />
          </div>
        </DentiModal>
      )}

      {/* Normal Restructure loan modal */}
      {!showWarningNotAllowed && restructureLoanModal && (
        <DentiModal
          key={1}
          close={handleCloseRestructureModal}
          modalClass='loan-details-modal modal-dialog-centered'
          modalClassName='partial-redemption-modal restructure'
          closeBtn={true}
        >
          <div className='partial-redemption-styles'>
            <RestructurePaymentFlow
              hasLeadUuid={leadUuid}
              hasCreditFacilityNumber={
                clientCreditData?.creditFacilityData?.number
              }
              isClientPortal={true}
              deviceType={device}
              closeModal={handleCloseRestructureModal}
              isSettlement={false}
              type='restructure'
              serviceFee={portalServiceFeeCustomizePayment}
              numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
            />
          </div>
        </DentiModal>
      )}

      {/* Month free Restructure loan modal */}
      {!showWarningNotAllowed && freeMonthLoanModal && (
        <DentiModal
          key={1}
          close={handleCloseFreeMonthModal}
          modalClass='loan-details-modal modal-dialog-centered'
          modalClassName='partial-redemption-modal restructure'
          closeBtn={true}
        >
          <div className='partial-redemption-styles'>
            <RestructurePaymentFlow
              hasLeadUuid={leadUuid}
              hasCreditFacilityNumber={
                clientCreditData?.creditFacilityData?.number
              }
              isClientPortal={true}
              deviceType={device}
              closeModal={handleCloseFreeMonthModal}
              isSettlement={false}
              type='free-month'
              serviceFee={portalServiceFeeFreeMonth}
              numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
            />
          </div>
        </DentiModal>
      )}

      {/* Settle loan modal */}
      {settleLoanModal && (
        <DentiModal
          key={1}
          close={handleCloseSettleLoanModal}
          modalClass='loan-details-modal modal-dialog-centered'
          modalClassName='partial-redemption-modal'
          closeBtn={true}
        >
          <div className='partial-redemption-styles'>
            <PaymentFlow
              hasLeadUuid={leadUuid}
              hasCreditFacilityNumber={
                clientCreditData?.creditFacilityData?.number
              }
              isClientPortal={true}
              deviceType={device}
              closeModal={handleCloseSettleLoanModal}
              isSettlement={true}
              numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
            />
          </div>
        </DentiModal>
      )}
    </ServiceActionContext.Provider>
  );
};

export const useServiceActionContext = () => useContext(ServiceActionContext);
