import React, { useEffect, useState } from 'react';
import moment from 'moment';
import AdjustMonthlyPayment from './steps/AdjustMonthlyPayment';
import AcceptNewLoanAgreement from './steps/AcceptNewLoanAgreement';
import LoadingMessage from '../statusMessages/LoadingMessage';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Card, CardBody } from 'reactstrap';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { useDispatch } from 'react-redux';
import { getClientDataForPartialRedemption } from '../../actions/leadActions';
import PaymentFreeMonthAgreement from './steps/PaymentFreeMonthAgreement';
import StepError from '../acceptance/StepError';
import RestructureProcessing from './steps/RestructureProcessing';
import { ClientPortalNewPaymentDetailProps } from 'components/interfaces';

type PaymentFlowProps = {
  isClientPortal?: boolean;
  hasLeadUuid?: string;
  hasCreditFacilityNumber?: string;
  deviceType?: string;
  closeModal?: () => void;
  isSettlement?: boolean;
  numberOfReepayInvoicesToday: number;
  type?: string;
  serviceFee?: number;
};

interface ClientDataProps {
  client: {
    firstName?: string;
    lastName?: string;
  };
  creditFacility: {
    remainingBalance?: number;
    creditFacilityInterestRate?: number;
    numberOfReepayInvoicesToday?: number;
  };
  creditCard: {
    expirationDate?: string;
    maskedCardNumber?: string;
  };
}

const RestructurePaymentFlow: React.FC<PaymentFlowProps> = ({
  isClientPortal,
  hasLeadUuid,
  hasCreditFacilityNumber,
  deviceType,
  closeModal,
  type,
  serviceFee,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [prevStep, setPrevStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const additionalPayment = 0;
  const [clientData, setClientData] = useState<ClientDataProps>({
    creditFacility: {},
    creditCard: {},
    client: {},
  });
  const [hasRedirect, setHasRedirect] = useState<boolean>(false);
  const [hasLogo, setHasLogo] = useState<boolean>(true); // Initialize as true
  const [source, setSource] = useState<string>('');
  const [device, setDevice] = useState<string>('');
  const [newPaymentDetails, setNewPaymentDetails] =
    useState<ClientPortalNewPaymentDetailProps>();
  const firstDayFollowingMonth = moment()
    .add(2, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  const dispatch = useDispatch();

  // detect if the user is using an iOS device, then prevent the zoom in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }
    if (!isClientPortal) {
      document.body.classList.add('public-content');
    } else {
      document.body.classList.add('private-content');
    }
    document.body.classList.add('public-flow');
    return () => {
      if (!isClientPortal) {
        document.body.classList.remove('public-flow');
      } else {
        document.body.classList.add('private-content');
      }
      document.body.classList.remove('public-content');
    };
  }, []);

  // Helper function to extract query parameters
  const extractUrlParam = (param: string): string | null =>
    new URLSearchParams(window.location.search).get(param);

  const fetchClientData = async (uuid: string, facilityNumber: string) => {
    try {
      const res = await dispatch(
        getClientDataForPartialRedemption(uuid, facilityNumber)
      );

      if (res.status === 200) {
        setClientData(res.data);
        setIsLoading(false);
      } else {
        setCurrentStep(null);
      }
    } catch (error) {
      console.log('error', error);
      setCurrentStep(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initializeData = () => {
      setIsLoading(true);

      const leadUuidFromUrl = extractUrlParam('leadUuid');
      const creditFacilityFromUrl = extractUrlParam('creditFacilityNumber');
      const sourceFromUrl =
        extractUrlParam('source') || deviceType ? 'portal' : '';
      const deviceFromUrl = extractUrlParam('device') || deviceType;

      if (sourceFromUrl) setSource(sourceFromUrl);
      if (deviceFromUrl) setDevice(deviceFromUrl);

      const uuid = isClientPortal ? hasLeadUuid : leadUuidFromUrl;
      const facilityNumber = isClientPortal
        ? hasCreditFacilityNumber
        : creditFacilityFromUrl;

      if (uuid && facilityNumber) {
        fetchClientData(uuid, facilityNumber);
      } else {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [dispatch, isClientPortal, hasLeadUuid, hasCreditFacilityNumber]);

  // Logic for managing hasLogo and hasRedirect
  useEffect(() => {
    if (source === 'portal' && device === 'mobile') {
      setHasLogo(false);
      setHasRedirect(true);
    } else if (source === 'portal' && device === 'desktop') {
      setHasLogo(false);
      setHasRedirect(false);
    } else {
      setHasLogo(true);
      setHasRedirect(false);
    }
  }, [source, device]);

  // Store previous step before the state change
  const handleNextStep = () => {
    setPrevStep(currentStep); // Capture current step as previous step
    setCurrentStep((prevStep) => prevStep + 1);
    window.parent.postMessage({ step: 2 }, '*');
  };

  const handlePreviousStep = () => {
    setPrevStep(currentStep); // Capture current step as previous step
    setCurrentStep((prevStep) => prevStep - 1);
    window.parent.postMessage({ step: 1 }, '*');
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        if (type === 'free-month') {
          return (
            <PaymentFreeMonthAgreement
              onNextStep={handleNextStep}
              closeModal={closeModal}
            />
          );
        }
        handleNextStep();
        break;
      case 2:
        return (
          <AdjustMonthlyPayment
            onNextStep={handleNextStep}
            additionalPayment={additionalPayment?.toString() || ''}
            setNewPaymentDetails={setNewPaymentDetails}
            handlePreviousStep={handlePreviousStep}
            newPaymentDetails={newPaymentDetails}
            type={type}
          />
        );
      case 3:
        return (
          <AcceptNewLoanAgreement
            onNextStep={handleNextStep}
            setIsLoading={setIsLoading}
            newPaymentDetails={newPaymentDetails}
            clientData={clientData}
            extraPayment={additionalPayment?.toString() || ''}
            serviceFee={serviceFee}
            setNewPaymentDetails={setNewPaymentDetails}
            handlePreviousStep={handlePreviousStep}
          />
        );
      case 4:
        return (
          <RestructureProcessing
            newPaymentDetails={newPaymentDetails}
            serviceFee={serviceFee}
            hasRedirect={hasRedirect}
            closeModal={closeModal}
            startDate={
              type === 'free-month'
                ? firstDayFollowingMonth
                : newPaymentDetails?.firstPaymentDate || null
            }
          />
        );
      default:
        return <StepError hasRedirect={hasRedirect} />;
    }
  };

  const directionClassName = currentStep > prevStep ? 'move-next' : 'move-prev';

  return (
    <>
      <div
        id='acceptance-flow'
        className={`flow-wrapper ${
          type === 'restructure' ? 'restructure' : ''
        }`}
      >
        {isLoading ? (
          <LoadingMessage />
        ) : (
          <TransitionGroup
            appear
            timeout={600}
            className={`slide-group ${directionClassName} step-${currentStep} 
                            `}
          >
            <CSSTransition
              key={currentStep}
              appear
              timeout={300}
              classNames='slide'
              className='transition-container'
            >
              <div className='sundhed-wrapper'>
                {hasLogo && (
                  <div className='sundhed-plus-logo'>
                    <img
                      className='sundhed-logo'
                      src={sundhedLogo}
                      alt='Sundhed+'
                    />
                  </div>
                )}
                <Card
                  className={`flow-container ${
                    currentStep === 1
                      ? 'additional-payment'
                      : currentStep === 4
                      ? 'payment-processing'
                      : 'partial-redemption-flow'
                  }`}
                >
                  <CardBody>{renderStep()}</CardBody>
                </Card>
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
      </div>
    </>
  );
};

export default RestructurePaymentFlow;
