import {
  brandConfig,
  creditMaxAmountApproveCreditForWarningCards,
} from '../../config';

/* eslint-disable max-len */
const en = {
  // Login and menus
  username: 'E-mail',
  password: 'Password',
  login: 'Login',
  invalidPassword1: 'Invalid username or password.',
  invalidPassword2: 'Please try again.',
  noAuthorityMsg:
    'You have no rights to what you are doing. Please contact customer service to have the problem fixed. ',
  profile: 'Profile',
  logout: 'Log out',
  settings: 'Settings',
  settingsHeadline1: 'User settings',
  settingsHeadline1Link1: 'Your profile',
  settingsHeadline1Link2: 'Users',
  settingsHeadline2: 'Account settings',
  settingsHeadline2Link1: 'Current master data',
  settingsHeadline2Link2: 'Companies',
  settingsHeadline2Link2Single: 'Company',
  settingsHeadline3: 'Marketing and integrations',
  settingsHeadline3Link1: 'SmartLink widget',
  settingsHeadline3Link2: 'Advertise',
  help: 'Help',
  forgotPassword: 'Did you forget your password?',
  forgotPasswordHeadline: 'Reset your password',
  forgotPasswordParagraph:
    "Please enter your email. Then we'll send you a link so you can change your password.",
  loginHeadline: 'Log in to the Partner Portal',
  enterUsernameHeadline:
    'Please enter your email and we will send you a link to change the password.',
  submitForm: 'Reset',
  successSubmit: 'An email has been sent to the user: ',
  returnToLogin: 'Return to login',
  validUsername: 'Please enter a valid username.',
  loginHelp: 'Do you need help?',
  loginAssistance: 'Need assistance?',
  loggedInAs: 'Logged in as',
  tooManyLoginAttempts: 'Too many login attempts.',
  waitToRetry:
    'For security reasons, you are temporarily blocked from further login attempts. Please wait or {0}',
  resetPasswordToUnlock: 'reset your password.',
  loginWrongBrand: 'Please try to login on {0}',
  loginMessageWithNewLink:
    'You have now received an email with a new link to reset your password',
  loginServerDownMessage:
    'There seems to be an issue with the server connection',
  sessionExpiredHeadline: 'Your session has expired',
  sessionExpiredText:
    'Sadly, you have been inactive for too long. For security reasons, please log in again.',
  loginErrorNotCooperating: `Your clinic has decided not to cooperate with ${brandConfig.appName}, so you cannot login at the moment. Please contact our customer service, if this is incorrect.`,
  loginForMembersTitle: 'Log in as a member',
  loginForMembersText:
    'Manage your Sundhed+ membership in the Member Portal. Here you can, among other things, edit your member profile and manage your Sundhed+ credit.',
  loginForMembersButton: 'Log in as a member',
  loginForPartnersTitle: 'For Partners',
  loginForPartnersText:
    'In the Partner Portal, you can, as a practitioner, manage patients who want to pay for their treatment with a Sundhed+ Kredit.',
  loginForPartnerButton: 'Log in as a partner',
  // reset password
  title: 'Reset password',
  headline:
    'The password must be at least 8 characters and it must contain at least one letter and one number.',
  successTitle: 'Success!',
  sucessMessage: 'Your password has been updated.',
  errorTitle: 'Error',
  errorMessage: 'The password could not be updated.',
  goToLogin: 'Proceed to login',
  passwordStrength:
    'The password must be at least {0} characters and must consist of numbers and both uppercase and lowercase letters.',
  newPassword: 'New password',
  repeatPassword: 'Confirm new password',
  changePasswordButton: 'Change Password',
  passwordDontMatch: 'The entered passwords are not the same',

  // Data validation
  required: 'Required',
  numberOfCharacters: 'Must be {0} characters',
  numberOfCharactersMax: 'Must contain maximum {0} characters',
  onlyLetters: 'Letters only',
  onlyDigits: 'Numbers only',
  format: 'Format should be d.ddd,dd',
  invalidEmail: 'Invalid email',
  invalidURL: 'Invalid URL',
  incompleteSelection: 'Please select at least one skill',
  mustBeBigger: 'Must be bigger than or equal to',
  mustBeSmaller: 'Must be smaller than',
  mustBeAtLeast: 'Must be at least {0} characters.',
  mustContainNumber: 'Must containt digits.',
  mustContainLetter: 'Must contain letters.',
  onlyAlphanumeric: 'Must contain only letters and digits.',
  danishNumber: 'Please enter a danish number!',
  minLoanAmount: 'Minimum amount is {0} kr.',
  maxLoanAmount: 'Maximum amount is {0} kr.',
  decimalsError: 'Must contain maximum 2 decimals.',
  moreCommas: 'Only one comma allowed.',
  inputEmpty: 'The field is empty',
  requiredAllFieldsFilled: '*Please ensure all required fields are filled',
  saving: 'Saving...',
  saved: 'Saved',
  notSaved: 'Could not save',

  leadPlaceholder: 'lead',
  leadsPlaceholder: 'leads',
  clientsPlaceholder: 'clients',
  companyPlaceholder: 'clinic',
  companiesPlaceholder: 'clinics',
  signupFeePlaceholder: 'Optional setup fee',
  companiesDownload: 'Download',

  // go back to list
  goToClientsList: 'All patients',
  goToClinicsList: 'All clinics',
  goToChainsList: 'All company chains',

  // leads list
  searchPlaceholder: 'Search for {0}...',
  noItems: 'As soon as you receive your first {0}, it will be visible here...',
  noCategoryItems: 'There are no {0} that match the selected status...',
  tipCategoryTitle: 'Tip',
  patientsLabel: 'patients',
  tipCategory:
    ': Remember to change the status of your {0}. Then you can easily keep track of how far the individual {1} are in the process.',
  errorLoading: `An error occured while loading the page. Please try again or close your browser and restart ${brandConfig.appName}.`,
  searchEmpty: 'No results found.',
  leadsDisplayFromCompany: 'Only referrals from {0} are displayed.',
  leadsDisplayReset: 'Reset view',

  // lead details
  leadPersonalDataTitle: 'Patient information',
  leadPersonalDataSubtitlePhone:
    'The patient prefers to be contacted by telephone',
  leadPersonalDataSubtitleEmail: 'The patient prefers to be contacted by email',
  noNote: '- No notes -',
  requiresActionAlertTitle:
    'This reference is more than 5 hours old and the patient probably wants to hear from you.',
  requiresActionAlertSubtitle:
    'You should contact the patient immediately, unless you have already made contact. Remember to change the status of the referral to avoid messages like this.',
  internalNote: 'Internal note',
  saveInternalNote: 'Save note',
  placeholderAddress: 'Enter address',
  placeholderZipCode: 'Enter zip code',
  placeholderCity: 'Enter city',

  // Credit details
  creditDetailsHeading: 'Credit details',
  creditDetailsCreatedDate: 'Date of creation',
  creditDetailsNumber: 'Credit no.',
  creditDetailsLatestEval: 'Latest credit eval.',
  creditDetailsLatestChange: 'Latest change',
  creditSendUpdate: 'Send updated credit email',

  // credit details edit
  creditDetailsEdit: 'Edit credit',
  creditDetailsAmount: 'Credit amount',
  creditDetailsInterestRate: 'Interest rate',
  creditDetailsExpirationDate: 'Expiration date',
  creditDetailsStatus: 'Credit status',
  creditDetailsChangeReason: 'Reason for change',
  creditDetailsEstimatedDisposableAmount: 'Estimated Disposable amount',
  creditDetailsMinDuration: 'Minimum duration:',
  creditDetailsMinDurationMonths: 'm.',
  creditDetailsMaxMotnhlyPayment: 'Max monthly payment:',
  creditDetailsValidateValuesError:
    'The client should at least have a monthly estimated disposable amount of {0} to be provided a credit of {1}',
  creditDetailsMinDurationcalculationError:
    'The required minimum duration for a max monthly payment of {0} is more than {1} months, which is the upper limit. Please lower the credit amount or increase the max monthly payment, ie by increasing the estimated disposable amount',
  creditDetailsAmountError: 'Max. {0} kr.',
  creditDetailsInterestRateError: 'Max. {0} %',
  creditDetailsMinDurationError: 'Min {0} - Max {1}',
  creditDetailsMaxPaymentLimit: 'Max {0}',
  creditDetailsDateError: 'Max {0} months from today',
  creditDetailsCommentError: 'Please provide a reason',
  creditDetailsSaveButton: 'Save changes',
  creditDetailsSaveButtonSuccess: 'Saved',
  creditDetailsSaveButtonFailure: 'Not saved',

  // company details
  companyDataTitle: 'Clinic information',
  filialStatus: 'Company status',
  accountNo: 'Account No.',
  createDate: 'Created date',
  lastLoginDate: 'Last login date',
  seeLeadsFor: 'See only referrals for this clinic',
  companyName: 'Name of clinic',
  account: 'Registration no. and account no.',
  noPhone: '- No phone no. -',
  noEmail: '- No email -',
  noWebsite: '- No website -',
  noAccountNo: '- No account no. -',
  companyAffiliation: 'Associated with',
  clinicIndexTab1: 'Profile',
  clinicIndexTab2: 'Bookinglink',
  companyMasterDataHeading: 'Master data',
  companyMasterDataExtra: 'Company no:',
  companyMasterDataInfoText: `Please contact customer service on tel. ${brandConfig.contactPhoneHtmlFormated} if you wish to change your CV or account number`,
  companyProfileHeading: 'Profile in clinic index',
  companyProfileUsage:
    "The practitioner index on {0} is Denmark's most complete index of health practitioners. As a Sundhed+ Partner, you have the opportunity to adjust your {1} in the practitioner index, thereby achieving optimal exposure.",
  companyProfileUsageSiteText: 'sundhedplus.dk',
  companyProfileUsageProfileText: 'profile',
  companyProfileStatus: 'Profile status',
  companySeeProfileBtn: 'See profile',
  companyActivateShowInIndexBtn: 'Visible',
  companyDeactivateShowInIndexBtn: 'Hidden',
  companyProfileSaveBtn: 'Save changes',
  companyProfilePhone: 'Telefon',
  companyProfileURL: 'URL',
  companyProfileEmail: 'E-mail',
  companyProfileDescription: 'Description',
  companyProfileDescriptionHint:
    "Write a short text that will appear on the branch's profile in the handler index.",
  companyProfileDescriptionHintCounter: 'symbol',
  companyProfileCategories: 'Skills',
  companyProfileLogo: 'Logo',
  companyProfileTipHeadline: 'Tips for your profile',
  companyProfileTipParagraph1:
    'All Danish healthcare practitioners have been created with a basic profile in the therapist index on {0}',
  companyProfileTipParagraph2:
    'As a {0} Partner, you have the opportunity to expand your profile with, for example, profile text and logo, and thus appear more attractive to users.',
  companyProfileTipParagraph3:
    'The more complete your profile appears, the more new patients you can expect to get via the therapist index.',
  companyProfileBookingLink: 'Booking link',
  companyBookingLinkHeadline: 'Bookinglink',
  companyBookingLinkUsage:
    'Choose what should happen when the "Book appointment" button is clicked on your therapist profile',
  companyBookingLinkOption1Label: 'Booking request',
  companyBookingLinkOption1Text:
    'The user is shown a form to send a booking request with requests for times and treatment. Every time a user makes a booking request, you receive an email notification with a link to view the booking request itself.',
  companyBookingLinkOption2Label: 'Own online booking',
  companyBookingLinkOption2Text:
    'The user is forwarded to your own online booking system.',
  companyBookingLinkOption3Label: 'Direct inquiries only',
  companyBookingLinkOption3Text:
    'The customer is shown a message with an invitation to contact you directly by phone or email.',

  deleteCompanyConfirmMessage:
    'Are you sure that you want to delete the company, {0}?',
  deleteCompanyWarningHeadline: 'Attention!',
  deleteCompanyWarningBody:
    'It is not possible to delete an active company. {0} Please mark the company passive or inactive before trying to delete the company.',
  deleteCompanyChainWarningBody1:
    'It is not possible to delete an active company chain. {0} Please mark the company chain passive or inactive before trying to delete the company chain.',
  deleteCompanyChainWarningBody2:
    'It is not possible to delete a company chain with active associated companies. {0} Please mark all the associated companies passive or inactive before trying to delete the company chain.',
  companyNameShort: 'Company name is too short',
  companyNotCooperatingWarningHeadline: 'The account is inactive',
  companyNotCooperatingWarningBody1: `Since you are currently not cooperating with ${brandConfig.appName}, you don't have the option to add new patients or create new invoices.{0}
  However, you do have access to see the payment status on previously created invoices.`,
  companyNotCooperatingWarningBody2:
    'Please contact customer service by mail, {0} or by phone {1}, if you wish to reactive the account.{2}',

  noCompanyForChainHeadline: '{0} does not have associated companies',
  noCompanyForChainText:
    '{0} does not have any more associated companies. Do you want to delete the company chain?',
  noCompanyForChainBtn1: 'Delete',
  noCompanyForChainBtn2: 'Ignore',

  setCompanyChainActiveMessage:
    'Please note that the related company chain is now set active as well.',
  setCompanyChainActiveLink: 'Go to company chain',
  setCompanyChainActiveBtn: 'OK',
  setDisabledBookingLink:
    'Please note that the booking request is disabled since it requires an email.',
  setEnabledBookingLink:
    'Please note that it is now possible to enable booking requests again.',

  // Client information
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  ssno: 'Social Security Number',
  address: 'Address',
  phone: 'Phone number',
  email: 'Email',
  zip: 'Zip code',
  city: 'City',

  // credit agreement
  creditAgreementTitle: 'Credit agreement',
  invoiceHistoryTitle: 'Invoice history',
  validTimeLabel: 'Valid until',
  expirationTimeLabel: 'expires in',
  expirationTimeToday: 'Expires today',
  expiredTimeLabel: 'Expired on',
  remainingAmount: 'Remaining amount:',
  creditLine: 'Initial amount:',
  noCreditAgreement: '- No credit agreement -',
  noAmount: 'kr. 0,00',
  createActionButton: 'Create',
  editActionButton: 'Edit',
  undoActionButton: 'Undo',
  saveActionButton: 'Save',

  // credit informations
  creditActiv: 'The credit is active',
  expresMore: 'Expires in {0} days',
  expiresOne: 'Expires in 1 day',
  expiresTuday: 'Expires today',
  availableCreditAmunt: 'Available credit amount',
  expired: 'Expired',
  creditNeedsRenewel: 'Credit needs to be renewed',
  noActivCredit: 'No active credit',
  creditTerminated: "The patient's credit has been terminated",
  applicationDenied: 'Application denied',
  patientWitNoCredit: "The patient doesn't have a credit",
  noCreditFacility: 'No credit facility',
  patientWithNocreditAplication: "The patient hasn't applied for a credit",
  patientWithNocreditFacility: "The patient doesn't have a credit facility",
  noDentiCredit: `The patient hasn't applied for a ${brandConfig.appName} Credit yet`,
  helpPatient:
    'Help the patient to apply, by sending the patient an application link. The application process takes less than 2 minutes, and the patient will receive an instant answer.',
  sendAppLink: 'Send application link',
  creditSuspended: 'The credit is suspended',
  creditHasExpired: 'The credit has expired',
  latepayment: 'Debt collection',

  // lead associated clinics
  clinicAccess: 'Clinic access',
  clinicDate: 'Date',
  clinicAssociated: 'Clinic',
  removeAccess: 'Remove access',
  removeAccessConfirmation:
    "Please confirm that you wish to remove {0}'s access to {1}'s credit facility.",
  addclinic: 'Assign clinic',

  // Grant access to the patient's credit facility.
  assignClinic: 'Assign clinic',
  selectPatient:
    "Please select a clinic that should be granted access to {0}'s credit facility.",
  selectPatientNotePart1:
    'When a new clinic is granted access, the patient will be notified about the access by sms.',
  selectPatientNotePart2:
    'The clinic will be notified by e-mail about the new patient.',
  assignGrantedClinic: 'Assign',
  accesGranted: 'Clinic successfully assigned',
  accesGrantedTo: "{0} has been granted access to {1}'s credit facility. ",
  accessGrantedNotification: 'Both patient and clinic has been notified',
  accesFaildTo:
    "Unfortunately it wasn't possible to assign {0} to {1}'s credit facility.",
  accesFaild: 'Something went wrong...',
  accesFaildReason: 'Error code: ',
  accessReasignClinicHeadline: 'The patient is already assigned to the clinic',
  accessReasignClinicText:
    '	The patient, {0}, is already assigned to the clinic, {1}.',
  accessReasignClinicNote: '{0} The patient will now be refreshed',
  accessReasignClinicNoteBold: 'NOTE:',
  accessReasignClinicButton: 'Reload the client',

  // statuses
  VIEW_ALL: 'View all',
  REQUIRES_ACTION: 'Requires action',
  UNTREATED: 'Untreated',
  CLIENT_MAKES_CONTACT: 'Customer will contact',
  TRIED_CONTACTED: 'Tried to contact',
  HAS_BOOKED_TIME: 'Have booked a time',
  ONGOING_TREATMENT: 'Ongoing treatment',
  COMPLETED_WITH_TREATMENT: 'Completed with treatment',
  COMPLETED_WITHOUT_TREATMENT: 'Completed without treatment',
  SMARTLINK: 'SmartLink',
  ACTIVE: 'Partner',
  DEACTIVE: 'Passive',
  PASSIVE: '(Created)',
  PASSIVE_OUT_OF_COMPONENT_STATUS: 'Created',
  DELETED: 'Deleted',
  DEPRECATED: 'Deprecated',
  PAID_OUT: 'Paid out',
  BLOCKED: 'Blocked',
  SUSPENDED: 'Suspended',
  CLOSED: 'Closed',
  REJECTED: 'Passive',

  // toast
  // success
  invoiceAcceptTitle: 'Invoice accepted',
  invoiceAcceptMessage: '{0} has approved an invoice of {1} kr.',
  labelAccept: 'Click here to view the patient',
  statusSuccessTitle: 'Status was updated!',
  successStatusChange1: 'Status for patient',
  successStatusChange2: 'was updated.',
  successStatusChange3: 'Status for clinic',
  successStatusChange4: 'Status for company chain',
  successTextMessageSent: 'SMS was sent successfully to: ',
  resendSMSTitleSuccess: 'SMS sent!',
  successEmailSent: 'E-mail was sent to: ',
  resendEmailTitleSuccess: 'E-mail sent!',
  successPatientChange1: 'Patient details for ',
  successPatientChange2: 'were updated.',
  patientSuccessTitle: 'Patient was updated!',
  patientInternalNoteSuccessMessage: "Patient's internal note was updated",
  patientInternalNoteErrorMessage: "Patient's internal note failed to update",
  invoiceCreateSuccessTitle: 'Invoice successfully created',
  invoiceCreateSuccessMessage:
    '{0} has just received an approval link via SMS and email. The amount of {1} kr. will be paid out when {2} has approved the invoice.',
  editCompanySuccess: 'Clinic details for {0} were updated',
  editCompanySuccessTitle: 'Clinic was updated!',
  clinicInternalNoteSuccessMessage: "Clinic's internal note was updated",
  clinicInternalNoteErrorMessage: "Clinic's internal note failed to update",
  editCompanyChainSuccess: 'Company chain details for {0} were updated.',
  editCompanyChainSuccessTitle: 'Company chain was updated!',
  companyChainInternalNoteSuccessMessage:
    "Company chain's internal note was updated",
  companyChainInternalNoteErrorMessage:
    "Company chain's internal note failed to update",
  successTextDelete: 'Invoice with number {0} was deleted.',
  deleteTitleSuccess: 'Invoice deleted',
  successSettleTitle: 'Invoice was settled',
  successSettleText: 'The invoice with loan number {0} was settled.',

  // error
  invoiceNotAcceptTitle: 'Invoice not accepted',
  invoiceNotAcceptMessage: '{0} has declined an invoice!',
  statusErrorTitle: 'Status not updated!',
  errorStatusChange1: 'Status for patient',
  errorStatusChange2: 'could not be updated.',
  errorStatusChange3: 'Status for clinic',
  errorStatusChange4: 'Status for company chain',
  errorTextMessageSent: 'SMS not sent!',
  resendSMSTitleError: 'SMS not sent!',
  errorEmailSent: 'Email could not be sent!',
  resendEmailTitleError: 'E-mail not sent!',
  patientErrorTitle: 'Patient not updated!',
  errorPatientChange: 'Patient could not be updated.',
  invoiceCreateErrorTitle: 'Invoice not created',
  invoiceCreateErrorMessage: ' could not be created!',
  invoiceCreateError: 'Invoice could not be created!',
  invoiceAmountExcedeedTitle: 'Invoice could not be created',
  invoiceAmountExcedeedMessage:
    'The invoice amount exceeds the sum remaining for the credit.',
  errorEditCompany: 'Clinic could not be updated.',
  errorEditCompanyTitle: 'Clinic not updated!',
  errorEditCompanyChain: 'Company chain could not be updated.',
  errorEditCompanyChainTitle: 'Company chain not updated!',
  errorTextDelete: 'Invoice with number {0} could not be deleted.',
  deleteTitleError: 'Invoice not deleted',
  errorSettleTitle: 'Invoice did not settle',
  errorSettleText: 'The invoice with loan number {0} could not be settled.',

  // Credit Agreement Page
  createAgreementPageTitle: 'Create credit',
  debtCollectionPageTitle: 'Debt collecting',
  createAgreementPageTextFirst: `Do you have a patient who wishes to create a credit through ${brandConfig.appName} ?`,
  createAgreementPageTextSecond:
    'Then you can quickly and easily assist the patient by sending a text message or an email with a link for creation. It takes no more than a maximum of 2 minutes and the patient needs nothing but his NemID to identify.',
  // createAgreementPageTextThird: 'The creation process takes less than 2 minutes and the patient should not use anything other than his NemD to be validated.',
  smsTextTitle: `{0} cooperates with ${brandConfig.appName} to give you the market's best payment scheme.`,
  smsTextHeader: 'Get your non-binding offer here:',
  smsTextListItem:
    '- it takes less than 2 minutes and requires nothing but your NemID.',
  smsTextClosing: 'The offer is free and non-binding.',
  smsTextRegards: 'Yours sincerely',
  smsTextSender: `Team ${brandConfig.appName}`,
  smsText1: 'Click this link to get the best dental credit on the market: ',
  smsText2: '. You need to use your NemID.',
  emailTemplateTextTitle:
    'Would you like to have your bill cut into smaller bites?',
  emailTemplateTextSubtitle: `{0} cooperates with ${brandConfig.appName} to provide you with the best installment for the market.`,
  emailTemplateTextButton: 'Get your non-binding offer',
  emailTemplateTextDescription1:
    'Click on the link above to get a non-binding credit offer - it takes less than 2 minutes and requires nothing but your NemID.',
  emailTemplateTextDescription2: `You do not bind yourself to anything, and the installment will be activated only when you ask your dentist for an invoice to be paid via ${brandConfig.appName}.`,
  emailTemplateTextCopyright: `${brandConfig.emailTemplateTextCopyrightDate} CMC Finans ApS`,
  emailTemplateTextAddress:
    'Charlottenlund Stationsplads 2, 2920 Charlottenlund',
  clickHere: 'Click here:',
  smsLink: brandConfig.presentationSite,
  signatureText: 'Yours sincerely',
  signatureName: 'Dentists on Østerbro',
  telLabel: 'Phone',
  telPlaceholder: 'Enter phone number.',
  submitText: 'Send SMS',
  SMSTabText: 'SMS',
  EmailTabText: 'E-mail',
  emailClickHere: 'Click here:',
  emailLink: `${brandConfig.presentationSite}/opret-aftale`,
  signatureTextEmail: 'Yours sincerely',
  signatureNameEmail: 'Dentists on Østerbro',
  emailLabel: 'E-mail',
  emailPlaceholder: 'Enter e-mail address',
  submitTextEmail: 'Send e-mail',
  month: 'month',
  months: 'months',
  monthsShort: 'mos.',
  days: 'days',
  day: 'day',
  hour: 'hour',
  hours: 'hours',
  minutes: 'min',
  seconds: 'sec',
  second: 'sec',
  ago: 'ago',
  date: 'Date',

  // Create Credit modal
  createCreditModalTitle: 'Create credit',

  // menu
  leads: 'Leads',
  companies: 'Clinics',

  // Guides
  forgotPhone: 'My client has forgotten his cell phone - what to do?',
  loanOfferHowTo: 'My client wants to apply for a loan - how to do?',
  invoiceHowTo: `I want to register an invoice - which can be payed by ${brandConfig.appName}.`,

  // Edit Patient Form
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  addressLabel: 'Address',
  zipLabel: 'Zip code.',
  cityLabel: 'City',
  noteLabel: 'Note',
  phoneLabel: 'Phone no.',
  cprLabel: 'cpr',
  websiteLabel: 'website',
  emailLabelEdit: 'E-mail',
  notePlaceholder: 'Write a note...',
  cprNumberLabel: 'CPR Number',
  cpr: 'CPR No.',

  // invoice
  invoiceDate: 'Date',
  invoiceLead: 'Reference',
  invoiceAction: 'Action',
  invoiceStatus: 'Status',
  invoiceTotal: 'Total',
  invoiceClinic: 'Clinic',
  registerInvoice: 'Register invoice',
  registerActionButton: 'Register',
  leadInvoiceIdPlaceholder: 'Enter ref.',
  leadInvoiceDetailsMessage: 'Turn the phone to register a new invoice',
  leadExpiredTooltipMessage:
    'Not allowed since the credit has expired or remaining amount is below {0} kr.',
  observation:
    'Notice: This patient has a no-interest credit that can only be used for a single invoice.',
  observationExp: 'This credit has expired. Please inform the patient to ',
  reapply: 're-apply.',
  obs1: `It is not possible to add additional invoices as the remaining credit is less than 500 kr. ${
    brandConfig.appName === 'Denti'
      ? 'Please inform the patient to contact Denti if the patient wishes to increase the credit.'
      : ''
  } `,
  obs2: 'It is not possible to add additional invoices as the remaining credit is less than 500 kr.',
  obs3: 'This credit has expired. Please inform the patient to ',
  obs4: 'This credit has expired.',
  obs4Link: 'Send the patient a link to renew the credit.',
  obsZeroCredit:
    'The credit facility is currently fully utilized. Therefore, no additional invoice amounts can be registered at this time.',

  suspensionText: `The credit has been suspended. Please contact ${brandConfig.appName} for more details or assist the patient in {0}`,
  suspensionTextNoInvoice: `The credit has been suspended. Please contact ${brandConfig.appName} for more details.`,
  suspensionLinkText: 'updating their credit card details',
  suspensionTooltip:
    'No additional invoices can be registered at the moment, as the credit has been suspended.',
  resendText:
    'The credit is blocked for registration of additional invoices until the patient has approved/rejected the last entered invoice amount.',
  resendTextOther:
    "Another health professional has registered an invoice that is still awaiting the patient's approval/rejection. It is not possible to register additional invoices until then.",
  resendSMSLinkText: 'Resend sms with approval link to the patient',

  createdByText: '- Created by another clinic -',

  referredDenti: `Referred from  ${brandConfig.appName}`,
  referredDentiTooltip: `If you believe that  ${brandConfig.appName} has not referred this client, please contact {0}`,

  // payment agreement table
  agreementLoanHeading: 'Payment agreements',
  agreementLoanStartDate: 'Date',
  agreementLoanType: 'Type',
  agreementLoanStatus: 'Status',
  agreementLoanInterestRate: 'Interest rate',
  agreementLoanRemainingPrincipal: 'Remaining principal',
  agreementLoanAmount: 'Loan amount',
  agreementRemainingAmount: 'Remaining amount',
  agreementNormalLoan: 'Loan',
  agreementZeroInterest: 'Split payment',

  // newInvoice
  // newInvoiceSuccess
  newInvoiceSuccessTitle: 'The invoice amount is registered',
  newInvoiceSuccessSubtitle: 'This is what comes next',
  stepOne:
    'The patient has just received an e-mail and a text message with an approval link.',
  stepTwo:
    'The patient has to click the link and confirm that he wants to pay the invoice amount of {0} with his {1} Credit.',
  stepThree:
    'You will be notified when the patient has approved, and the money will be paid out to your bank account within 24 hours.',
  newInvoiceSuccessNote:
    'We encourage you to always get your patients to approve the invoice amount before they leave the clinic. This is your guarantee that you will receive the payment.',
  // newInvoiceError
  newInvoiceErrorTitle: 'Something went wrong',
  invoiceNotRegistered: "Unfortunately the invoice hasn't been registered.",
  newInvoiceErrorContactDetails: `Please try again, or contact us on  ${brandConfig.contactPhoneHtmlFormated} or {0} if the problem continues.`,
  // newInvoiceForm
  newInvoiceFullAmount: 'The amount covers a final invoice',
  newInvoicePartialAmount: 'The amount is an advance payment or an estimate',
  newInvoiceRegister: 'Register',
  newInvoiceTitle: 'Register invoice',
  newInvoicePaymentOptionHeadline: 'The amount is paid to',
  newInvoicePaymentOption1: 'Clinic',
  newInvoicePaymentOption2: 'Patient',
  newInvoicePaymentReasonHeadline: 'Reason for direct payment',
  newInvoicePaymentReasonPlaceholder:
    'Briefly describe the reason and add a link to documentation.',
  newInvoiceAmount: 'Invoice amount',
  newInvoiceAmountNote: 'Min. {1} - Max {0}',
  newInvoiceAmountNoteWithoutMin: 'Max {0}',
  newInvoiceReference: 'Reference',
  newInvoiceReferenceNote:
    'Max 10 characters. Will appear on your bank statement',
  newInvoiceGeneralError:
    "Please make sure all fields are filled out correctly and the amount doesn't exceed the patient's available credit line.",
  loadingCompanies: 'Loading companies...',

  // tooltips
  emailResendTooltip: 'Resend email',
  smsResendTooltip: 'Resend sms',
  printInvoiceTooltip: 'Save as PDF',
  loanDetailsTooltip: 'Loan details',
  loanDetailsDraftTooltip: 'Draft of agreement',
  referenceToolTip: `This is your own reference id. This text will appear on your bank statement, when ${brandConfig.appName} transfers the money to you.`,
  latePaymentTooltip:
    'This patient has been sent for debt collection. You can not register additional bills on the credit.',
  cloneTooltip: 'Clone invoice',
  deleteTooltip: 'Delete invoice',
  deletePendingTooltip: 'Cancel invoice',
  settleTooltip: 'Settle loan',

  // noLeadsDetails
  noLeadsQuestion: 'Need help?',
  noLeadsTextFirst: `We are ready to help you on all weekdays from ${
    brandConfig.appName === 'Denti' ? '08.00 to 16.00' : '10.00 to 13.00'
  }.`,
  noLeadsTextSecond: `In addition, we try to answer emails on chat messages within 60 minutes between ${
    brandConfig.appName === 'Denti' ? '16.00 and 22.00.' : '13.00 and 16.00.'
  }.`,

  // Payment plan
  paymentAgreement: 'This is a payment agreement between',
  and: 'and',
  productType: 'Product type',
  productTypeKredit: 'Credit',
  agreementNumber: 'Agreement Number',
  creditAmount: 'Credit Amount',
  duration: 'Loan Term',
  establishmentFee: 'Establishment Fee',
  establishmentFeeShort: 'Establishm. Fee',
  annualInterest: 'Annual Interest',
  apr: 'APR',
  totalCreditCost: 'Total Credit Cost',
  monthlyPaymentsSum: 'Monthly Payments Sum',
  startDate: 'Start Date',
  endDate: 'Final Payment',
  contactDetails: 'Contact Details',
  creditor: 'Creditor',
  companyRegistration: 'Company reg.',
  debtor: 'Debtor',
  paymentSummary: 'Payment Summary',
  paymentSummaryStatus: 'Status of payment',
  paymentInterest: 'Interest',
  paymentInstallment: 'Installments',
  payment: 'Payment',
  inTotal: 'In total',
  total: 'Total',
  important: 'IMPORTANT',
  contractDetails1:
    'If the debtor does not pay the monthly benefits in a timely manner, the creditor has the option to terminate and terminate this payment plan with immediate effect.',
  contractDetails2:
    'The debtor declares to have read and understood the credit agreement and its related contractual provisions, and meets and accepts the terms and conditions stated and the information provided by creditors is correct.',
  contractNote1: 'The establishment fee is 5% of the loan amount.',
  contractNote2:
    brandConfig.appName === 'Denti'
      ? 'The amount of the bill will be paid to the attending dentist after the bill has been entered and printed.'
      : 'The amount of the bill will be paid to the health professional after the bill amount has been registered and accepted by the client.',
  // contractNote3: 'The first payment will be paid on the 1st of the forthcoming month, if the bill is approved before the 20th.',
  // contractNote4: 'If after the 20th, the first payment is to be payed the current month + 1 month.',
  contractNote3:
    'The first payment will be paid on the 1st of the forthcoming month.',
  contractNote4: ' ',
  signature: 'Signature',

  // agreement modal

  agreementModalTitle:
    'Do you want the initial agreement{0} or the current status agreement?',
  agreementModalNoSignatureData: '(no signature data found)',
  agreementModalInitial: 'Initial',
  agreementModalCurrent: 'Current',

  // Confirmation modal
  confirmActionSMS: 'Are you sure you want to send an sms to the number: ',
  confirmActionEmail: 'Are you sure you want to send an email to the address: ',
  confirmActionDelete:
    'Are you sure you want to delete the invoice of {0} kr. for {1}?',
  cancel: 'Cancel',
  confirm: 'Confirm',
  close: 'Close',
  invoiceTitle: 'Please confirm...',
  deleteInvoiceTitle: 'Confirm deletion',
  internalServerErrorTitle: 'Something went wrong...',
  internalServerErrorHeader:
    "It looks like we're experiencing a technical problem.",
  internalServerErrorText:
    'Please try again, or contact us by chat, {0} or phone {1}, if the problem continues.',
  internalServerErrorFooter: 'We are sorry for the inconvenience!',
  settleConfirmationTitle: 'Confirm settlement',
  settleConfirmationText:
    'Please make sure that the remaining balance of {0} has been paid by the client before settling the installment loan.',

  // Invoice status
  waitingForSignature: 'Pending',
  accepted: 'Approved',
  notAccepted: 'Not approved',
  active: 'Approved',
  deprecated: 'Deprecated',
  payedInFull: 'Payed in full',
  latePayment: 'Late payment',
  deleted: 'Deleted',
  settled: 'Settled',
  payed: 'Paid',
  inactive: 'Inactive',
  debtCollection: 'Debt collection',
  restructured: 'Restructured',
  consolidated: 'Consolidated',
  updateCreditCard: 'Update credit card',
  onBoarding: 'Onboarding',
  member: 'Member',
  cancelled: 'Cancelled',
  activeCredit: 'Active',
  expiredCredit: 'Expired',
  rejectedCredit: 'Rejected',
  suspended: 'Suspenderet',
  latePaymentCredit: 'Debt collection',
  noCredit: 'No credit',
  denti: 'Denti',
  sundhed: 'Sundhed+',

  // New invoice history statuses
  pendingStatus: 'Pending',
  approvedStatus: 'Approved',
  approvedStatusZeroInterest: 'Approved (BNPL)',
  paidOutStatus: 'Paid out',
  paidOutStatusZeroInterest: 'Paid out (BNPL)',
  declinedStatus: 'Declined',
  expiredStatus: 'Expired',
  seeReceiptKebabMenu: 'See receipt',

  // Payment agreement statuses
  loanActiveStatus: 'Active',
  loanConsolidatedStatus: 'Consolidated',
  loanRestructuredStatus: 'Restructured',
  loanLatePaymentStatus: 'Late payment',
  loanPayedStatus: 'Payed',
  loanSettledStatus: 'Settled',

  // Change credit details
  heading: 'Update payment card',
  subheading: 'Credit number. {0}',
  firstParagraph:
    'When you have entered your credit card information and press "Save", our payment solution partner automatically attempts to withdraw 0 kr. from your card. This happens to validate your entered credit card for being active.',
  secondParagraph:
    'Not until after the validating is confirmed, potential dunning payments will be withdrawn from the card.',
  invoiceListHeading: 'Your active installment loans',
  line1: '{0}, {1} (Loan amount: {2} kr.).',
  line2: '{0}: {1} mdr., Last payment: {2}. ',
  amountC: '{0} kr.',
  totalHeading: 'Total monthly payment:',
  contactHeading:
    'If you have any questions, you are always welcome to contact us at {0} or tel. {1}.',
  errorHeading: 'No subscription found',
  errorP1: 'We cannot find the requested subscription in our system.',
  errorP2:
    "Maybe the link is outdated or maybe we've made a mistake. If the problem persists, please don't hesitate to contact us at {0} or tel. {1}.",

  // Debt collection page
  remainingPrincipal: 'Principal',
  debtCollectionDate: 'Sent to debt collecting',
  interestInArreas: 'Interest in arrears',
  collectionAgency: 'Collection Agency',
  caseNumber: 'Case No.',
  caseNumberLabel: 'Enter case no.',
  note: 'Note',
  initialLoanAmount: 'Loan amount',
  debtCollectionTitle: 'Debt collection loans',
  registerDebtLoanButton: 'Register debt loan',
  invoiceNo: 'Agreement No.',
  loanInterestRate: 'Interest rate',
  loanCreatedDate: 'Created date',
  debtCreateSuccessMessage:
    'The loan with number: {0} was registered in debt collection.',
  debtCreateSuccessTitle: 'Loan registered in debt collection',
  loanCreateErrorMessage:
    'The loan could not be registered in debt collection!',
  loanCreateErrorTitle: 'Loan not registered in debt collection',

  // lead options tabs
  overviewTab: 'Overview',
  logTab: 'Log',
  logTabDate: 'Date',
  logTabEvent: 'Event',
  logTabUser: 'User',

  // loan details view
  loanDetails: 'Payment plan',
  loanAmount: 'Loan amount',
  setupFee: 'Payment fee',
  interestRate: 'Interest rate/APR',
  loanDuration: 'Duration',
  installments: 'Instalments',
  paid: 'Paid',
  paidReepay: 'Paid (Reepay)',
  paidManual: 'Paid (Manuel)',
  overdue: 'Overdue',
  loanDetailsFuturePayment: 'Future payment',
  paidInTotal: 'Paid in total',
  repayments: 'Repayments',
  interests: 'Interests',
  additionalCosts: 'Additional costs',
  principalAsOf: 'Remaining debt of {0}',
  remainingDebt: 'Remaining debt as of {0}:',
  signatureModalTitle: 'Signature data is not available',
  signatureModalBtn: 'Ok',
  loanDetailsHeaderDate: 'Date',
  loanDetailsHeaderAction: 'Action',
  loanDetailsHeaderOther: 'Other',
  loanDetailsHeaderOutstandingDebt: 'Out. debt',
  loanDetailsHeaderHash: '#',
  loanDetailsHeaderDueDate: 'Due date',
  loanDetailsHeaderMonthlyPayment: 'Payment',
  loanDetailsHeaderStatus: 'Status',
  loanDetailsClose: 'Close',

  // loan calculator
  calculatorTitle: 'Approximately how much does the treatment cost?',
  calculatorAmount: 'Amount:',
  calculatorQuestion1: 'How many months will you repay?',
  calculatorMonth: '{0} mos',
  calculatorMonthlyBenefit: 'Monthly Payment:',
  calculatorEstablishmentCosts:
    'Establishment costs (included in the service):',
  calculatorInterestRate: 'Interest rate:',
  calculatorTotalCosts: 'Total borrowing costs:',
  calculatorAnnualCosts: 'Annual cost as a percentage (APR):',
  calculatorTotalRepayment: 'Total Repayment:',
  calculatorNote:
    'The example above is based on a debt interest rate of {0}. When you apply for a credit, a personal credit offer is created with a credit amount and interest rate based on your finances',
  calculatorApplyButton: 'Apply now',

  // clear all filters
  clearAllFilters: 'Clear filters',

  // leads filter
  filterTitle: 'Filter',
  loanStatus: 'Loan status',
  clinic: 'Clinic',
  dateInterval: 'Date range',
  resetFilter: 'Clear',
  submitFilter: 'Apply',
  filterSearchPlaceholder: 'Clinic name or ID',
  filterSearchPlaceholderClinic: 'Clinic',
  patientStatus: 'Patient status',
  creditStatus: 'Credit facility status',
  brandStatus: 'Brand',
  deleteFilterText: 'Remove the filter',

  // company filter
  companyStatus: 'Company status',
  companyBrandStatus: 'Company brand',
  companyStatusOption: 'Active',
  companySmartLinkOption: 'Smartlink',
  companyCategoryStatus: 'Company category',
  companyCategoryPlaceholder: 'All company categories',

  // companyChain filter

  companyChainStatus: 'Company chain status',
  companyChainTrialPeriod: 'Trial period ends on',

  // payment status
  paymentStatus: 'Payment status',
  latestPayment: 'Latest payment',
  currentRemaing: 'Current remaining principal',
  cardStatus: 'Card status',
  cardNumber: 'Card No.',
  caseStatus: 'Case status',
  personResponsible: 'Responsible',
  billwerkPlus: 'Open Billwerk+',
  updateCreditCardOption: 'Add/update credit card',
  retryWithdrawal: 'Retry withdrawal',

  // retryWithdrawal modal
  retryWithdrawalLoadingTitle:
    "We are now attempting to charge the member's payment card",
  retryWithdrawalLoadingText: 'Please wait...',

  retryWithdrawalSuccessTitle: 'Payment Successful',
  retryWithdrawalSuccessText:
    'The amount of {0} was successfully charged to the linked payment card. The member is no longer in arrears.',
  retryWithdrawalButtonText: 'Ok',

  retryWithdrawalErrorTitle: 'Payment Failed',
  retryWithdrawalErrorText:
    'The amount of {0} could not be charged to the linked payment card. The member is still in arrears.',

  // dunning statuses
  dunningCardTitle: 'Dunning',
  dunningNotContacted: 'Not contacted',
  dunningNoContact: 'No contact',
  dunningDialogue: 'Dialogue',
  dunningAgreement: 'Agreement',
  dunningDebtCollection: 'Debt collection',
  dunningNoStatus: 'No status',
  dunningCauseTitle: 'Cause',
  dunningResponsibleDropdownPlaceholder: 'Please choose...',
  dunningCardStatusTitle: 'Card status',
  dunningLastPaymentPayedDateTitle: 'Last payment date (payed)',

  // card status
  noCard: 'No card',
  cardActive: 'Active',
  cardDeclined: 'Declined',

  never: 'Never',

  // patients in dunning
  loansInDunning: 'Loans in dunning',
  personInDunning: 'Pers. in dunning',
  amountInDunning: 'Total dunning amount',
  totalDuning: 'Total remaining balance',
  exportDebtCollection: 'Export for debt collection',
  search: 'Search',
  patientsDunningsResults: 'Results: {0} of {1}',

  clientName: 'Name',
  totalLoanAmount: 'Loan amount',
  lastPaymentPayedDate: 'Last paid',
  remainingBalance: 'Rem. balance',
  monthlyPayment: 'Mo. payment',
  creditCardState: 'Card status',
  dunningStatus: 'Status',
  userInCharge: 'Responsible',
  dunningType: 'Cause',

  // restructuring
  restTitle: 'Restructuring',
  restDate: 'Date',
  restReference: 'Reference',
  restInterest: 'Interest',
  restDuration: 'Duration',
  restAmount: 'Amount',
  restBalance: 'Balance',
  restTotalRemBalance: 'Total remaining balance per {0}:',
  restFee: 'Restructuring fee',
  restFeeShort: 'Rest. fee',
  restLatePaymentFee: 'Late payment fees',
  restLatePaymentFeeShort: 'LP fees',
  restSelectFirstOfMonth: 'Select the 1st of an upcoming month',
  restExtraPayment: 'Extra payment',
  restFirstPaymentDate: 'First payment date',
  restFirstPaymentDateShort: '1st. payment',
  restInterestRate: 'Interest rate',
  restInterestRateWarning: 'Set the interest rate',
  restCause: 'Cause',
  restMonths: '{0} months.',
  restAPR: 'APR',
  restMonthlyPayment: 'Monthly payment',
  restLoansRestructure: 'Restructure loans',
  restAllowDuration: 'Let the patient adjust the duration',

  // restructuring modal
  restModalTitle: 'Confirm restructuring',
  restModalText:
    "Are you sure that you want to transfer the patient's debt to a new loan with a duration of {0}, an interest rate of {1}, a restructuring fee of {2} and a monthly payment of {3}?",
  restModalConfirm: 'Confirm',
  restModalCancel: 'Cancel',

  // restructuring notification
  restNotificationTitle: 'The restructuring loan was created',
  restNotificationBody:
    'An approval link has been sent to {0} via SMS and email.',
  restNotificationTitleError: 'The restructuring loan could not be created',

  // dropdown
  dropPatientInDunning: 'Client in dunning',
  dropAmountReturnClinic: 'Amount returned from clinic',
  dropPartialRedemption: 'Partial redemption',
  dropClientRequest: "At client's request",
  dropOther: 'Other',
  dropPlaceholder: 'Choose',

  // dunning modal
  dunningExportTitle: 'Export for debt collection',
  dunningExportMessage1:
    'If you decide to continue, all loans associated with patients that have been given a debt collection status will be terminated.',
  dunningExportMessage2:
    'This includes {0} loans across {1} patients, with a total remaining balance of {2}',
  dunningExportMessage3:
    'Are you sure you want to export these {0} loans for debt collection?',
  dunningComfirm: 'Export',
  dunningCancel: 'Cancel',
  generatingFile: 'Generating file ...',

  // duning Cause
  dunningCauseDunning: 'Dunning',
  dunningCauseDeceased: 'Deceased',
  dunningCauseCounseling: 'Debt counselling',
  dunningCauseManual: 'Manual payment',

  // duning card state
  dunningCardStateActive: 'Active',
  dunningCardStateInactive: 'Inactive',
  dunningCardStateFailed: 'Failed',
  dunningCardStateDeleted: 'Deleted',
  dunningCardStateMissing: 'Missing',

  // suspend
  suspendPatient: 'Suspend client',
  unsuspendPatient: 'Unsuspend client',
  suspendMessage:
    'Credit is closed. It is no longer possible to register invoice amounts.',
  suspendClose: 'Closed',
  suspend: 'Suspend',
  reactivate: 'Reactivate',
  cancelMembership: 'Cancel membership',
  removeSuspension: 'Remove suspension',

  // clients messages
  clients: 'Clients',
  creditExpired: 'The credit has expired',
  creditClosed: 'The credit has been closed',
  clientSuspended: 'The client has been suspended',
  clientDebtCollection: 'The client has been sent to debt collection',
  clientDeceased: 'The client is deceased',
  clientCreditDisabled: 'Member has no active credit',
  clientCreditRejected: 'The credit is rejected',

  paymentApproval: 'Payment is waiting approval',

  // paid out
  paidOutTitle: 'Paid out',
  paidOutInvoice: 'Invoice amount:',
  paidOutCommision: 'Commission ({0}%):',
  freePaidOutCommision: 'Free trial period:',
  paidOutDate: 'Paid out on {0}:',
  payoutToClientTrue:
    "The amount has been paid to the patient's NemKonto (cpr nr. {0}) with the following text: “{1}”.",
  payoutToClientFalse:
    'The amount has been paid out to your NemKonto (cvr nr. {0}) with the following text: “{1}”.',
  paidOutText2:
    "The amount has been paid out to your bank account  ({0} {1}) with a payment message containing the patient's name and birth date along with the reference “{2}”.",
  paidOutText3:
    'Please note that it can take up to 2 banking days from the payout date, before the amount shows up in your account.',
  paidOutDenti: `${brandConfig.appName.toUpperCase()} {0}`,

  // accept
  // step 1
  step1Title: `Approve payment with your ${brandConfig.appName} Credit`,
  loanClinic: 'Clinic:',
  invoiceAmount: 'Invoice amount',
  availableCredit: 'Available credit:',
  agreeBillQuestion: 'Do you agree with the bill amount?',

  // step 2
  step2Title: 'Adjust your monthly payment',
  billAmount: 'Invoice amount:',
  existingLoans: 'Existing loan:',
  acceptSetupFee: 'Setup fee:',
  acceptSetupFeeRest: 'Restructuring fee:',
  acceptInterestRate: 'Interest rate:',
  acceptLoanDuration: 'Duration',
  acceptMonthlyPayment: 'Monthly payment:',
  acceptLoanApr: 'APR:',
  acceptLoanStep2Headline:
    'Drag the slider to adjust how much you want to pay each month. You can repay a maximum of {0} months.',
  acceptLoanStep2SliderLabel: 'Adjust your monthly allowance:',
  acceptLoanStep2Note:
    'When you approve the payment, a new payment agreement is created with a term of {0} months.',
  acceptLoanStep2NoteAdditional:
    'This agreement will replace your current payment agreement, and the current outstanding debt of {0} is recognized in the new payment agreement.',
  acceptTotalCreditCost: 'Total credit costs:',
  totalRepayment: 'Total repayment:',
  tipSetupFeeTitle: 'What is "setup fee"?',
  tipSetupFeeText1: `Each time you use your ${brandConfig.appName} Credit, a setup fee corresponding to 5% of the invoice amount is added to the loan amount. Together with the interest, the setup fee constitute the so-called “total credit costs”. There are no ongoing administration costs or payment fees associated with using the credit.`,
  tipSetupFeeText2: `TIP: As the fee is a fixed percentage, the costs will ultimately be the same for you, regardless of whether you use your ${brandConfig.appName} Credit to finance one payment of DKK 1,000 or two payments of DKK 500.`,
  tipExistingLoanTitle: 'What is "existing loan"?',
  tipExistingLoanText1:
    'The amount is the current principal of your current loan. The amount is recognized in the new loan, so you always have only one loan to deal with, and thus only pay one monthly installment.',
  tipExistingLoanText2:
    'Please note that if the interest rate on your current loan is different from the interest rate on the loan, then the new interest rate will apply to the entire loan amount, including the principal from the old loan.',
  tipDurationText1:
    'You choose how many months you want the loan to last. Pull the slider to adjust the duration of the loan and see how it affects the monthly payment.',
  tipDurationText2:
    'In most cases you will be free to choose a duration between {0} and {1} months. If you find that the interval is reduced, it is due to government legislation that prevents us from offering loans where the APR exceeds 25%.',

  // step 2 restructured
  step2RestTitle: 'Restructuring of payment agreement',
  currentPrincipal: 'Current principal:',
  latePaymentFee: 'Late payment fees:',
  restructureFees: 'Restructuring fee:',
  newDuration: 'New duration:',
  firstInstallmentDueDate: 'First installment is due on:',
  futurePayment: 'Future monthly payment:',
  reepayInvoiceSettleMessage: 'Waiting for invoice to be settled',
  reepayInvoiceSettledError:
    "Sadly, we couldn't settle the invoice, please contact our customer support for help to fix the issue.",

  // step 3
  step3Title: 'Payment agreement and terms and conditions',
  titleAgreement: 'Payment agreement',
  subTitleAgreement: 'Terms and conditions',
  numberAgreement: 'Agreement no.',
  establishmentDate: 'Date of establishment',
  firstInstallmentDate: 'First installment is due on',
  lastInstallmentDate: 'Last installment is due on',
  loanAmountAgreement: 'Loan amount',

  // step 4
  step4Title: 'Is this your first visit to',
  firstVisitText:
    'In order to provide the best possible service to the clinic, we would kindly ask you to tell us if this is the first time you are using this clinic.',
  yesVisit: 'Yes, this is the first time I am using this clinic',
  noVisit: 'No, I have been at this clinic before',

  // step 5
  step5Title: 'Payment method',
  paymentDetails:
    'Going forward, we will deduct {0} on your credit card every month.',
  creditCardDetails: 'Current credit card',
  creditCardExpires: 'Expires',
  useCurrentCard: 'Continue to use this credit card',
  useDifferentCard: 'I prefer to use another credit card',
  loadingMessage: 'Please wait...',

  // step 6
  step6Title: 'Add your payment information',
  registerCardText:
    'In order for us to deduct your monthly payment, please register a credit card.',
  registerCardLater:
    'I prefer to register my credit card details at a later point.',

  // step accept
  stepAcceptTitle: 'Success!',
  acceptWithCardText:
    'The payment agreement has been established, and we will make sure that the clinic receives its payment within 24 hours.',
  acceptWithCardText1: `Going forward, the monthly payment on your ${brandConfig.appName} Credit will be {0}, which wil be deducted from your credit card every month for {1} months.`,
  sendEmailWithCard:
    'We have sent you an e-mail at {0} with all details regarding the payment agreement.',
  sendEmailWithoutCard:
    'We have sent you an e-mail ({0}) with all details regarding the payment agreement, as well as a link to register you credit card.',
  acceptFirstPayment: 'First payment: {0}.',
  acceptObservation: `It is important that you register your credit card before the first payment date, as you may otherwise be charged reminder fees. Please note that you can't use your ${brandConfig.appName} Credit for additional payments until you have added your card information.`,

  // step accept restructured
  acceptRestText: 'Your payment plan has been successfully restructured.',
  acceptRestText1: `Going forward, the monthly payment on your ${brandConfig.appName} Credit will be {0}, which wil be deducted from your credit card every month for {1} months.`,
  acceptRestDueDate: 'First installment is due on: {0}.',
  acceptRestText2:
    'We have sent you an e-mail at {0} with all details regarding the payment agreement.',

  // step decline
  stepDeclineTitle: 'Payment declined',
  declineText: `You have declined to pay the invoice with your ${brandConfig.appName} Credit.`,
  declineText1:
    'If your refusal is due to the fact that you believe that the invoice amount of {0} is incorrect, please inform the therapist of this.',
  declineText2: `If you have declined the agreement because you have questions about the payment terms or your ${brandConfig.appName} Credit in general, you are very welcome to contact customer service.`,
  declineCallText: 'Call customer service at {0} {1}',
  declineEmailText: 'Send an email to customer service',
  showMoreTehnicalInformation: 'Click this to get technical information',
  httpText: 'HTTP:',
  httpMessage: 'Message:',
  httpCause: 'Cause:',

  // step decline restructured
  declineRestText:
    'You have declined the restructuring of your payment agreement and your current payment agreement is therefore still in effect.',
  declineRestText1:
    'If you have chosen to decline due to an error in the amounts or payment terms listed, please contact customer service as soon as possible and bring the matter to their attention.',

  // actions
  declineLoan: 'Decline',
  continueStep: 'Continue',
  acceptStep: 'Accept',
  goBack: 'Back',

  // exceptions
  exceptionDeletedTitle: 'The bill no longer exists',
  exceptionDeletedText1:
    'It appears that the bill you are trying to approve has been deleted from our system.',
  exceptionDeletedText2:
    'This can happen if, for example, the clinic discovers that the bill amount is incorrect.',
  exceptionDeletedText3:
    'In such cases, the clinic will often create a new bill for the correct amount, and you will then have received another message with a link to approve the updated bill.',
  exceptionDeletedText4:
    'If you have any questions, you are always very welcome to contact us at {0} or {1}',

  exceptionNotFoundTitle: '404 - not found',
  exceptionNotFoundText1:
    'The page you wish to visit does not appear to exist.',
  exceptionNotFoundText2:
    "We must have made a mistake, so don't hesitate to contact us at {0} or {1} if the problem persists.",

  exceptionComfirmedTitle: 'The payment agreement has already been activated',
  exceptionComfirmedText1:
    'Since we send the approval link both as an e-mail and as an SMS, you have probably already been able to approve the bill and activate the payment agreement.',
  exceptionComfirmedText2:
    'In that case, you should have already received an email with detailed information about the deal.',
  exceptionComfirmedText3:
    'If this is not the case, or you have questions about your agreement, you are always very welcome to contact us at {0} or {1}',

  exceptionExpiredTitle: 'The link has expired',
  exceptionExpiredText1:
    'For security reasons, approval links are only valid for 48 hours.',
  exceptionExpiredText2: `To receive a new approval link, please contact your practitioner or ${brandConfig.appName} on {0} or on phone {1}.`,

  resetLinkExpiredTitle: 'This link is not working anymore',
  resetLinkExpiredSubtitle: 'This could be due to:',
  resetLinkExpiredReason1: 'Link is expired',
  resetLinkExpiredReason2: 'Link has been used',
  resetLinkExpiredReason3: 'Link has never worked',
  resetLinkParagraph:
    'Please contact customer service at {0} or tel. {1} if the problem persists.',

  // error
  errorServerTitle: 'Something went wrong...',
  errorServerText1:
    "We are sorry for the inconvenience. It looks like we're experiencing a technical problem. Please try again.",
  errorServerText2:
    'If the problem continues, please contact customer service, who will help you find a solution to your problem.',

  // server rebooting error
  serverRebootingHeadline: 'Looks like we just updated',
  serverRebootingText:
    'We are sorry for the inconvenience. It looks like a technical problem happened, while we were updating. Please try again.',
  serverRebootingButton: 'Close',
  serverRebootingOKButton: 'OK',
  // server down error
  errorServerDownTitle: 'There seems to be an issue with the server connection',
  errorServerDownText1:
    'We are sorry for the inconvenience. It looks like there is an issue with your network connection. Please try again.',
  errorServerDownText2:
    'If the problem continues, please contact customer service, who will help you find a solution to your problem.',

  // add patient
  sidebarMenuAddPatient: 'Add patient',
  buttonTextAddPatient: 'Add patient',
  buttonTextClose: 'Close',
  buttonTextTryAgain: 'Try again',
  buttonTextSendLink: 'Send link',
  buttonTextShowClient: "Show patient's credit",

  // step 1
  apStep1Title: `Does the patient already have a ${brandConfig.appName} Credit?`,
  apStep1HelpText: `Have you been informed that the patient already has an active ${brandConfig.appName} Credit, which the patient wants to use to finance his treatment?`,
  apStep1YesTitle: `Yes, the patient has a ${brandConfig.appName} Credit`,
  apStep1YesText: `When you add a patient with a existing ${brandConfig.appName} Credit, you get instant access to record invoice amounts on the patient's credit.`,
  apStep1NoTitle: `No, the patient doesn't have a ${brandConfig.appName} Credit`,
  apStep1NoText: `Help the patient get a ${brandConfig.appName} Credit by sending the patient an application link.`,
  // step 2
  apStep2Title: `Add patient with existing ${brandConfig.appName} Credit`,
  apClinicLabel: 'Clinic to which the patient must be added',
  apCprNumber: "Patient's cpr number:",
  apLettersName: "The first two letters of the patient's name:",
  apStep2TextInfo: `When you add a new patient, the patient will receive a message that {0} have now gained access to his ${brandConfig.appName} Credit.`,
  apStep2PlaceholderTextForClinic: 'clinic',

  // step 3
  apPatientAddedTitle: 'The patient has been added',
  apPatientAddedText1: `You have been granted access to {0}'s ${brandConfig.appName} Credit.`,
  apPatientAddedText2:
    'We have also sent {0} a message that {1} now has access to record invoice amounts on the credit.',

  apCreditExpiredTitle: 'The credit has expired',
  apCreditExpiredText1: `You have been granted access to {0}'s ${brandConfig.appName} Credit.`,
  apCreditExpiredText2:
    'However, before you can register an invoice amount, {0} must first renew the credit - The process takes 2 minutes and can be done via mobile. We have sent {1} a text message with a link to apply for a credit renewal.',

  apNotFoundTitle: 'The patient was not found',
  apNotFoundText1:
    'We could not find a patient with cpr number {0} and a first name starting with {1}.',
  apNotFoundText2:
    'Please check that you have entered the correct information.',
  apNotFoundText3: `If the patient does not already have a ${brandConfig.appName} Credit, you can instead`,
  apNotFoundLink: 'send the patient an application link.',

  apCreditClosedTitle: 'The credit is closed',
  apCreditClosedText1: `{0}'s ${brandConfig.appName} Credit has been closed and can no longer be used.`,
  apCreditClosedText2:
    'The patient has already been notified of this and we suggest that the patient pay for his treatment in cash or by credit card.',

  apSendLinkTitle: 'Send application link',
  apSendLinkText1: `Do you have a patient who wants apply for a ${brandConfig.appName} Credit?`,
  apSendLinkText2:
    "Enter the patient's email address or mobile number to send the patient a link to apply for a credit. The application process takes less than 2 minutes and can be completed using MitID alone.",
  apSendLinkNumber: "Patient's mobile number",
  apSendLinkEmail: "Patient's email address",
  apSendLinkOrLabel: 'or',
  apConfirmAgreement:
    brandConfig.appName === 'Denti'
      ? 'I confirm that the patient has given permission to receive an SMS/email from Denti, with a link to apply for a Denti credit.'
      : 'I confirm that the patient has given permission to receive an SMS/email with information about Sundhed+ and Sundhed+ Credit.',
  apPhoneLabel: 'Phone number',

  apLinkSuccessTitle: 'The application link was sent',
  apLinkSuccessText1:
    'The patient should receive the link within a few seconds.',
  apLinkSuccessText2: `If the patient applies via the link sent, and is approved for a ${brandConfig.appName} Credit, you will be able to access the credit immediately thereafter.`,
  apLinkSuccessTextEmail: 'We will also send you a notification by email.',
  apLinkSuccessTextSms: 'We will also send you a notification by sms.',

  apClientAssignedTitle: 'You already have access',
  apClientAssignedText1: `You already have access to {0}'s ${brandConfig.appName} Credit.`,
  apClientAssignedText2:
    '{0} Use the search box at the top of the list of patients to search among all the patients you have access to. You can search both by name or cpr-number.',
  apClientAssignedTip: 'TIP:',

  // live agent
  multipleMatches: 'Multiple matches,',
  multipleMatchesNote:
    'There seems to be more than one patient in DAM that match the sender of this ticket.',
  openDAM: 'Open DAM',
  noMatch: 'No match',
  noMatchNote:
    "We didn't succeed in finding any patient in DAM that matches the sender of this ticket",
  openPatientDAM: 'DAM',
  openPatientReepay: 'Reepay',
  openPatientCustomerIo: 'Customer.io',
  chooseClinic: 'Email-flow to choose a clinic',
  membershipCreated: 'Membership created',
  memberAvailableCredit: 'Available credit',
  creditLastRenewed: 'Credit last renewed',
  rmnPrincipal: 'Remaining principal',
  activeLoans: 'Active loans',
  loans: 'loan(s)',
  creditCard: 'Credit card',
  clinicsWithAccess: 'Clinics with access to the credit:',
  applicationRejected: 'Application rejected',
  notApplied: "Hasn't applied",
  applicationNotFinished: "The patient hasn't finished his application yet.",
  liveAgentUpdateCreditCard: 'Update',
  liveAgentUpdateCreditCopied: 'Copied',
  liveAgentUpdateCreditLabel: 'Add card',

  // live agent credit status
  liveAgentCreditStatus_ACTIVE: 'Active',
  liveAgentCreditStatus_REJECTED: 'Application denied',
  liveAgentCreditStatus_SUSPENDED: 'Suspended',
  liveAgentCreditStatus_LATE_PAYMENT: 'Sent to debt collecting',
  liveAgentCreditStatus_DISABLED: "Hasn't applied",
  liveAgentCreditStatus_EXPIRED: 'Expired',

  // live agent tabs
  liveAgentTabBookingHeadline: 'Booking details',
  liveAgentTabPatientHeadline: 'Patient details',
  liveAgentTabBookingParagraphV1: 'Status of booking request',
  liveAgentTabBookingParagraphV2: 'Status on processing creation',
  liveAgentTabBookingPlaceholder: 'Select status',
  liveAgentTabBookingCreated: 'Request created',
  liveAgentTabBookingExpiration: 'Expires in',
  liveAgentTabBookingClinic: 'Clinic',
  liveAgentTabBookingClinicStatus: 'Clinic status',
  liveAgentTabBookingSwitchText:
    'The clinic has agreed to receive booking requests by email',
  liveAgentTabBookingReceiverEmail: 'Recipient(s)',
  liveAgentTabBookingEmailCreatedDate: 'Email sent',
  liveAgentTabBookingButtonSend: 'Send booking request',
  liveAgentTabBookingButtonSee: 'See clinic in DAM',
  liveAgentTabBookingButtonCompanySlug: 'See profile in the practitioner index',
  liveAgentTabBookingButtonCustomerIO: 'See email in Customer.io',

  // chain
  registrationNumber: 'Account No.',
  trialEnd: '0% marketing fee until',
  marketingFee: 'Marketing fee',
  marketingFeeCustomMsg: 'standard value',
  marketingFeeEditCustomMsg:
    'Standard value (currently {0}) will be used if no fee is set',
  marketingFeeError: 'Dot is not allowed, please use comma',
  new: 'New',
  newChainTitle: 'Create company chain',
  cvrNumber: 'CVR nr.',
  chainStatus: 'Company Chain status',
  chainStatusClosed: 'Closed',
  chainDeleteConfirmMesage:
    'Are you sure that you want to delete the company chain, {0}, and all associated locations?',
  blacklistCVR: 'Blacklist CVR-number, {0}',
  chainEditCVRHeadlineBlackList: 'Blacklist CVR',
  chainEditCVRBodyBlackList:
    'To avoid the CVR from being automatically imported again, please blacklist the previous CVR.',
  chainEditCVRBodyBlackListSecond:
    'If the CVR number should be used for another existing company chain, you can confirm without clicking the checkbox.',
  chainFee: 'Fee',
  chainRevenue: 'Revenue',
  companyCreateDate: 'Created',
  seeDetails: 'See Details',
  companyChainsMenuTitle: 'Company Chains',
  seeCustomerIO: 'See in Customer.io',
  navigateToApplications: 'Applications',
  edit: 'Edit',
  delete: 'Delete',
  cvrError: 'CVR has to be 8 digits',
  cvrExists: 'Company chain with cvr {0} already exists.',
  cvrExistsShort: 'CVR already exists',
  companyChainNameError: 'Please enter a name',
  kotnirError: 'Account No. is too short',
  searchForchain: 'Search for a company chain...',
  errorCvcNew: "CVR isn't valid. It has to be 8 digits.",
  errorShortCompanyName: 'The company name is to short',
  errorOnlyNumbersAllowed: 'Only numbers are allowed',

  chainDeleteError: "The company chain {0} wasn't deleted due to an error.",
  companyDeleteError: "The company {0} wasn't deleted due to an error.",
  nameAndPhone: 'Name and address',
  clinicNote: 'Note for clinic',
  createdOn: 'Created on',
  memberSince: 'Member since',
  membershipCanceled: 'Membership cancelled',
  deleteLeadTitle: 'Delete (GDPR)',
  deleteLeadContent:
    'Please confirm that you wish to delete {0} from the system. All personal data will be permanently deleted and cannot be restored.',
  cancelLeadConfirmTitle: 'Cancel membership',
  canceldLeadConfirmContent:
    "Please confirm that you wish to cancel {0}'s membership.  ",
  onboardingLidProp: 'Signup aborted',
  chainUsers: 'Users',
  chainAddUser: 'Add User',
  chainCreateUser: 'Create user',
  chainEditUser: 'Edit user',
  chainUserType: 'User type',
  chainAssociatedLocation: 'Associated location',
  chainSelectLocation: 'Select location',
  chainAdministrator: 'Administrator',
  chainManager: 'Manager',
  chainEmployee: 'Employee',
  chainAdmin: 'Administrator',
  chainEdit: 'Edit',
  chainDelete: 'Delete',
  chainResendInvitation: 'Resend Invitation',
  chainNever: 'Never',
  chainPendingInvitation: 'Pending invitation',
  chainResetPAssword: 'Reset Password',
  chainResendInvitationNote:
    'Do you wish to resend the invitation with the activation link to the user ',
  chainDeleteUserNote: 'Do you wish to delete the user ',
  chainInvoicesRegisteredNote:
    'Invoices registered by the user will not be deleted.',
  chainSendUserNote1: 'Do you wish to send the user ',
  chainSendUserNote2: ' a link to reset their password?',
  chainFullAccess:
    'Full access to all patients and settings on company chain level',
  chainAccessSettings:
    'Access to settings and patients for a specific location only',
  chainAccessAdmin: 'Full access to all patients and settings for all of DAM',
  chainUserRole: 'Role',
  chainUserLatestLogin: 'Latest login',
  chainUserConfirmResetTitle: 'Confirm password reset',
  userNoCompanySelected:
    'In order to create a user, the company chain has to have at least one associated location.',
  userSaveBtn: 'Save',
  userCancelBtn: 'Cancel',
  selectUserTypePlaceholder: 'Select user type',
  selectUserLocationPlaceholder: 'Select location',
  create: 'Create',
  chainSendInformation:
    'An email with a link to activate the user and create a password will be sent to the specified email address.',
  userRegistrationActiveUser: 'Activate user',
  userRegistrationCreateSecurePassword:
    'Create a secure password to get started.',
  userRegistrationPassword: 'Password',
  userRegistrationPasswordRepeat: 'Repeat password',
  userRegistrationPasswordRequermets: 'Password requirements',
  userRegistrationPasswordRequermets1: 'Min. 8 characters',
  userRegistrationPasswordRequermets2: 'Min. 1 uppercase letter',
  userRegistrationPasswordRequermets3: 'Min. 1 lowercase letter',
  userRegistrationPasswordRequermets4: 'Min. 1 number',
  userRegistrationCancel: 'Cancel',
  userRegistrationContinue: 'Continue',
  userRegistrationUserInformation: 'User information',
  userRegistrationEditContactInformation:
    'Add/edit your additional contact information',
  userRegistrationFirstName: 'First name',
  userRegistrationLastName: 'Last name',
  userRegistrationPhone: 'Phone',
  userRegistrationTermsAndConditions: 'Accept terms and conditions',
  userRegistrationAborted: 'Aborted',
  userRegistrationTermsNedsAccepted:
    'The terms and conditions have to be accepted in order to activate your user.',
  userRegistrationForMoreQuestions: `If you have any questions to the terms and conditions or to ${brandConfig.appName} in generel, please don't hesitate to call us on ${brandConfig.contactPhoneHtmlFormated} or send an e-mail to `,
  userRegistrationBack: 'Back',
  userRegistrationThatsIt: "That's it!",
  userRegistrationReadyPaiments: `You are now ready to receive payments from patients with a ${brandConfig.appName} Credit.`,
  // userRegistrationSetpGuide: 'An invoice amount can be registered in our system with only a few clicks, and the patient accepts the payment directly on his mobile phone. Please also see our step-by-step guide.',
  userRegistrationSetpGuide:
    'An invoice amount can be registered in our system with only a few clicks, and the patient accepts the payment directly on his mobile phone.',
  userRegistrationSetpGuideLink: ' Please also see our step-by-step guide.',
  userRegistrationReadyToHelp:
    'If you need help, customer service is always ready to help you, both by phone and email.',
  userRegistrationLogIn: 'Log in',
  userRegistrationCreateUaser: 'Create User',
  userAlredyActivated1: 'Your user has already been activated',
  userAlredyActivated2:
    'It looks like your account is already activated. Please proceed by logging in to your account.',
  userAlredyActivatedButton: 'Proceed to login',
  userNotFound1: 'User not found',
  userNotFound2:
    'The user does not appear to exist. Please contact the person who created the user for you if you believe that an error has occurred.',
  linkExpiredButton: 'Send new link',
  userLinkExpired1: 'The link has expired',
  userLinkExpired2:
    'For security reasons, the activation link is only valid for 7 days.',
  userLinkExpired3: 'Please click the button below to receive a new link.',
  userLinkExpiredButton: 'Resend activation link',
  userRegistrationStep2Note: 'Add/edit your additional contact information',
  userregistrationMailError: 'A user with this e-mail already exists',
  userRegistrationExistingUser: 'Username already exists',
  userRegistrationPleaseEnterMail: 'Please enter a valid email address',
  userRegistrationPleaseEnterUserType: 'Please select a user type',
  userRegistrationPleaseEnterUserCompany: 'Please select a company',
  cvrLabel: 'CVR',
  locationFlow_step1_title: 'Add location',
  locationFlow_step1_name: 'Location name',
  locationFlow_step1_adress: 'Address',
  locationFlow_step1_zipCode: 'Zip code',
  locationFlow_step1_city: 'City',
  locationFlow_step1_cvr: 'CVR number',
  locationFlow_step1_cvrSwitch:
    'The location does not have its own CVR number.',
  locationFlow_step1_requerdField: 'Please fill out all required fields',
  locationFlow_step1_continue: 'Continue',
  locationFlow_step2_title: 'Contact information',
  locationFlow_step2_description: `Please enter the location's contact information and select one or more categories that match the treatments that is offered at the locations. The information will be displayed in ${brandConfig.appName}'s index of dental clinics.`,
  locationFlow_step2_phone: 'Phone',
  locationFlow_step2_email: 'Email',
  locationFlow_step2_website: 'Website',
  locationFlow_step2_categories: 'Categories',
  locationFlow_step2_placeholder_categories:
    'Please select one or more categories...',
  locationFlow_step2_hide:
    "Please hide the location from {0}'s index of dental clinics.",
  locationFlow_step2_back: 'Back',
  locationFlow_step2_continue: 'Continue',
  locationFlow_step2_error: 'Please select a category',
  locationFlow_step2_error2: 'Please fill out all required fields',
  locationFlow_step3_title: 'Bank account',
  locationFlow_step3_description1:
    'When a patient approves a payment with his {0} credit, the amount is paid out to the selected bank account within 24 hours.',
  locationFlow_step3_description2:
    'Please select to which bank account invoice amounts should be paid out:',
  locationFlow_step3_cvrTarget: 'for CVR no. {0}',
  locationFlow_step3_anotherBank: 'Other Danish bank account',
  locationFlow_step3_regno: 'Reg no.',
  locationFlow_step3_regno_error: 'Exactly 4 digits',
  locationFlow_step3_acountNo: 'Account no.',
  locationFlow_step3_acountNo_error: 'Exactly 10 digits',
  locationFlow_step3_selectionMeaning:
    'Your selection means that all approved invoice amounts registered at this location will be paid out to the NemKonto associated with CVR no. {0}.',
  locationFlow_step3_noResp:
    'For payouts to an account other than NemKonto, it is your responsibility to enter the correct account number. {0} ApS can not be held responsible for amounts paid out to an incorrect bank account due to incorrectly entered account number.',
  locationFlow_step3_selectOption: 'Please select an option',
  locationFlow_step3_enterNumber:
    'Please enter registration and account number. ',
  locationFlow_step4_title: 'Location added',
  locationFlow_step4_description:
    'The location “{0}” has been added successfully.',
  locationFlow_step4_adress: 'Address',
  locationFlow_step4_cvr: 'CVR',
  locationFlow_step4_accountNo: 'Reg. and account no.',
  locationFlow_step4_phone: 'Phone',
  locationFlow_step4_email: 'Email',
  locationFlow_step4_web: 'Website',
  locationFlow_step4_notSet: '- Not set -',
  locationFlow_step4_visible:
    'The location will be visible in the clinic index within 60 minutes.',
  locationFlow_step4_Manager:
    'All existing Manager users are now able to register invoices on the location.',
  locationFlow_step4_createUser: 'Create a new user for this location.',
  locationFlow_step4_close: 'Close',
  flial_tab_name_filialer: 'Locations',
  addLoaction: 'Add location',

  // Booking request
  labelTLF: 'tlf.',
  labelComment: 'comment:',
  time_ranges: 'Choose a time from the time range:',
  noTimeSlotHeadLine: 'Time desired:',
  noTimeSlot: 'As soon as possible',
  date_abbreviation: ' d. ',
  time_abbreviation: ' hr. ',
  timer_hour: 'Hour',
  timer_min: 'Min',
  timer_seconds: 'Sec',
  header_title: 'Booking Request',
  therapist_profile: 'You have received this booking request via your ',
  therapist_profile1: 'therapist profile ',
  therapist_profile2: 'in the therapist index on sundhedplus.dk. ',
  contact_patient_title: 'Contact the patient',
  time_intervals:
    'You are encouraged to contact the patient and either confirm a time in one of the time intervals that the patient has requested, or suggest the patient an alternative time.',
  resolved_query:
    'Once you have been in dialogue with the patient, you can advantageously mark the query as resolved.',
  btn_solved: 'Mark as solved',
  btn_markedSolved: 'Marked as solved',
  automatic_delete_msg:
    'For safety reasons and to ensure a quick response to the patient, this query is automatically deleted if:',
  errorRequestBookingHeader: 'No Booking Request found',
  errorRequestBookingP1:
    'We cannot find the requested booking request in our system.',
  errorRequestBookingP2:
    ' Please do not hesitate to contact us at {0} or phone {1} if the problem persists.',
  booking_help_msg: 'Do you have any questions?',
  errorLinkExpiredHeader: 'The booking request has expired',
  errorLinkExpiredP1:
    'For security reasons, this booking request is no longer available.',
  errorLinkExpiredP2:
    'Please contact our customer service by email {0} or call {1} if you have any questions.',

  // Migrate credit
  migrateCredit_title_step1: `Transfer your Denti Credit for ${brandConfig.appName}`,
  migrateCredit_first_paragraph_step1:
    'Create a free Sundhed+ membership, and at the same time have your Denti Credit of {0} converted to a Sundhed+ Credit of a corresponding amount.',
  migrateCredit_second_headline_step1:
    'Use the credit with over 10,000 therapists',
  migrateCredit_second_paragraph_step1:
    'Unlike your current Denti Credit, you can use your Sundhed+ Credit for all types of health treatments. And of course, you can also continue to use the credit for dental treatments.',
  migrateCredit_third_headline_step1: 'Same interest rate and terms',
  migrateCredit_third_paragraph_step1:
    'Both your credit and your existing payment agreements are transferred from Denti to Sundhed+. Interest rates and terms remain the same.',
  migrateCredit_button_step1: `Create ${brandConfig.appName} membership`,

  migrateCredit_title_step2: 'Membership conditions',
  migrateCredit_first_paragraph_step2:
    'As a member of Sundhed+, you get access to a number of services and benefits. Membership is free and can be terminated at any time.',
  migrateCredit_second_paragraph_step2:
    'As part of the membership, you will approx. twice a month receive our member newsletter, with inspiring articles on health, as well as exclusive offers on health services from health care professionals in your area.',
  migrateCredit_read_more_step2: 'Read the full membership terms',
  migrateCredit_checkbox_step2:
    'I declare that I have read and accept the membership terms',
  migrateCredit_button_next: 'Continue',
  migrateCredit_button_back: 'Back',

  migrateCredit_title_step3: 'Add your card information',
  migrateCredit_first_paragraph_step3:
    'For security reasons, we are unable to transfer your card information from Denti to Sundhed +.',
  migrateCredit_second_paragraph_step3: 'Please re-enter your card details.',

  migrateCredit_title_step4: 'Welcome!',
  migrateCredit_subtitle_step4: 'Your Sundhed+ membership has now been created',
  migrateCredit_first_paragraph_text1_step4:
    'We are pleased to welcome you as a new member of Sundhed+.{0} Your Denti Credit of {1} has now been converted to a Sundhed+ Credit with a corresponding credit line, and we have also transferred your existing payment agreement, so that your monthly benefit will in future be deducted by Sundhed+.',
  migrateCredit_first_paragraph_text2_step4:
    'We are pleased to welcome you as a new member of Sundhed+.{0} Your Denti Credit of {1} has now been converted to a Sundhed+ Credit with a corresponding credit line.',
  migrateCredit_first_paragraph_text_for_expired_step4:
    'We are happy to welcome you as a new member of Sundhed+.',
  migrateCredit_second_paragraph_text_for_expired_step4:
    'Your existing Denti payment agreement has now been migrated to Sundhed+, and in the future you will therefore experience that the monthly payment receipts will come from Sundhed+. {0} However, everything else remains unchanged, including your interest rate, the monthly payment and the general terms of the agreement.',

  migrateCredit_text3_step4_headline: 'You already have a Sundhed+ Credit',
  migrateCredit_first_paragraph_text3_step4:
    'Your Denti Credit is already migrated to a Sundhed+ Credit with a credit amount of {0} Your monthly payment is withdrawn by Sundhed+ according to the payment agreement.',
  migrateCredit_second_paragraph_step4:
    'You current available credit amount is {0}, which can be used to finance health treatments at more than 10.000 health clinics across the country.',
  migrateCredit_first_paragraph_text3_expired:
    'Your Denti Credit has already been converted to a Sundhed+ Credit. Your monthly benefit is also deducted by Sundhed+ in accordance with your payment agreement.',
  migrateCredit_third_paragraph_step4: 'First draw: {0}.',
  migrateCredit_subsection_title_step4: 'Get started ...',
  migrateCredit_subsection_paragraph_step4:
    'We have sent you an e-mail at {0}, with information about your membership and the benefits you can look forward to as a member of Sundhed +.',

  migrateCredit_SundhedCredit_DentiLoan_title:
    'Transfer payment agreements to Sundhed+',
  migrateCredit_SundhedCredit_DentiLoan_p1:
    'You pay today on one or more payment agreements through Denti. Since we have chosen to merge Denti with Sundhed+, it is therefore necessary to transfer your payment agreements to Sundhed+.',
  migrateCredit_SundhedCredit_DentiLoan_p2:
    'Since you are already a member of Sundhed+, it is quickly done. All we need is your acceptance that we transfer the payment agreements, and that you re-enter your card details, as for security reasons we cannot move them over.',
  migrateCredit_ExpiredDentiCredit_DentiLoan_title:
    'Transfer payment agreements to Sundhed+',
  migrateCredit_ExpiredDentiCredit_DentiLoan_p1:
    'You pay today on one or more payment agreements through Denti. As Denti is merged with Sundhed+, it is necessary to transfer your payment agreements to Sundhed+.',
  migrateCredit_ExpiredDentiCredit_DentiLoan_headline2:
    'Create a free membership',
  migrateCredit_ExpiredDentiCredit_DentiLoan_p2:
    'Unlike Denti, at Sundhed+ only members can have a credit.{0} In order to be able to transfer your payment agreements to Sundhed+, it is therefore necessary that you accept the Sundhed+ membership conditions.',
  migrateCredit_ExpiredDentiCredit_DentiLoan_p3:
    'Membership of Sundhed+ is free and non-binding, and gives you access to a wide range of health-related benefits.',
  migrateCredit_button_step4: 'Find clinic',
  migrateCredit_button_step4_expired: 'Read more about Sundhed+',
  migrateCredit_button_step4_migrate: 'Migrate credit',
  migrateCredit_error_headline:
    'We cannot provide you with a Sundhed+ Credit right now',
  migrateCredit_error_p1:
    'Unfortunately, we cannot create your Sundhed+ membership by migrating your Denti Kredit. This is due to one or more unpaid invoices at Denti.',
  migrateCredit_error_p2:
    'As soon as the unpaid payments are paid, you will be able to migrate your Denti Kredit to a Sundhed+ Credit, which can be used to finance health treatments at more than 10.000 health clinics across the country.',
  migrateCredit_error_btn: 'Update payment method',
  migrateCreditLoadingMsg: 'Migrating loans',
  migrateCreditIOSMessage:
    'We have become aware that iOS may have problems displaying the button at the bottom. If you experience the same problem, try change the text size, as these solutions have helped others before.',

  bookingModalSolved_tittle:
    'Has the booking request resulted in you booking an appointment for the patient?',
  bookingModalSolved_first_option:
    'Yes, an appointment has been booked for the patient',
  bookingModalSolved_second_option:
    'No, an appointment has not been booked for the patient',

  bookingSolvedNotification: 'The booking request has been marked as solved.',
  bookingModalDelete_tittle: 'Delete booking request.',
  bookingModalDelete_message:
    'Are you sure you want to delete this booking request?',

  // Unsubscribe Booking Request Page

  unsubscribeBookingRequest_title: 'Unsubscribe booking request',
  unsubscribeBookingRequest_text1:
    'Are you sure that you want to remove the possibility that users of the practitioner index of {0} can send booking inquiries to your clinic?',
  unsubscribeBookingRequest_text2:
    'If the reason why you do not want to receive booking request by email is because you already have an online booking function on your website, you can instead consider being set up as a Sundhedplus Partner. It is completely free and non-binding and gives you among another option to customize your {0} in the practitioner index - including letting the "Book time" button point directly to your online booking.',
  unsubscribeProfile: 'profile',
  unsubscribeBookingRequest_text3:
    'Contact customer service at {0} to be set up or read more about the benefits {1}',
  unsubscribeBookingRequest_here: 'here',
  unsubscribeBookingRequest_button: 'Unsubscribe',
  unsubscribeBookingRequest_error_title: 'Unsubscribe failed',
  unsubscribeBookingRequest_error_text:
    'For security reasons, this unsubscribe link is no longer available.',
  unsubscribeBookingRequest_success_title: 'Unsubscribed successfully',
  unsubscribeBookingRequest_success_text:
    'You have successfully unsubscribed from booking requests and won´t be bothered again.',

  // WEBSOCKET NOTIFICATIONS
  wsNotifPatientUpdateHeadline: 'Change registered',
  wsNotifPatientUpdateText:
    '{0} profile has been updated. Reload the profile of the patient to watch the changes.',
  wsNotifPatientUpdateButton: 'Reload',
  wsNotifInvoiceAcceptedHeadline: 'Invoice accepted',
  wsNotifInvoiceAcceptedText: '{0} has just accepted the invoice of {1}',
  wsNotifInvoiceDeclinedHeadline: 'Invoice declined',
  wsNotifInvoiceDeclinedText:
    'Sadly, an invoice of {0} was declined by {1}. If this is unexpected, please take contact to the patient.',
  wsNotifInvoiceAcceptedButton: 'OK',
  wsNotifNewInvoiceHeadline: 'New invoice has been created',
  wsNotifNewInvoiceText: 'An invoice of {0} has just been created for {1}.',
  wsNotifNewInvoiceButton: 'OK',
  wsNotifDunningInHeadline: 'The credit is temporarily closed',
  wsNotifDunningInText:
    "{0} credit is temporarily closed, which means, that it isn't possible to create a new invoice for {0} at the moment.{1} For more information, feel free to contact customer support, at {2} or {3}.",
  wsNotifDunningOutHeadline: 'The credit is active again',
  wsNotifDunningOutText:
    '{0} credit is now active again, which means, that it is again possible to create a new invoice for {0}.',
  wsNotifDunningButton: 'OK',
  wsNotifRefundHeadline: 'Payment refunded',
  wsNotifRefundText: 'The invoice of {0} is refunded to the patient.',
  wsNotifRefundLink: 'See loan details',
  wsNotifRefundButton: 'OK',
  wsNotifLoanDeletedHeadline: 'Loan has been deleted for the patient',
  wsNotifLoanDeletedText:
    'A loan has been deleted for this patient. {0} Find more about this in the patient log.',
  wsNotifLoanDeletedBtn: 'Refresh',
  wsNotifLoanDeletedWithdrawalFailedHeadline:
    'Withdrawal from credit card failed',
  wsNotifLoanDeletedWithdrawalFailedText:
    'Sadly, the withdrawal from the credit card failed. Therefore, the restructuring loan has been deleted.{0} Please inform the client about this, and hopefully you can find another solution.{1} The failing reason can be found in Reepay.',
  wsNotifLoanDeletedWithdrawalFailedBtn: 'Refresh',
  referralTrue: 'Referred by own doctor',
  referralFalse: 'Not referred by own doctor',

  // Error Boundary
  errorBoundaryTitle: 'Sorry, there seems to be an issue',
  errorBoundaryText: 'Clear and try again',
  cookiesClearLoading: 'Clearing cookies...',
  cookiesClearDone: 'Cookies are cleared!',

  // Onboarding Flow
  onboardingGoBack: 'Back',
  onboardingContinue: 'Continue',
  onboardingFlowTitle: 'Join',
  onboardingFlowSubtitle: 'Create a free membership at Sundhed+',
  onboardingJoinBtn: 'Sign up',

  onboardingFlowFirstInformation:
    'You will immediately then have the opportunity to apply for a Sundhed+ Credit. It only takes a few minutes and all you need is your MitID.',
  onboardingCPRNumber: 'CPR-number',
  onboardingFirstNameTooShort: 'The first name is too short',
  onboardingLastNameTooShort: 'The last name is too short',
  onboardingAcceptMembership: 'Accept membership terms',

  // smartlinks
  onboardingSmartlinkHeadline: 'Select treatment location',
  onboardingSmartlinkSubtitle: 'The therapist has several treatment locations.',
  onboardingSmartlinkSelect:
    'Please select the treatment center where you expect to use the credit.',
  onboardingSmartlinkNext: 'Continue',
  onboardingSmartlinkNextError: 'You must select a branch to continue.',
  onboardingSmartlinkWrongCompanyChain:
    'There are no treatment locations for this company chain',

  // CPR number page

  onboardingStepTwoSubtitle:
    'We need your CPR-number to retrieve your name and address from the CPR registry.',
  onboardingStepTwoOBS:
    'NOTE: Your contact details and your social security number will be shared with the health practitioners where you use the credit.',
  onboardingStepTwoSeeMore: 'Secret address? {0}',
  onboardingStepTwoSeeMoreLink: 'See here',

  onboardingStepThreeTitle: 'Please wait...',
  onboardingStepThreeSubtitle:
    'Please wait a moment while we retrieve your data from the CPR registry.',
  onboardingStepThree: 'Checking for data in the Land Registry',

  // have tou take out new loans

  onboardingHaveAlreadyTakeLoansTitle: 'Have you taken out new loans?',
  onboardingHaveAlreadyTakeLoansSubTitle1:
    'Did you take loans in banks or consumer loan companies after December 31st, {0} ?',
  onboardingHaveAlreadyTakeLoansSubTitle2:
    'Please enter the remaining debt and the total monthly payment of the loans.',
  onboardingHaveAlreadyTakeLoansInputDebt: 'Remaining debt',
  onboardingHaveAlreadgTakeLoanRmainigDebt: 'Remaining debt: {0}',
  onboardingHaveAlreadyTakeLoansMontlytPayment: 'Monthly payment: {0}',
  onboardingHaveAlreadyTakeLoansDebtLessThanPayment:
    'Monthly payment must not exceed remaining debt.',
  onboardingHaveAlreadyTakeLoansInputPayment: 'Monthly payment',
  onboardingWhatTypeOfLoan: 'What type of loan do you currently have?',
  onboardingAddNewLoan: 'Add loan',
  onboardingTypeLoanCar: 'Car loan',
  onboardingTypeLoanConsumer: 'Consumer loan',
  onboardingTypeLoanHouse: 'House loan',
  onboardingTypeLoanOther: 'Other',
  onboardingHaveAlreadyTakeLoansYes: 'Yes',
  onboardingHaveAlreadyTakeLoansNo: 'No',

  // existing member
  onboardingExistingMemberTitle: 'Existing member',
  onboardingExistingMemberSubtitle:
    'You have started creating a membership of Sundhed+, but based on the information entered, it appears that you are already a member.',
  onboardingExistingMemberQuestionsTitle: 'Questions about your membership?',
  onboardingExistingMemberQuestions:
    'If you have any questions about your membership, you are always welcome to contact customer service at tel. {0} or at {1}.',
  onboardingExistingMemberApplyForCreditTitle: 'Apply for a Sundhed+ Credit',
  onboardingExistingMemberApplyForCredit:
    'If you wish to apply for a Sundhed+ Credit as part of your membership or already have a credit and wish to renew it, you can do so here.',
  onboardingCreateNewCredit: 'Create credit',

  // existing client
  onboardingExistingClientTitle: 'Renew your credit',
  onboardingExistingClientP1:
    'To ensure responsible lending, all members of Sundhed+ must complete an annual credit check if they wish to continue to be able to draw on their credit.',
  onboardingExistingClientP2:
    'The purpose of the credit check is to ensure that there is still enough room in your finances for you to be able to pay the maximum monthly expenses that a full use of your Sundhed+ Kredit can entail.',
  onboardingExistingClientP3:
    'In the credit check, we will therefore ask you a few questions and obtain data from your bank and SKAT as well as various public registers. Our system then sets up a budget on your behalf, based on the collected data.',

  onboardingExistingClientExpiredCreditTitle: 'Note',
  onboardingExistingClientExpiredP1:
    'The credit check may cause your current credit limit and/or interest rate to be raised or lowered.',
  onboardingExistingClientExpiredCreditP2:
    'If the credit check leads to a change in your interest rate, this change will only take effect if you choose to draw further on the credit.',
  onboardingExistingClientExpiredCreditP3:
    'If the credit check shows that you are no longer creditworthy, your credit will be closed.',

  // Existing Member - start onboarding
  onboardingExistingMemberStartHeadline: 'Apply for a Sundhed+ Credit',
  onboardingExistingMemberStartText1:
    'Here you can start your application for a Sundhed+ Credit. It costs you nothing to apply, and it also costs nothing to have a Sundhed+ Credit, only when you make use of the credit by approving a bill from one of our many therapists will costs occur.',
  onboardingExistingMemberStartSubheadline: 'Questions about your membership?',
  onboardingExistingMemberStartText2:
    'If you have questions about your membership, you are always welcome to contact customer service at tel. {0} or at {1}.',
  onboardingExistingMemberButtonText: 'Create Credit',

  onboardingRenewCredit: 'Renew credit',
  onboardingRenewCreditCheck: 'Check credit',

  // consent page
  onboardingConsentTitle: 'Here is what will happen...',
  onboardingConsentDataTitle: 'Gathering of data',
  onboardingConsentDataText:
    'In order to offer you a Sundhed+ Credit, we need to make sure that you do not take on debt that you cannot repay.',
  onboardingConsentDataText2:
    'We will therefore ask you a few questions and collect data from your bank and the Danish tax authority (SKAT), as well as various public registers. Our system will then create a budget on your behalf based on the collected data.',
  onboardingConsentHandlingDataTitle: 'Handling of your data',
  onboardingConsentHandlingDataText:
    'The data we collect is solely used to conduct a credit assessment of you. We do not share your data with any healthcare providers or other partners. Please also see our {0}.',
  onboardingConsentHandlingDataLink: 'privacy policy',
  onboardingJoinSundhedPlus:
    'We care about your data security, and you should therefore know that we collect and process your personal data in accordance with our {0}.',

  // how do you live

  onboardingStepFiveTitle: 'Where do you live?',
  // v1
  onboardingStepFiveOption1: 'Rental',
  onboardingStepFiveOption2: 'Cooperative housing',
  onboardingStepFiveOption3: 'Homeowner',
  onboardingStepFiveOption4: 'Condominium owner',
  onboardingStepFiveOption5: 'Living at home',
  // v2
  onboardingAcommodationOption1: 'Owner house',
  onboardingAcommodationOption2: 'Owner appartment',
  onboardingAcommodationOption3: 'Rent',
  onboardingAcommodationOption4: 'Living at home',
  onboardingAcommodationOption5: 'Cooperative living',

  // civil status, v2
  onboardingCivilStatusTitle: 'Cohabiting',
  onboardingCivilStatusSubtitle:
    'Are you married, or do you have a cohabitant?',
  onboardingCivilStatusOptionYes: 'Yes',
  onboardingCivilStatusOptionNo: 'No',

  // redirect to Monthio
  onboardingRedirectToMonthioTitle: 'Acquisition of budget items',
  onboardingRedirectToMonthioP1:
    'We collaborate with the Danish company Monthio, which is approved by the Danish Financial Supervisory Authority to collect and analyze bank data.',
  onboardingRedirectToMonthioP2:
    "When you click on the button below, you will therefore be forwarded to Monthio's platform, where you must authorize Monthio with your MitID to withdraw your transactions from your bank accounts.",
  onboardingRedirectToMonthioP3:
    "After this, Monthio's system will automatically generate a budget draft, which you will be presented with and have the opportunity to adjust if necessary.",
  onboardingRedirectToMonthioP4:
    'If you would like to wait with this part of the application, we have also sent you an email with a link so that you can continue the application at a later time.',
  onboardingRedirectToMonthioButton: 'Continue',

  // evaluating budget, v2
  onboardingEvaluatingBudgetMessage: 'Loading budgets.',
  onboardingAnomalousWaitingTimeMessage: 'This is taking longer than expected',
  // v2 DAM applications
  onboardingAcommodationRent: 'Renting',
  onboardingAcommodationLivingAtHome: 'Living at home',
  onboardingAcommodationOwnerOccupied: 'Owner of house/appartment',
  onboardingAcommodationHousingCooperative: 'Housing Cooperative',

  // Monthio redirect
  onboardingRedirectToMonthio: 'Continue to Monthio',

  // check debtor register, v2
  onboardingCheckDebtorRegisterTitle: 'Check the Debtor register',

  // how many kids
  onboardingStepSixTitle:
    'Do you have children under the age of 18 living at home?',
  onboardingStepSixSubtitle:
    'Add all your children under the age of 18 who live at home to the list below.',
  onboardingStepSixChooseAgeTitle: 'How old is your child today?',
  onboardingStepSixButton: 'Add child',
  onboardingStepSixBarn: 'Child',
  onboardingStepSixBarnAge: '{0} years old',
  onboardingStepSixFjern: 'Remove',

  // do you share your expenses
  onboardingStepSevenTitle: 'Do you share your expenses?',
  onboardingStepSevenSubtitle:
    'Do you share your fixed expenses with a partner or a cohabitant?',
  onboardingStepSevenOptionYes: 'Yes',
  onboardingStepSevenOptionNo: 'No',

  // how do you share your expenses
  onboardingStepEightTitle: 'Distribution of expenses',
  onboardingStepEightSubtitle:
    'Indicate with the slider what percentage of the shared expenses you pay',
  onboardingStepEightSlider: 'Approximately {0}%',

  // do you have a car
  onboardingStepNineTitle1: 'Do you have one or more cars?',
  onboardingStepNineTitle2: 'What is your ownership status for the car?',
  onboardingStepNineTitle3: 'What is your monthly payment for the car?',
  onboardingStepNineSubtitle:
    'Add cars that you have expenses for to the list. It can be cars you own, lease or rent.',
  onboardingStepNineButton: 'Add car',
  onboardingStepNineCarLease: 'I lease it',
  onboardingStepNineCarRent: 'I rent it',
  onboardingStepNineCarOwn: 'I own it',
  onboardingStepNineCarCompany: 'It is a company car',
  onboardingStepNineCar: 'Car ({0})',
  onboardingStepNineMontlyPayment: 'Monthly payment: {0}',
  onboardingStepNineEmptyAmoutField:
    'Please state your monthly benefit that you pay to the landlord/leasing company',

  // cpr failed

  onboardingCprFailedTitle: 'Failed to retrieve your name and address',
  onboardingCprFailedSubtitle:
    'In order to create your membership, we need to be able to retrieve both your name and address from the CPR registry.',
  onboardingCprFailedSecondTitle: 'Secret address?',
  onboardingCprFailedSecondSubtitle:
    'If you have a secret address, you can easily deactivate your secret address temporarily on {0} while making the application, and then reactivate it afterwards. {1}',
  onboardingCprFailedBorgerDkLink: 'borger.dk',
  onboardingCprFailedSecondLink: 'See our guide on how to do it here',
  onboardingCprFailedParagraph:
    'If you are currently living abroad, or for other reasons are not registered with an address in the CPR registry, we unfortunately cannot offer you a membership of Sundhed+ at this time.',

  // onboarding spinner page
  onboardingSpinnerTitle: 'Please wait...',
  onboardingSpinnerSubtitleRedirectingMitId:
    'You are now being redirected to Skattestyrelsen, where you can use MitID to authorize access to your tax information.',

  // spinner before approved credit
  onboardingSpinnerBeforeApproveTitle: 'Please wait...',
  onboardingSpinnerBeforeApproveSubtitle:
    'So far, everything looks good, we will just do the very final validation, which should be very quick.',

  // Monthly household expenses
  onboardingMonthlyHouseholdExpensesTitle: 'Monthly household expenses',
  onboardingMonthlyHouseholdExpensesHeadline:
    'Please state how much money you spend on average on household etc. per month.',
  onboardingMonthlyHouseholdExpensesFooter:
    'The amount covers food, drink, groceries, shoes, clothes, household items, leisure interests, pleasures, gifts, holidays, other personal expenses such as a hairdresser and medicine, as well as the maintenance of durable consumer goods (e.g. when you replace your telephone, computer or bicycle) and anticipated expenses.',
  onboardingSliderAmount: 'ca. {0}',

  // add missing budget items
  onboardingAddMissingBudgetItemsTitle: 'Missing budget items',
  onboardingAddMissingBudgetItemsP1: `Sadly, it wasn't possible to estimate all budget items from your bank data. Therefore we ask you to kindly fill in the blanks of the budget items shown below. You can enter 0, if the budget item is irrelevant in your situation.`,
  onboardingAddMissingBudgetItemsP2:
    'Please note, that your inputs can entail, that additional documentation is needed.',
  onboardingAddMissingBudgetItemsHeadline: 'Enter the following budget items',
  onboardingAddMissingBudgetCheckbox: 'No cost',

  // edit estimated budget
  onboardingEditEstimatedBudgetTitle: 'Your spending budget',
  onboardingEditEstimatedBudgetP1:
    'Based on the information obtained, we have drawn up the consumption budget below of your monthly income and expenses. Please review the budget and make any corrections before confirming that the budget is accurate for your personal finances.',
  onboardingEditEstimatedBudgetP2:
    'Be aware that your corrections may lead to requirements for additional documentation.',

  onboardingEditEstimatedBudgetBtn: 'I confirm that I meet the requirements',
  onboardingEditEstimatedBudgetDisclaimer:
    'When you confirm the budget, you also declare that any corrections you have made are correct and fair for your personal finances. You may be liable if you provide incorrect information in your credit application.',
  onboardingConditionsCreditTitle: 'Conditions for Credit',
  onboardingConditionsCreditText:
    'In order to be approved for a Sundhedplus+ Credit, you must meet the following minimum requirements:',
  onboardingConditionsCreditItem1: 'Minimum 18 years old',
  onboardingConditionsCreditItem2: 'Danish population register address',
  onboardingConditionsCreditItem3: 'Danish telephone number',
  onboardingConditionsCreditItem4:
    'Valid Dankort, Visa/Visa Electron, Maestro or MasterCard',
  onboardingConditionsCreditItem5:
    'Not registered in debtor registers, e.g. RKI, Debtor Register and Credit Register',
  onboardingConditionsCreditItem6: 'Not cash benefit recipient',
  onboardingConditionsCreditNote:
    'Please confirm that you meet the conditions.',
  // onboarding - Membership conditions
  onboardingMembershipConditionsTitle: 'Membership conditions',
  onboardingMembershipConditionsP1:
    'As a member of Sundhed+, you get access to a number of services and benefits. Membership is free and can be terminated at any time.',
  onboardingMembershipConditionsP2:
    'As part of the membership, you will approx. twice a month receive our member newsletter, with inspiring articles on health, as well as exclusive offers on health services from health care professionals in your area.',
  onboardingMembershipConditionsReadMore: 'Read the full membership terms',
  onboardingMembershipConditionsCheckbox:
    'I declare that I have read and accept the membership terms',

  // onboarding - Requested amount
  onboardingRequestedAmountTitle: 'Your requested credit amount',
  onboardingRequestedAmountText: 'How much do you need for your treatment?',
  onboardingRequestedAmountGuide:
    "You need to select the amount needed for your treatment. If you don't have an upcoming treatment, please select 50.000 kr, then we will try to provide you the largest possible amount decided by your personal economy.",

  // onboarding - Power of attorney
  onboardingPowerOfAttorneyTitle:
    'Power of attorney for obtaining income and tax information',
  onboardingPowerOfAttorneytext1:
    "When you click 'Continue', you will be forwarded to the Danish Tax Agency's website, where you must authorize us with your MitID to obtain your income and tax information",

  onboardingPowerOfAttorneytext2:
    'The information is necessary to carry out the statutory creditworthiness assessment of you.',
  onboardingPowerOfAttorneytext3:
    'After you have given power of attorney at the Tax Agency, you will automatically be sent back to your application.',
  onboardingPowerOfAttorneytext4:
    'If you do not wish to provide power of attorney for your income and tax information, we unfortunately cannot offer you a Sundhed+ Kredit.',

  onboardingPowerOfAttorneySubtitleHasReturned:
    "It appears that you have returned from the Tax Agency's website without having authorized your income and tax information.",
  onboardingPowerOfAttorneyContainerHasReturnedTitle:
    'Are you experiencing technical problems?',
  onboardingPowerOfAttorneyContainerHasReturnedText1:
    'If you experience technical challenges signing the power of attorney with MitID, the problem may be due to an old MitID cookie on your device. In that case, the problem can be solved by visiting this {0} and clicking on the "Delete cookies" button.',
  onboardingPowerOfAttorneyContainerHasReturnedTextPage: 'page',
  onboardingPowerOfAttorneyContainerHasReturnedText2:
    'Once you have deleted the old MitID cookies, you can try to sign the authorization again by clicking "Continue".',

  // onboarding - How much for
  onboardingHowMuchForRentTitle: 'Rent',
  onboardingHowMuchForRentText:
    'Unfortunately, we are unable to identify your rent from your bank statements. Please state your monthly rent and whether the amount includes electricity, heating and/or water.',
  onboardingHowMuchForRentLabel: 'Please enter your monthly rent',
  onboardingHowMuchForRentIncludesElectricity: 'Rent includes electricity',
  onboardingHowMuchForRentIncludesHeating: 'Rent includes heating',
  onboardingHowMuchForRentIncludesWater: 'Rent includes water',
  onboardingHowMuchForElectricityTitle: 'Electricity consumption',
  onboardingHowMuchForElectricityText:
    'Unfortunately, we are unable to identify your electricity consumption from your bank statements. Please enter your monthly electricity bill below.',
  onboardingHowMuchForElectricityLabel:
    'Please enter your monthly electricity consumption',
  onboardingHowMuchForHeatTitle: 'Heat Consumption',
  onboardingHowMuchForHeatText:
    'Unfortunately, we are unable to identify your heat consumption from your bank statements. Please enter your monthly cost for heating below.',
  onboardingHowMuchForHeatLabel: 'Please state your monthly heating cost',
  onboardingHowMuchForWaterTitle: 'Costs for water',
  onboardingHowMuchForWaterText:
    'Unfortunately, we are unable to identify your water expenditure from your bank statements. Please enter your monthly water expenditure below.',
  onboardingHowMuchForWaterLabel:
    'Please state your monthly expenses for water',
  onboardingHowMuchForPhoneTitle: 'Telephone',
  onboardingHowMuchForPhoneText:
    'Unfortunately, we are unable to identify your telephone expenses from your bank statements. Please enter your monthly expenses for telephony below.',
  onboardingHowMuchForPhoneLabel:
    'Please state your monthly expenditure for telephone',
  onboardingHowMuchForPhoneCheckbox:
    'My employer pays my telephone subscription',

  // onboarding - membership created
  onboardingMembershipCreatedTitle: 'Welcome to the club!',
  onboardingMembershipCreatedP1:
    'We are pleased to welcome you as a new member of Sundhed+, and hope that you will enjoy the benefits of our free membership.',
  onboardingMembershipCreatedP2:
    'As a member, you now have the option to apply for our popular Sundhed+ Kredit, which gives you the opportunity to split the payment of your health treatments into smaller, manageable chunks.',
  onboardingMembershipCreatedBtn: 'Apply for credit',
  onboardingMembershipCreatedLink: 'I do not want a credit at this time',

  // onboarding - Prior approval
  onboardingPriorApprovalTitle: 'Prior Approval',
  onboardingPriorApprovalSubtitle:
    'You meet the requirements to get a Sundhed+ Credit',
  onboardingPriorApprovalP1:
    'Based on your disposable amount and your financial key figures, you will be able to be approved for a Sundhed+ Credit of {0}',
  onboardingPriorApprovalP2:
    'Before we can issue the credit, however, it is a legal requirement that we prepare a documented budget, thereby ensuring that the available amount you have stated is correct.',
  onboardingPriorApprovalP3:
    'In the following steps, you will therefore be asked to answer further questions about your personal finances and give authorization for us to retrieve your bank statements so that our system can prepare a detailed consumption budget for you.',
  onboardingPriorApprovalSectionTitle: 'Sundhed+ Credit',
  onboardingPriorApprovalSectionL1: 'Credit amount:',
  onboardingPriorApprovalSectionL2: 'Interest rate:',
  onboardingPriorApprovalSectionL3: 'Duration:',

  // onboarding - Prior approval v2
  onboardingPriorApprovalTitleV2: 'Advance assessment',
  onboardingPriorApprovalSubtitleV2:
    'Based on your disposable amount and your financial key figures, you will be able to be approved for a Sundhed+ Credit',
  onboardingPriorApprovalP1V2:
    'Before we can determine the exact credit amount and issue the credit, however, it is a legal requirement that we prepare a documented budget of your income and expenses, thereby ensuring that the available amount you have stated is correct.',
  onboardingPriorApprovalP2V2:
    'In the following steps, you will therefore be asked to answer further questions about your personal finances.',

  // onboarding - credit offer conclusion
  onboardingCreditOfferConclusionTitle: 'Credit offer conclusions',
  onboardingCreditOfferConclusionSubtitle:
    'Your budget shows that you have a monthly available of {0} ',

  onboardingCreditOfferConclusionP1:
    'As the amount is significantly lower than the {0} that you previously stated, we can unfortunately only offer you a credit of {1}',
  onboardingCreditOfferConclusionP2:
    'To complete the activation, please upload the follow documentation!',
  onboardingCreditOfferConclusionAlternativeP1:
    'The amount is sufficient for us to offer you a credit of {0}',
  onboardingCreditOfferConclusionAlternativeP2:
    'In case the documentation does not match the entered amount for the budget item, the credit amount will be adjusted.',

  // onboarding - Approved credit
  onboardingApprovedCreditTitle: 'Congratulations',
  onboardingApprovedCreditSubtitle:
    'You have been approved for a Sundhed+ Credit',
  onboardingApprovedCreditP1:
    'Based on our credit assessment of you, we are pleased to offer you a Sundhed+ Credit of {0}',
  onboardingApprovedCreditSmartLink:
    'With the credit, you have the option of splitting the payment into smaller chunks at {0} and more than 10,000 other healthcare providers across the country',
  onboardingApprovedCreditP2:
    'With the credit, you have the option of splitting the payment into smaller chunks at more than 10,000 other healthcare providers across the country',
  onboardingApprovedCreditConditionP1:
    'However, as the credit assessment is partly based on your own information, we need documentation of this information before the credit can be activated',
  onboardingApprovedCreditConditionP2: 'Documentation we need: {0}',
  onboardingApprovedCreditSectionHeadline: 'Sundhed+ Credit',
  onboardingApprovedCreditSectionL1: 'Credit amount:',
  onboardingApprovedCreditSectionL2: 'Debitorrente:',
  onboardingApprovedCreditP3:
    'We have sent you an email at {0}, with further information on how to get started using your credit.',

  // onboarding - upload documentation
  onboardingUploadDocTitle: 'Upload documentation',
  onboardingUploadDocP1:
    'In order to meet the requirements of the Danish Financial Supervisory Authority, it is necessary that you submit documentation for the budget items below before we can activate your credit.',
  onboardingUploadDocLearnMore:
    'Read about what you can upload as documentation',
  onboardingUploadDocSectionRENT: 'Rent',
  onboardingUploadDocSectionHEAT: 'Heat',
  onboardingUploadDocSectionELECTRICITY: 'Electricity',
  onboardingUploadDocSectionWATER: 'Water',
  onboardingUploadDocSectionPHONE: 'Phone',
  onboardingUploadDocBtnSection: 'Add documentation',
  onboardingUploadMoreDocBtnSection: 'Add files',
  onboardingUploadDocBtnMoreSection: 'Add additional files',
  onboardingUploadNoteBtnSection: 'Add note',
  onboardingUploadNoteHeadline: 'Your note',
  onboardingUploadSaveNoteBtn: 'Save note',
  onboardingUploadTip:
    'Allowed file types: pdf, jpeg or gif. Max file size: 10 MB.',
  onboardingUploadDocNoteHeading:
    'Do you want to add a note to this documentation file?',
  onboardingUploadDocYesBtn: 'Submit',
  onboardingUploadDocNoBtn: 'Cancel',
  onboardingUploadDocModalSectionText: 'Amount to be documented',
  onboardingUploadDocBtn: 'Activate',
  onboardingUploadDocBottomText:
    'Do you need help? {0} Call customer service on tel. {1} Monday to Friday between 9 - 16',
  onboardingUploadDeleteHeadline: 'Do you want to delete the document?',
  onboardingUploadDeleteYes: 'Yes',
  onboardingUploadDeleteNo: 'No',
  onboardingUploadDocStatusPending: 'Documentation is awaiting approval',
  onboardingUploadDocStatusRejected: 'Action required',
  onboardingUploadDocStatusApproved: 'Documentation approved',
  onboardingUploadDocRejectedNoteHeadline: 'Comment from customer service',

  // upload statuses
  onboardingUploadLoadingHeadline: 'Uploader dokumentation',
  onboardingUploadLoadingSubtitle: 'Vent venligst...',
  onboardingUploadSuccessHeadline: 'Documentation submitted',
  onboardingUploadSuccessSubtitle:
    'You will be contacted as soon as we have reviewed the submitted documentation.',
  onboardingUploadErrorHeadline: 'Upload failed',
  onboardingUploadErrorP:
    'Please go back and try again or contact customer service for assistance.',
  onboardingUploadErrorBtn: 'Back',
  onboardingUploadValidationLoadingHeadline: 'Validating file',
  onboardingUploadValidationLoadingSubtitle: 'Please wait...',
  onboardingUploadValidationErrorHeadline: 'The selected file is invalid',
  onboardingUploadValidationErrorP1: 'The file has not been added',
  onboardingUploadValidationErrorP2:
    'The file type must be either pdf, jpg or png and the file size must not exceed 10 MB.',
  onboardingUploadValidationErrorOk: 'OK',

  onboardingUploadDuplicateErrorHeadline:
    'Not possible to upload multiple files with same file name',
  onboardingUploadDuplicateErrorSubtitle: 'The file has not been uploaded',
  onboardingUploadDuplicateErrorP1:
    'Click "Skip", if the file has already been uploaded, otherwise choose "Rename" to let us rename and make it possible for you to upload the file.',
  onboardingUploadDuplicateErrorSkip: 'Skip',
  onboardingUploadDuplicateErrorRename: 'Rename',

  // upload step, toast notifications
  uploadStepToastSuccess: 'The documentation for {0} is approved',
  uploadStepToastError:
    'The documentation for {0} is deficient and requires yet another action',

  // upload guide
  uploadGuideStep1Title: 'We need your help',
  uploadGuideStep1P1:
    'The law requires us to obtain "adequate documentation" for both income and expenditure items in your budget.',
  uploadGuideStep1P2:
    'We therefore need you to submit documentation for one or more amounts in your budget.',
  uploadGuideStep2Title: 'What to send?',
  uploadGuideStep2P1:
    'You will typically find the documentation in your digital mailbox or online bank, and may for example consist of:',
  uploadGuideStep2L1: 'Bank statement',
  uploadGuideStep2L2: 'Invoices/Receipts',
  uploadGuideStep2L3: 'Payment Service Statements',
  uploadGuideStep3Title: 'Please note that...',
  uploadGuideStep3P1:
    'It is important that the documentation you submit contains both the amount to be documented, your name or address and the income/expense to which the amount relates.',
  uploadGuideStep4Title: 'A screenshot is perfectly fine',
  uploadGuideStep4P1:
    'When you add documentation to a budget item, it can be in the form of images, screenshots or pdf files. It is also possible to add more than one file if you need it.',
  uploadGuideStep5Title: 'Elaborate with a note',
  uploadGuideStep5P1:
    'If you do not have the opportunity to submit adequate documentation for a budget item, or want to attach a comment to the files you submit, you can add a note.',
  uploadGuideOk: 'OK',
  uploadGuideGetStarted: 'Get started',

  // upload approved documentation
  onboadringUploadApprovedHedline: 'Congratulations',
  onboadringUploadApprovedSubtitle: 'Your documentation is now approved',
  onboadringUploadApprovedP1:
    'You will now receive an email with information about your credit',
  onboadringUploadApprovedOk: 'OK',

  onboardingUploadCancelHeadline: 'Are you sure you want to cancel?',
  onboardingUploadCancelText:
    'In that case, the added documentation will not be saved.',
  onboardingUploadCancelYes: 'Yes',
  onboardingUploadCancelNo: 'No',

  onboardingDeleteFileHeadline:
    'Are you sure, you want to remove the documentation completely?',
  onboardingDeleteFileText:
    'The uploaded documentation will be removed completely.',
  onboardingDeleteFileYes: 'Yes',
  onboardingDeleteFileNo: 'No',
  onboardingWarningDeleteDocFailed: 'The document was not deleted. Try again.',
  onboardingWarningUpdateNoteFailed: 'The note was not saved. Try again.',
  onboardingEditNoteCancel: 'Cancel',
  onboardingEditNoteSave: 'Save',
  onboardingEditNoteCancelHeadline: 'Do you want to cancel your edit?',
  onboardingEditNoteCancelText:
    'Select yes to cancel your edit and go back to the original text.',

  // onboarding - denied credit offer
  onboardingDeniedCreditTitle: 'Application Denied',
  onboardingDeniedCreditSubtitle:
    'Unfortunately, you cannot be approved for a credit at this time',
  onboardingDeniedCreditWarningTitle: 'Reason',
  onboardingDeniedCreditP1:
    'We are very sorry, but unfortunately an error occurred in connection with the credit assessment, and we therefore cannot offer you a Sundhed+ Credit at this time.',
  onboardingDeniedCreditP2:
    'Please contact customer service on tel. {0} or {1} for assistance.',
  onboardingDeniedCreditP3:
    'Of course, you still have access to all our other member benefits, and will in future receive our newsletter with inspiring articles and exclusive member offers approx. 2 times monthly.',
  onboardingDeniedCreditSubsectionHeadline: 'Get started...',
  onboardingDeniedCreditSubsectionP1:
    'We have sent you an e-mail at {0}, with information about your membership and the benefits you can look forward to as a member of Sundhed+.',
  onboardingDeniedCreditBtn: 'Read more about the benefits',

  // onboarding - denied credit offer text mapping

  //The client has payment remarks
  onboardingDeniedForPaymentRemarks:
    'Unfortunately we cannot offer you a credit at this point, due to a payment remark in Debitorregistret.',
  onboardingDeniedForPaymentRemarksP2:
    'More details about your payment remark can be found {0}',
  onboardingDeniedForPaymentRemarksP3: 'here',
  // Estimated disposable amount less than 0
  onboardingDeniedForDisposableAmountBelow0:
    "The submitted budget does not meet the Danish Financial Supervisory Authority's minimum requirements for creditworthiness.",
  onboardingDeniedForDisposableAmountBelow0P2:
    'You are always welcome to apply again, if your financial situation should improve in the future.',
  // Cpr missmatch
  onboardingDeniedForCprMismatch:
    'The provided CPR number does not match the CPR number associated with the MitID you have used. Your application has therefore been interrupted.',
  onboardingDeniedForCprMismatchP2:
    'If you believe this was done by mistake, please apply again.',
  // Client is suspended
  onboardingDeniedForSuspendedP1:
    'You have previously been approved for a credit with us (Sundhed+/Denti), but are currently blocked from taking out further credit.',
  onboardingDeniedForSuspendedP2:
    "This can happen if you have recently been registered in e.g. RKI or Debitorregistret, or if you haven't paid previous loan installements on time.",
  onboardingDeniedForSuspendedP3:
    'Contact customer service if you have any questions about this.',
  //Client is in debt_collection
  onboardingDeniedForClientIndebCollection:
    'As you have previously defaulted on your payment obligations on a credit that you have had through us (Sundhed+/Denti), we unfortunately have to reject your application.',
  //Client has name and address protection
  onboardingDeniedForClientHasNameAndAddressProtection:
    'It looks like you have name and address protection enabled, which means, that we cannot offer you a credit.',
  //next allowed application date is in the future
  onboardingDeniedForNextAllowedApplicationDate:
    'Unfortunately, it looks like you have already applied recently and received a response, and therefore it is not possible to apply again yet. You can try again after {0}.',
  // no salary
  onboardingDeniedForMissingSalary:
    'Sadly, we could not find any payslips in your tax report. This is required to be provided a credit. Please contact us, if you have any unusual form of income instead.',
  // no tax report
  onboardingDeniedForMissingTaxReport:
    'It seems like we could not find any yearly tax report from last year in your tax data. This is required to proceed, since we use it to estimate your debt and other metrics. Please contact us, if this is incorrect.',

  // Generic denied message
  onboardingDeniedGenericMessage:
    'Unfortunately, we are unable to provide the reason why your application has been rejected.',

  onboardingDeniedCreditHelp: 'Do you need help?',
  onboardingDeniedCreditHelpPhone: 'Call customer service on tel. {0}',
  onboardingDeniedCreditHelpTime: 'Monday to Friday between 9 - 15',
  onboardingActionButtonToSundhed: 'Back to Sundhedplus.dk',

  // Loading spinner messages
  onboardingSpinnerSubtitleCprRegistration:
    'Wait a moment while we retrieve your data from the CPR register.',
  onboardingSpinnerSubtitleCalculatingBudget:
    'We are now calculating your budget based on the collected data',
  onboardingSpinnerMessageCalculatingBudget: 'Calculating budget..',
  onboardingSpinnerSubtitleCheckDebtRecords:
    'We have now obtained all the data we need to make a credit assessment, and our credit robot is busy finding out whether we can offer you a Sundhed+ Credit.',
  onboardingSpinnerMessageCheckDebtRecords: 'Checking available amount',
  onboardingSpinnerTitleLinkBankAccount: 'Your Expenses',
  onboardingSpinnerSubtitleEstablishBudget:
    'We are now establishing your budget, based on the data obtained.',
  onboardingSpinnerMessageEstablishBudget: 'Establishing budget...',
  onboardingSpinnerSubtitleEskatCompletes:
    'We are now fetching your latest tax assessment notice and income data from the tax authorities, in order to conduct an initial credit assessment.',
  onboardingSpinnerMessageEskatCompletes: 'Retrieving eSkat data',
  onboardingSpinnerMessageEskatWaitTime: 'It can take up to 15 seconds',
  onboardingSpinnerSubtitleDataValidation:
    'We have now obtained all the data we need to make a credit assessment, and our credit robot is busy finding out whether we can offer you a Sundhed+ Credit.',
  onboardingSpinnerMessageDataValidation: 'Calculate',
  onboardingSpinnerMessageDataValidation2: 'Analyzing data',
  onboardingSpinnerMessageDataValidation3: 'Updating budget',
  onboardingSpinnerSubtitleLinkBankAccount:
    'We are now connecting to the service where you can grant access to authorize us to retrieve your bank statements from your bank',
  onboardingSpinnerMessageLinkBankAccount: 'Retrieve data',
  onboardingSpinnerSubtitleAnalyzeData:
    'We have now obtained all the data we need to make a credit assessment, and our credit robot is in full swing to find out whether we can offer you a Sundhed+ Credit.',
  onboardingSpinnerMessageAnalyzeData: 'Checking available amount',
  onboardingSpinnerMessageCheckCPR: 'Checking CPR number',
  onboardingSpinnerMessageCprGetData: 'Retrieving data',
  onboardingSpinnerMessageTaxInformationLoader: 'Forwarding',

  // onboarding - technical error
  onboardingTechnicalErrorTitle: 'Application Aborted',
  onboardingTechnicalErrorSubtitle:
    'Unfortunately, your application has been interrupted',
  onboardingTechnicalErrorP1:
    'We are very sorry, but unfortunately an error occurred in connection with the credit assessment, and we are therefore unable to offer you a Sundhed+ Credit at this time.',
  onboardingTechnicalErrorP2:
    'Please contact customer service on tel. {0} or {1} for help.',
  onboardingTechnicalErrorP3:
    'You of course still have access to all our other member benefits, and will in future receive our newsletter with inspiring articles and exclusive member offers approx. 2 times monthly.',
  onboardingTechnicalSubsectionHeadline: 'Get started...',
  onboardingTechnicalSubsectionP:
    'We have sent you an e-mail at {0}, with information about your membership and the benefits you can look forward to as a member of Sundhed+.',

  // onboarding - invalid link for onboarding
  onboardingInvalidLinkTitle: 'Invalid link',
  onboardingInvalidLinkP: `Sadly, you have entered an invalid link, which couldn't be passed to an ongoing credit application.
    If this is not correct according to you, please contact our customer service on either email, {0}, or phone, {1}`,
  onboardingInvalidLinkButton: 'Restart',

  // onboarding - email verification
  onboardingEmailVerificationTitle: 'Confirm email address',
  onboardingEmailVerificationSubtitle:
    'To verify your email address, please enter the code we just sent to {0}.',
  onboardingEmailVerificationP:
    'Please note that it can sometimes take up to 2 minutes before you receive the email.',
  onboardingEmailVerificationErrorTitle: 'Email code is incorrect',
  onboardingEmailVerificationErrorP1:
    'In order to create your membership, we need to be able to confirm your email address. Sadly, you have not yet managed to enter the code, which was sent to the entered email address.',
  onboardingEmailVerificationErrorLabel: 'Confirmation code',
  onboardingEmailVerificationErrorBtn: 'Send new code',

  onbordingEmailVerificationCodeText1:
    "If you haven't received an email with a confirmation code, please double check that the above email address is correct and that the email has not ended up in your spam filter. You can request a new code in",
  onboardingEmailVerificationCodeText2:
    "If you haven't received an email with a confirmation code, please double check that the above email address is correct and that the email has not ended up in your spam filter. Resend confirmation code to ",
  onboardingEmailVerificationSendNewCode: '{0}.',

  onboardingEmailConfirmationErrorMessage:
    'The code "{0}" does not match the code we just emailed you. Please try again',

  // onboarding - Link bank account Subaio
  onboardingLinkBankAccountTitle: 'Your expenses',
  onboardingLinkBankAccountP1:
    'In order to calculate your monthly disposable income, we also need to know your fixed expenses.',
  onboardingLinkBankAccountP2:
    'By giving consent for us to retrieve your bank statements, our system will automatically try to identify your fixed monthly expenses.',
  onboardingLinkBankAccountP3:
    'When you click the button below, a new window will open where you can use MitID to authorize us to retrieve your bank statements from your bank.',
  onboardingLinkBankAccountP4:
    'If you have accounts in multiple banks, it is very important that you grant access to all of them, as otherwise we will not be able to create an accurate budget.',
  onboardingLinkBankAccountButton1: 'Retrieve Bank Statements',
  onboardingLinkBankAccountAccesAllBankMessage1:
    'Did you grant access to all the banks where you have accounts? If so, we are almost done!',
  onboardingLinkBankAccountAccesAllBankMessage2:
    'Note: It is not possible to add the same bank account twice.',
  onboardngLinkBankAccountButtonNext: 'Next',
  onboardngLinkBankAccountButtonAdditionalAccounts: 'Add Additional Accounts',
  onboardngLinkBankAccountPopupClosedError:
    'It looks like you closed the window without completing the association with your bank. Please try again.',

  // onboarding - Loading with bullet list
  onboardingLoadingSubtitle:
    'We are now connecting to the tax authorities to retrieve your latest annual statement and information about your income.',
  onboardingLoadingText1: 'Searching for data in Tinglysning',
  onboardingLoadingText2: 'Searching for data in Bilbogen',
  onboardingLoadingText3: 'Searching for data in Andelsbogen',
  onboardingLoadingText4: 'Searching for data in Geomatic',
  onboardingLoadingText5: 'Searching for data in BBR',
  onboardingLoadingText6: 'Calculating budget...',
  onboardingLoadingAnalyzingText1: 'Retrieving data from SKAT',
  onboardingLoadingAnalyzingText2: 'Analyzing tax data',
  onboardingLoadingAnalyzingText3: 'Processing information',
  onboardingLoadingAnalyzingText4: 'Retrieving necessary information',
  onboardingLoadingAnalyzingText5: 'Please wait...',

  // onboarding - Edit budget types
  CATEGORY_NET_INCOME: 'Income after tax',
  NET_INCOME_TOTAL: 'Salary',
  HOUSING_BENEFIT: 'Housing benefit',
  CHILD_BENEFIT: 'Child allowance',
  CHILDREN_CHECK_AMOUNT: 'Child and youth benefit (child check)',
  ADDITIONAL_INCOME: 'Other income',

  CATEGORY_LIVING: 'Housing expenses',
  HOUSE_LOAN_EXPENSES: 'House loan expenses',
  MORTGAGE_EXPENSES: 'Mortgage benefit',
  RENTAL_EXPENSES: 'Rent',
  COMMON_FIXED_EXPENSES: 'Common expenses/owner association',
  ELECTRICITY: 'Electricity',
  HEATING_EXPENSES: 'Heat',
  WATER: 'Water',
  MAINTENANCE: 'Maintenance',
  REAL_ESTATE_INSURANCE: 'Home insurance',

  CATEGORY_TRANSPORT: 'Expenses for transportation',
  TRAIN_BUS_CARD: 'Public transport',
  TRANSPORT_FUEL_EXPENSES: 'Fuel',
  CAR_LEASING_COST: 'Car leasing',
  CAR_LOAN: 'Car loan',
  CAR_INSURANCE: 'Car insurance',
  CAR_MAINTENENCE: 'Service on car',
  CAR_OWNERSHIP_TAXES: 'Owner Tax',
  FDMAAA: 'Road side assistance',

  CATEGORY_DEBT: 'Debt expenses',
  EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN: 'Bank loan expenses ',
  STUDENT_LOAN_EXPENSES: 'Student loan expenses',
  DebtSinceLastTaxReport: 'DebtSinceLastTaxReport',

  CATEGORY_OTHERS: 'Other fixed expenses',
  SECURITY_SUBSCRIPTION: 'Alarm and security',
  CHILDREN_SUPPORT: 'Child support',
  CHILDREN_COST: 'Daycare',
  HEALTH_INSURANCE: '	Health Insurance',
  INSURANCE: 'Other insurance',
  INTERNET_SUBSCRIPTION: 'Internet',
  PHONE_SUBSCRIPTION: 'Telephony',
  TV_SUBSCRIPTION: 'TV/streaming subscriptions',
  FAGLIGT_KONTIGENT: 'Academic contingent',
  OTHER_EXPENSES_TO_PUBLIC_AUTHORITIES: 'Other public expenditure',
  ADDITIONAL_EXPENSES: 'Other expenses',

  CATEGORY_HOUSEHOLD: 'Your household expenses',
  HOUSEHOLD: 'Your household expenses',
  SPLIT_EXPENSES: 'Split expenses',

  correctedText: '(corrected)',
  onboradingAmountEdited: 'The amount is fixed by the customer',
  onboardingAmountFromReference: 'The amount is calculated based statistics',
  onboardingAmountFromESkat: 'The amount is found in your tax data',
  onboardingAmountFromPSD2: 'The amount is found in your banking data',
  onboardingAmountFromClient: 'The amount is provided by the applicant',
  onboardingAmountIncludedInRent:
    'The applicant has informed, this to be included in the rent',
  onboardingAmountPayedByEmployer:
    'The applicant has informed, this is paid by employer',
  onboardingAmountMissing: 'Could not be calculated',
  onboardingBudgetSplitExpensesTag: 'Shared with partner',
  onboardingBudgetSplitExpensesP1: `Since you have stated that you pay {0} % of your household's fixed expenses, budget items marked with {1} are only weighted with {0} % in our calculation of how much credit your finances can carry.`,

  // Documentation handling section
  documentationSideBarMenuItem: 'Documentation',
  documentationPlaceHolder: 'documentation',
  documentationSeachPlaceholder: 'Search for documentation...',
  documentationUploadedDate: 'Uploaded d. {0}',
  documentationApproveBtn: 'Approve',
  documentationDenyBtn: 'Deny',
  documentationBudgetHeader: 'Budget',
  documentationBudgetBtn: 'See full budget',
  documentationDocumentPreviewBtn: 'See in fullpage',
  documentationClientNote: 'Note',
  documentationClientNoteDefault: 'No client note',

  // payment agreement signature
  paymentAgreementSignatureLabel1: 'The agreement is approved by',
  paymentAgreementSignatureLabel2: 'E-mail',
  paymentAgreementSignatureLabel3: 'IP-address',
  paymentAgreementSignatureLabel4: 'Device signature:',
  paymentAgreementSignatureLabel5: 'Location',
  paymentAgreementSignatureTopBtnTextMitID: 'MitID-verified',
  paymentAgreementSignatureTopBtnTextNemIdAndMitId: 'NemID / MitID-verified',
  paymentAgreementSignatureBottomText1:
    'The agreement has been digitally approved by {0}',
  paymentAgreementSignatureBottomText2: '{0}, at {1} CET',
  documentationFilterStatus: 'Documentation status',
  documentationFilterType: 'Documentation type',
  documentationMemberProfileButton: 'Member profile',
  documentationSeeApplicationButton: 'See application',
  documentationUpdateBtn: 'Update',

  documentationBudgetTypeInformation: 'Expense for {0}',
  documentationBudgetReferenceBudget: 'Reference budget:',
  documentationBudgetRetrievedByNoitso: 'Retrieved by Noitso:',
  documentationBudgetInformedByCustomer: 'Informed by customer:',

  documentationDocumentsUploaded: 'Documentation',
  documentationBtnApproveDocuments: 'Approve documentation',
  documentationConfirmApproveDocuments:
    'Are you sure you want to approve these documents?',

  documentationRequestDocumentation: 'Request documentation',
  gotToDocumentList: 'All documents',

  documentationNoteModalTitle: 'Send request for additional documentation',
  documentationNoteModalButtonSend: 'Send request',
  documentationNoteModalCloseTitle: 'Cancel request?',
  documentationNoteModalButtonYes: 'Yes',
  documentationNoteModalButtonNo: 'No',

  documentationNoteCloseButton: 'Close',
  documentationSupportNoteTitle: 'Sent requests',

  onboardingUpdatingValueLoader: 'Updating',
  onboardingUpdatingValueLoader2: 'Please wait...',

  // documentation filter status
  documentationFilterApproved: 'Approved',
  documentationFilterPending: 'Pending',
  documentationFilterRejected: 'Rejected',
  documentationFilterPlaceholder: 'All documentation types',

  // documentation budget
  documentationBudgetReference: 'Reference',
  documentationBudgetPSD2: 'PSD2',
  documentationBudgetFinal: 'Final',
  documentationBudgetSource: 'Source',

  // aplications
  applicationsSideBarMenuItem: 'Applications',
  applicationsSeachPlaceholder: 'Search for application',
  applicationsPlaceHolder: 'applications',

  // application filter status
  applicationFilterStatus: 'Application status',
  applicationFilterInProgress: 'In progress',
  applicationFilterFailed: 'Failed',
  applicationFilterRejected: 'Rejected',
  applicationFilterDeprecated: 'Deprecated',
  applicationFilterPendingManual: 'Manual intervention',
  applicationFilterPendingDocumentation: 'Pending documentation',
  applicationFilterPendingActivation: 'Pending activation',
  applicationFilterDone: 'Done',

  // application page
  applicationsGoToList: 'All applications',
  applicationItemID: 'ID:',
  applicationItemDate: 'Date:',
  applicationKebabMenuItem1: 'Member profile',
  applicationKebabMenuItem2: 'Noitso data',
  applicationKebabMenuItem2v2: 'See in Monthio',
  applicationKebabMenuItem3: 'Annual statement (PDF)',
  applicationKebabMenuItem4: 'Approve application',
  applicationKebabMenuItem5: 'Deny application',
  applicationKebabMenuItem6: 'Continue application',
  applicationKebabMenuItem7: 'Check payment remarks',
  applicationKebabMenuItem8: 'Monthio data (PDF)',

  applicationTimelineeSkat: 'Tax',
  applicationTimeLineMonthio: 'Monthio',
  applicationTimeLineDebitorCheck: 'Payment remarks',
  applicationTimelineDocumentation: 'Documentation',
  applicationTimelineCompleted: 'Completed',

  applicationClientDetails: 'Personal information',
  applicationClientPhone: 'Phone no.',
  applicationClientCPR: 'CPR',
  applicationWarnings: ' Warnings:',
  applicationBudgetStatus: 'Status',
  applicationBudgetReference: 'Reference',
  applicationBudgetBankData: 'Bank data',
  applicationBudgetClientEntered: 'User correction',
  applicationBudgetSplit: 'Split',
  applicationBudgetFinal: 'Final',
  applicationBudgetNoData: 'Awaiting budget data',
  applicationBudgetSaveBtn: 'Save corrections',
  applicationBudgetDisposableAmount: 'Amount for free disposal',
  applicationBudgetDisposableCalculated: 'Calculated',
  applicationBudgetInformedByApplicant: 'Informed by applicant',
  applicationCarHeadline: 'Cars',
  applicationCarSubheadline: 'Leasing (service)',
  applicationChildrenHeadline: 'Children',
  applicationChildrenSubheadline: 'Age',
  applicationChildrenAgeGroup1: 'Age group 0-1',
  applicationChildrenAgeGroup2: 'Age group 2-6',
  applicationChildrenAgeGroup3: 'Age group 7-17',
  applicationCreditHeadline: 'Credit',
  applicationCreditMaxCredit: 'Max credit',
  applicationCreditRequestedAmount: 'Requested credit',
  applicationCreditInterestRate: 'Interest rate',
  applicationCreditMiscellaneous: 'Miscellaneous',
  applicationCreditHouseType: 'House type',
  applicationCreditCostSharing: 'Cost sharing',
  applicationCreditDebtMonth: 'Debt per month',
  applicationIncomeHeadline: 'Income and debt',
  applicationIncomePay: 'Salary',
  applicationIncomeDebt: 'Debt',
  applicationIncomeDebtRation: 'Debt ratio',
  applicationProperties: 'Properties',
  applicationPropertiesAddress: 'Address',
  applicationBudgetQuantity: 'Quantity',
  applicationChildrenYears: 'years',
  applicationIncomeNetSalary: 'Net salary:',
  applicationIncomeGrossSalary: 'Gross salary:',
  applicationIncomeSalaryType: 'Salary type:',
  applicationIncomeSalaryAverage: 'Average net salary:',
  applicationApproveHeadline: 'Approve application',
  applicationApproveText:
    "By approving the application, you confirm that you have reviewed the applicant's budget, that the budget is adequate and that the applicant is creditworthy.",
  applicationApproveComment: 'Comments',
  applicationApprovedModalConclusionSectionMonthly: 'Minimum duration',
  applicationApprovedModalConclusionSectionMonthlyPayment:
    'Monthly installment',
  applicationDenyBtn: 'Deny',
  applicationApproveBtn: 'Approve',
  applicationCancelActionBtn: 'Cancel',
  applicationApproveDenyReasonHeadline: 'Reject application',
  applicationApproveDenyReasonNote: 'Write a short reason for rejection',
  applicationApproveDenyReasonPlaceholer:
    'Write a short reason for rejecting the application...',

  onboardingUploadAllCompletedHeadline: 'Well done',
  onboardingUploadAllCompletedSubtitle:
    'You have now submitted documentation for all entries',
  onboardingUploadAllCompletedP1:
    'Since you have submitted your documentation during our opening hours, we expect that you will be contacted within 1 hour.',
  onboardingUploadAllCompletedP2:
    'You should therefore keep this page open, as you will be able to see your answer here easily and quickly.',
  onboardingUploadAllCompletedBtn: 'Ok',

  // application warning options
  applicationWarningOption1Headline:
    'The applicant is getting unemployment assistance',
  applicationWarningOption1Description:
    'The applicant is getting unemployment assistance. People in this group are usually in a vulnerable position, so it is important to be extremely cautious before the credit application potentially is approved.',
  applicationWarningOption2Headline:
    'Missing payslips within the latest 6 months',
  applicationWarningOption2Description:
    'At least one missing payslip have been found within the last 6 months. It is important to ensure, whether this could occur again at a later stage. This should be cleared up before the credit application can be approved.',
  applicationWarningOption3Headline: 'The applicant is in arrears',
  applicationWarningOption3Description:
    'The application is in arrears, meaning owing money for the government. It is important to check up on this, and potentially take this into account, before potentially approving the credit application.',

  // application payslips salary types
  payslipsType00: 'Normal salary income (e.g. white-collar workers)',
  payslipsType01: 'Education benefit (SU)',
  payslipsType03: 'Income under the rules regarding Greenland tax',
  payslipsType04:
    'Other personal income, e.g. daily allowance, G-day allowance, pension, municipal resource progression benefit, municipal flexible wage subsidy, other transfer income',
  payslipsType05: `B income. If the person has received A income at the same time, then income type' is used`,
  payslipsType06: 'Cash assistance',
  payslipsType07:
    'Sickness and maternity benefits paid by the public, directly to the person.',
  payslipsType08: 'Research scheme - taxation according to KSL §48E - F',
  payslipsType09:
    'Am contribution-free and A tax-free A income. For example, DIS income, salary of foreign employees of Danish companies for work performed abroad.',
  payslipsType20:
    'Income from the AFU fund. May only be used by the AFU Foundation.',
  payslipsType24:
    'As income type 04, but for benefits in which salary deductions cannot be made.',
  payslipsType26:
    'A income etc. from retirement savings - expected to be created in mid-2018. The income type must so far only be used by providers of employer-administered retirement savings, together with fields 57 and 58.',

  //update budget from application loading
  applicationUpdateBudgetLoadingHeadline: 'Updating budget',
  applicationUpdateBudgetLoadingSubtitle: 'Please wait...',

  applicationDocumentationStatusRequired: 'Required',
  applicationDocumentationStatusPending: 'Pending',
  applicationDocumentationStatusApproved: 'Approved',
  applicationDocumentationStatusRejected: 'Rejected',

  // onboarding restart modal
  onboardingRestartApplicationHeadline:
    'Do you want to pick up where you left off?',
  onboardingRestartApplicationText:
    'It appears that you have already started an application for a Sundhed+ Credit. Do you want to continue the application or start over?',
  onboardingRestartApplicationSuccess: 'Proceed',
  onboardingRestartApplicationCancel: 'Reset',

  // Manual intervention Onboarding v2

  manualInterventionTitle: 'Budget submitted',
  manualInterventionSubheadline: 'Your budget has been sent for manual review',
  manualInterventionP1:
    'Our nice customer service employees are ready to process your credit application every workday between 8 and 16. We will let you know, as soon as we have reviewed your case.',
  manualInterventionP2:
    'If you in the meantime has questions about your application, or for Sundhed+ Kredit in general, you are always very welcome to reach out to our customer service at {0} or {1}',
  manualInterventionButton: 'Close',

  // Onboarding errors
  // Create member error
  createMemberErrorTitle: 'Sorry, a technical error occurred',
  createMemberErrorP1:
    'It seems like something related to your entered data is causing issues on our system.',
  createMemberErrorP2: 'Please contact us on {0} or phone {1}',

  // Create member error
  confirmEmailAlreadyConfirmedError: 'Link is no longer valid',
  confirmEmailLinkError: 'Email is already confirmed',
  confirmEmailAlreadyConfirmedP1:
    'It looks like you have already confirmed your email',
  confirmEmailAlreadyConfirmedP2:
    'If you need help, you are always very welcome to reach out on {0} or phone {1}',

  //withBackCheck
  withBackCheckMessage: 'Sorry, is not possible at this step to go back',

  // cache busting confirm modal
  cacheBustingConfirmModalTitle: 'New update available',
  cacheBustingConfirmModalText:
    'We have just made some improvements to the platform. However, before you can enjoy the improvements, the page needs to be refreshed. {0} If you are in the middle of something, feel free to press "Not now", and we will remind you again in five minutes.',
  cacheBustingConfirmModalConfirmButton: 'Update',
  cacheBustingConfirmModalCancelButton: 'Not now',

  // Registration InvoiceFlow
  registerInvoiceFlowLookupPatientTitle: 'Find member',
  registerInvoiceFlowLookupPatientSubTitle:
    'Enter CPR number and the first 2 letters of the first name of the Sundhed+ member who wishes to pay with their Sundhed+ Credit.',
  registerInvoiceFlowLookupPatientParagraph:
    "If this is the first time you have registered an invoice amount for this member, the member will receive a notification that {0} now has access to the member's Sundhed+ Credit.",
  registerInvoiceFlowButton: 'Find member',
  registerInvoiceFlowMembersCPR: 'The member´s CPR number:',
  registerInvoiceFlowMemberName:
    "The first two letters of the member's first name:",
  registerSeeProfileButton: 'See member´s profile',
  registerInvoiceButton: 'Register invoice',

  registerInvoiceFlowPatientInfoTitle: "Member's credit",
  registerInvoiceFlowCprLabel: 'CPR-nr.:',
  registerInvoiceFlowCreditStatus: 'Credit status:',
  registerInvoiceFlowExpirationDate: ' ({0} days)',
  registerInvoiceFlowExpirationDateforNonActiveCredit: '{0}.',
  registerInvoiceFlowCreditAmount: 'Available credit amount:',
  registerInvoiceFlowCreditExpired: 'Expiration date:',

  registerInvoiceFlowTitleCreditInactive: "The patient's credit is inactive.",
  registerInvoiceFlowButtonClosed: 'Close',
  registerInvoiceFlowNote: 'Note:',
  registerInvoiceFlowCreditInactiveStatus: 'Closed',
  registerInvoiceNoteTitleForStatusClosed: 'The credit is closed.',
  registerInvoiceNoteMessageForStatusClosed:
    "The member's credit has been closed. It is therefore not possible to register additional invoice on this credit.",

  registerInvoiceNoteTitleForPendings: 'The credit is temporarily blocked.',
  registerInvoiceNoteMessage:
    'The credit is blocked for registration of additional invoices until the patient has approved/rejected the last entered invoice amount.',
  registerInvoiceNoteTitleForExpiredCredit: 'The credit has expired.',
  registerInvoiceNoteMessageForExpiredCredit:
    "The member's credit has expired. If the member wishes to reactivate the credit, they must complete a new creditworthiness assessment.",
  registerInvoiceSmsButtonToNewAssesmentLink:
    'Send an SMS with a reactivation link to the member.',

  // register InvoiceFlow Statuses
  registerInvoiceFlowStatusClosed: 'Closed',
  registerInvoiceFlowStatusRejected: 'Rejected',
  registerInvoiceFlowStatusInactive: 'Inactive',
  registerInvoiceFlowStatusSuspended: 'Suspended',
  registerInvoiceFlowStatusActive: 'Active',
  registerInvoiceFlowStatusDebtCollection: 'Sent to debt collection',
  registerInvoiceFlowStatusExpired: 'Expired',

  //mitSundhed+ - Dashboard
  mitSundhedDashboardTitle: 'Welcome to Mit Sundhed+',
  mitSundhedDashboardSubTitle:
    'Here you can see an overview of your Sundhed+ Credit and your budget.',
  mitSundhedDashBoardingLoginButton: 'Sign in',

  mitSundhedMenuItemKredit: 'Sundhed+ Credit',
  mitSundhedMenuItemProfile: 'Member profile',
  mitSundhedMenuItemHelp: 'Help',

  mitCreditTitle: 'Your Sundhed+ Credit',
  mitCreditTitleSmallScreen: 'Sundhed+ Credit',
  mitCreditStatus: 'Credit status',
  mitCreditAvailable: 'Credit available',
  mitCreditExpiration: ' - expires {0}',
  mitInterestRate: 'Debtor interest',
  mitCreditLimit: 'Credit limit',

  // Credit Status Mapping Text
  mitCreditStatusActive: 'Active',
  mitCreditStatusFullyUtilized: 'Fully utilized',
  mitCreditStatusInactive: 'Inactive',
  mitCreditStatusExpired: 'Expired',
  mitCreditStatusRejected: 'Rejected',
  mitCreditStatusDisabled: 'No credit',
  mitCreditStatusSuspended: 'Suspended',
  mitCreditStatusLatePayment: 'Late payment',
  mitCreditStatusClosed: 'Closed',

  // warning card messages
  mitWarningCardForMissingCreditCard:
    'You have not added a payment card to your credit agreement. You must add a payment card in order for us to deduct your monthly payment.',
  mitCreditCardNotActive:
    'Your payment card has been blocked/expired, and you must therefore link a new payment card to your credit agreement in order for us to deduct your monthly payment.',
  mitCreditCardInsufficientFunds:
    'We have not been able to withdraw the latest service on your associated payment card. Please make sure to get coverage on the card, or add a new payment card to your payment agreement.',
  mitNoteForLatePayment:
    'Due to default, your credit has been closed. Contact Sundhed+ Customer Service if you have any questions.',
  mitWarningCardForDisabledAndRejectedWithNoActiveAgreement: `When you create a Sundhed+ Kredit, you get access to a flexible credit of up to DKK ${creditMaxAmountApproveCreditForWarningCards}, which you can use to divide the payment for your health treatments into smaller chunks.`,
  mitNoteForExpiredCredit:
    'Your Sundhed+ Credit has expired. If you want to reactivate your Sundhed+ Kredit, so that you can also divide the payment for your health treatments into smaller parts in the future, you must carry out a renewed credit check.',
  mitCreditIsFullyUtilized:
    'Your credit amount is fully utilized, which means, that it can no longer be used for new invoices.',
  mitButtonForDisabledNote: 'Apply for Credit',

  mitRenewCreditCard: 'Add payment card',
  mitStartCheckCreditButton: 'Start credit check',

  mitCreditInfoTitle: 'Credit Information',
  mitCreditInfoP1:
    ' You can use your credit to pay for health treatments at all Sundhed+ Partners, as long as the credit is active and there is credit available. The credit is active for 12 months from the creation date - after that you must carry out a renewed credit check if you still want to be able to draw on the credit.',
  mitCreditInfoP2:
    'When there are less than 6 months until the credit expires, you can extend the credit by a further 12 months by completing a credit check. Be aware, however, that the credit check may mean that you are no longer assessed as creditworthy, and that you thus lose access to draw further on your credit. The credit check can also cause your credit limit to be raised or lowered',

  mitCreditInfoDunningP1:
    'Your credit is currently suspended. A Sundhed+ Kredit is automatically suspended if you are in arrears with one or more payments, or if your payment history over the past 6 months indicates that your finances cannot currently support a higher monthly payment than the one you are currently paying.',
  mitCreditInfoDunningP2:
    '{0}, Sundhed+ can also choose to suspend a credit at any time for other reasons.',

  mitCreditInfoDunningCreditAgreement: 'Credit agreement',

  mitCreditInfoSuspended:
    'Your credit is closed and can no longer be used. Contact Sundhed+ Customer Service if you have any questions about the closure.',

  mitCreditAgreementTitle: 'Active payment agreements',
  mitCreditAgreementTotalDebt: 'Total debt',
  mitCreditAgreementTableCreated: 'Created',
  mitCreditAgreementTableIMontly: 'Month.',
  mitCreditAgreementTableItemInterest: 'Interest',
  mitCreditAgreementTableItemOustandingDebt: 'Outstanding Debt',
  mitCreditAgreementTableItemLoanAmount: 'Loan amount',
  mitCreditAgreementTableItemServiceFee: 'Service fee',

  mitCreditAgreementKebabMenuOptionShowDetails: 'See details',
  mitCreditAgreementKebabMenuOptionPartialPayment: 'Extra payment',
  mitCreditAgreementKebabMenuOptionSettleLoan: 'Settle loan',

  tableEmptyList: 'No data available',
  tablePaymentAgreementsEmptyTable: 'No active payment agreements',
  tableBillingHistoryEmptyTable: 'No billing history',

  mitServicesSectionTitle: 'Services',
  mitServicesSectionPartialRedemptionHeadline: 'Make an extraordinary deposit',
  mitServicesSectionPartialRedemptionDescription:
    'If you have money in surplus and want to reduce your debt, you can make an extraordinary payment of an amount of your choice.',
  mitServicesSectionPartialRedemptionButton: 'Deposit Amount',

  mitServicesSectionCustomizePaymentHeadline: 'Adjust your monthly payment',
  mitServicesSectionCustomizePaymentDescription:
    'In most cases, it is possible to adjust the amount you pay each month by shortening or extending the term of your payment agreement. {0}',
  mitServicesSectionCustomizePaymentDescriptionLink: 'See your options',
  mitServicesSectionCustomizePaymentButton: 'Adjust payment',

  mitServicesSectionFreeMonthHeadline: 'Create a free month',
  mitServicesSectionFreeMonthDescription:
    'If you create a free month today, you will not be charged any service on the 1st of {0}. You can create a maximum of 2 free months per 12-month period.',
  mitServicesSectionFreeMonthButton: 'Free month',

  mitServicesSectionSettleHeadline: 'Pay off your remaining debt',
  mitServicesSectionSettleDescription:
    'You always have the option to pay off your remaining debt at no cost.',
  mitServicesSectionSettleButton: 'Settle loan',

  mitServicesSectionFee: 'Service Fee: {0}',

  mitPaymentFreeHeadline: 'Create a payment-free month',
  mitPaymentFreeP1:
    'With a Sundhed+ Credit you have the option to temporarily pause the payment of the monthly payment. {0} We call it a “payment-free month”, and you can activate up to 2 payment-free months per 12 months.',
  mitPaymentFreeQuestion: 'Do you want to make {0} a payment-free month?',
  mitPaymentFreeP2:
    'When you activate a payment-free month, a new payment agreement will be created, replacing your current agreement.',
  mitPaymentFreeP3:
    'The service fee of {0} for activating a payment-free month will be included in the principal of the new payment agreement.',
  mitPaymentFreeBanner: 'Number of payment-free months in the last 12 months.',
  mitPaymentFreeBannerCounter: '{0} / 2',
  mitPaymentFreeCancelBtn: 'Cancel',
  mitPaymentFreeNextBtn: 'Next',

  mitPaymentDetailsTitle: 'Payment details',
  mitPaymentDetailsMonthlyPayment: 'Monthly payment',
  mitPaymentDetailsLastPayment: 'Last payment',
  mitPaymentDetailsLinkedPaymentCard: 'Linked payment card',
  mitPaymentDetailsCardStatus: 'Card status',

  mitUpdateCreditCard: 'Update credit card',

  mitBillingHistoryTitle: 'Billing history',
  mitBillingHistoryDate: 'Date',
  mitBillingHistoryClinicAssociated: 'Clinic associated',
  mitBillingHistoryStatus: 'Status',
  mitBillingHistoryAmount: 'Amount',

  mitPractitionerAccessTitle: 'Practitioner access to your credit',
  mitPractitionerAccessP1:
    'All practitioners who collaborate with Sundhed+ have the opportunity to access your credit and register an invoice amount on the credit. When a practitioner retrieves your credit for the first time, you will be notified by SMS, and the practitioner will be listed below.',
  mitPractitionerAccessP2:
    "If you remove a practitioner's access to your credit, the practitioner can still retrieve your credit, but you will be notified again by SMS.",

  mitPractitionerButton: 'Delete access',
  mitPractitionerAccessPage: 'Access page',

  mitDocumentSectionTitle: 'Agreement documents',
  mitCreditTermsLabel: 'Agreement provisions',
  mitCreditTermsCreditDownload:
    'Standardized European Consumer Credit Information',

  // MemberProfile
  mitMemberProfileTitle: 'Member profile',
  mitPersonalInformation: 'Personal information',

  // billing history mapping text status
  billingHistoryStatusWaitingForSignature: 'Awaiting approval',
  billingHistoryStatusAccepted: 'Accepted',

  // client portal error messages
  clientPortalErrorTitle: 'Something went wrong...',
  clientPortalErrorP1:
    'Unfortunately, a technical problem occurred with your login. Please try again or contact Sundhed+ Customer Service if the problem persists.',
  clientPortalErrorButton: 'Try again',
  clientPortalErrorHelpMsg: 'Need help?',
  // client portal error messages for not client
  clientPortalErrorNotClientTitle: 'Login failed',
  clientPortalErrorNotClientP1:
    'At this time, the Member Portal is reserved for members with a Sundhed+ Credit',
  clientPortalErrorNotClientP2:
    'Are you already a member of Sundhed+ and wish to apply for a Sundhed+ Credit? {0}',
  clientPortalErrorNotClientStartApplication: 'Start application',
  clientPortalErrorNotClientP3: 'Not a member yet? {0}',
  clientPortalErrorNotClientCreateAccount: 'Create a free membership',
  clientPortalErrorButtonGoBackToSundhed: 'Go back to sundhedplus.dk',

  // linkMissingPage modal
  linkMissingModalTitle: 'The Link has expired',
  linkMissingModalP1:
    'It seems that you have clicked on an old link that is no longer active.',
  linkMissingModalP2:
    "You can try to find what you're looking for by going to the front page of sundhedplus.dk.",
  linkeMissingModalP3:
    'Alternatively, you are also welcome to contact Sundhed+ customer service at {0} or by phone at {1}.',

  // welcome page
  welcomePageTitle: 'Welcome to the Partner Portal',
  welcomePageSubTitle: 'Get started quickly:',
  // patient with credit
  welcomePageCardCreateInvoiceTitle: 'Patient with credit',
  welcomePageCardCreateInvoiceP1:
    "Do you have a patient with an existing Sundhed+ Credit? Register the bill amount on the patient's credit with a few clicks.",
  welcomePageCardCreateInvoiceButton: 'Register amount',
  //patient without credit
  welcomePageCardSendApplicationLinkTitle: 'Patient without credit',
  welcomePageCardSendApplicationLinkP1:
    'Do you have a patient who wants to apply for a Sundhed+ Credit? Send the patient an email or SMS with a link to apply.',
  welcomePageCardSendApplicationLinkButton: 'Send application link',

  welcomePageContactInfoP1:
    'Do not hesitate to contact us if you have any questions.',
  welcomePageContactInfoP2: 'You can reach us on tel. {0} or on {1}',

  // send renew link modal
  sendRenewLinkModalTitle: 'Send renewal link',
  sendRenewLinkModalP1:
    'To ensure responsible lending, all members of Sundhed+ must undergo an annual credit check if they wish to continue accessing their credit.',
  sendRenewLinkModalP2:
    'You can help the member reactivate their credit by sending them a direct link to complete the credit check. The credit check can be done on both computer and mobile, and requires nothing more than MitID.',
  sendRenewLinkModalCheckBoxTitle: 'Select where the link should be sent',
  sendRenewLinkModalCheckBoxSMS: 'Send via SMS ({0})',
  sendRenewLinkModalCheckBoxEmail: 'Send via email ({0})',
  sendRenewLinkModalBtnCancel: 'Cancel',
  sendRenewLinkModalBtnSend: 'Send link',

  // send renew link modal success
  sendRenewLinkModalSuccessTitle: 'The link has been sent',
  sendRenewLinkModalSuccessSubTitle: 'Please note the following...',
  sendRenewLinkModalSuccessInfoBox1:
    'Renewal applications are generally processed within 15 minutes on weekdays between 9 AM and 3 PM.',
  sendRenewLinkModalSuccessInfoBox2:
    "The credit check may result in the member's current credit limit being increased or decreased.",
  sendRenewLinkModalSuccessInfoBox3:
    "If the credit check shows that the member's financial situation no longer meets the Financial Supervisory Authority's minimum creditworthiness requirements, the member's credit cannot be reactivated.",

  sendRenewLinkModalBtnClose: 'Close',

  // partial redemption
  partialRedemptionTitleWarningLimitError: 'Action Not Allowed',
  partialRedemptionTitleWarningCardError: 'Add a valid payment card',
  partialRedemptionTitleWarningDunningError: 'You are in dunning',
  partialRedemptionTextWarningMessage1:
    'Currently, it is only possible to attempt withdrawals once a day. You have reached this limit for today, so please try again tomorrow.',
  partialRedemptionTextWarningMessage2:
    'It is not possible to do a partial redemption before a working credit card has been added, which also has sufficient funds. You can add a new payment card {0}.',
  partialRedemptionTextWarningMessage2Link: 'here',
  partialRedemptionTextWarningMessage3:
    'Unfortunately, it is not possible to make an extraordinary payment as long as you are behind on your payments.',
  clientPortalRestructureTextWarningMessage3:
    'Unfortunately, it is not possible to make an adjustment to your monthly payment as long as you are behind on your payments.',
  clientPortalPaymentFreeTextWarningMessage3:
    'Unfortunately, it is not possible to make an addition of a payment-free month as long as you are behind on your payments.',
  partialRedemptionTextWarningMessage4:
    'Our system continuously attempts to debit the overdue amount from the payment card you have linked to your payment agreement. If you wish to link a new payment card, you can do so {0}.',

  clientPortalNotPayedHeadline:
    "You still haven't paid any payments on your existing payment agreement",
  clientPortalNotPayedMessage1:
    'Until the first payment of the payment agreement has been paid, it is not possible to adjust the payment agreement.',
  clientPortalNotPayedMessage2:
    'If this cannot wait until you have paid the first payment on your existing payment agreement, then please contact Sundhed+ customer service at email, {0}, or by phone, {1}.',
  partialRedemptionTextWarningMessage4Link: 'here',
  partialRedemptionButtonNext: 'Next',
  partialRedemptionButtonBack: 'Back',
  partialRedemptionButtonCancel: 'Cancel',

  clientPortalNoFreeMonthsHeadline:
    'You have already used all your payment free months of the last year',
  clientPortalNoFreeMonthsMessage1:
    'From the terms and conditions of a Sundhed+ Kredit, 2 payment free months are allowed per 12 continuous months. You have reached this limit for now, so sadly you cannot have further payment free month at the time being.',
  clientPortalNoFreeMonthsMessage2:
    'If this is extremely critical, you can contact Sundhed+ customer service at email, {0}, or by phone, {1}.',

  clientPortalBrandMismatchHeadline:
    'This service is only for Sundhed+ payment agreement',
  clientPortalBrandMismatchMessage1:
    'Sadly, it looks like your payment agreement is a Denti payment agreement, and not a Sundhed+ payment agreement which is not supported by this service.',
  clientPortalBrandMismatchMessage2:
    'If you have your credit card ready, you can migrate your payment agreement {0}, it is completely free of charge to migrate.',
  clientPortalBrandMismatchLink: 'here',

  clientPortalZeroInterestLoanHeadline:
    'This service are not for zero BNPL payment agreements',
  clientPortalZeroInterestLoanMessage1:
    'Sadly, it looks like you have an active BNPL payment agreement, which is not supported by this service.',
  clientPortalZeroInterestLoanMessage2:
    'If you have any questions about this, please do not hesitate to contact our customer service at email, {0}, or by phone, {1}.',

  partialRedemptionAdditionalPaymentTitle: 'Make an extraordinary payment',
  partialRedemptionAdditionalPaymentSubtitle:
    'With an extraordinary payment, you can quickly and easily reduce the remaining debt on your credit.',
  partialRedemptionAdditionalPaymentInputLabel:
    'Enter the amount you wish to pay',
  partialRedemptionAdditionalPaymenRemainingDebt: 'Current remaining debt',
  partialRedemptionAdditionalPaymenRemainingDebtAfterPayment:
    'Remaining debt after payment',

  partialRedemptionAdjustMonthlyPaymentTitle: 'Adjust your monthly payment',
  partialRedemptionAdjustMonthlyPaymentSubtitle:
    'Enter how much you want to pay each month in the future.',
  partialRedemptionAdjustMonthlyPaymentSubtitle2:
    'You can choose to repay the remaining debt over {0} to 72 months.',
  partialRedemptionAdjustMonthlyPaymentInputLabel:
    'Adjust your future monthly payment:',
  partialRedemptionAdjustMonthlyPaymentSliderInformation:
    'Drag the slider to adjust the payment',
  partialRedemptionAdjustMonthlyPaymentPeriodMonths: 'Repayment period',
  partialRedemptionAdjustMonthlyPaymentLastPaymentDate: 'Last payment date',

  partialRedemptionAcceptLoanAgreementTitle:
    'Accept new payment agreement and terms',
  partialRedemptionAcceptLoanAgreementContainerTitle: 'Payment Agreement',
  partialRedemptionAcceptLoanAgreementContainerSubTitle: 'Terms of Agreement',
  partialRedemptionAcceptLoanAgreementContainerInvoiceNumber:
    'Agreement No. {0}',

  partialRedemptionPaymentInfo1: 'Interest until {0}.',
  partialRedemptionPaymentInfo2: 'New remaining debt as of {0}.',
  partialRedemptionPaymentInfo3: 'Repayment period',
  partialRedemptionPaymentInfo4: 'Debtor interest rate',
  partialRedemptionPaymentInfo5: 'APR',
  partialRedemptionPaymentInfo6: 'Total credit costs',
  partialRedemptionPaymentInfo7: 'Total repayment',
  partialRedemptionPaymentInfo8: 'Monthly payment',
  partialRedemptionPaymentInfo9: 'First payment date',
  partialRedemptionPaymentInf10: 'Last payment date',
  partialRemptionPaymentDuration: '{0} m.',

  partialRedemptionConfirmPaymentTitle: 'Confirm payment',
  partialRedemptionConfirmPaymentSubtitle:
    'Please confirm that you wish to make an extraordinary payment of {0}. The extraordinary payment will result in your Sundhed+ Credit remaining debt being reduced to {1}.',
  partialRedemptionConfirmPaymentCardLabel:
    'We will charge {0} to the following card:',

  partialRedemptionPaymentProcessingLoadingTitle:
    'Your payment is being processed',
  partialRedemptionPaymentProcessingLoadingSubtitle: 'Please wait...',
  partialRedemptionPaymentProcessingFailedTitle: 'Payment failed',
  partialRedemptionPaymentProcessingFailedSubtitle:
    'It was not possible to charge {0} to your payment card, so your payment agreement has not been changed.',
  partialRedemptionPaymentProcessingFailedSubtitle2:
    'If you wish to make an extraordinary payment from another card, you can change the linked payment cards {0}.',
  partialRedemptionPaymentProcessingFailedSubtitle2Link: 'here',
  partialRedemptionPaymentProcessingFailedButton: 'Ok',
  partialRedemptionPaymentProcessingSuccessTitle:
    'Extraordinary payment completed',
  partialRedemptionPaymentProcessingSuccessSubtitle:
    'The remaining balance on your Sundhed+ Credit has now been reduced by {0}, and your payment agreement has been adjusted.',
  partialRedemptionPaymentProcessingSuccessSubtitle2:
    'The monthly payment will now be {0}, which will automatically be charged to your linked payment card.',
  partialRedemptionPaymentProcessingSuccessButton: 'Ok',
  partialRedemptionPaymentErrorMsg1: 'Please enter the amount to pay',
  partialRedemptionPaymentErrorMsg2:
    'Amount to pay cannot be greater than remaining debt',
  partialRedemptionPaymentErrorMsg3: 'Amount to pay cannot be less than 100 kr',
  partialRedemptionPaymentErrorMsg4:
    'Your new remaining balance cannot be lower than 500 kr. Alternatively, you can settle the loan with our "Deposit Amount" feature',

  // client portal - setled loan

  clientPortalSetledLoanTitle: 'Confirm payment for settling',
  clientPortalSetledLoanP1:
    'Please confirm, your wish to settle the payment agreement with a remaining balance of {0}. If the settlement succeeds, you will now longer have a remaining balance on your Sundhed+ Kredit, ie it will be reduce to 0 kr.',
  clientPortalSetledLoanP2:
    'We will withdraw {0} on the following credit card:',

  clientPortalSetledLoanFailedSubtitle:
    "Sadly, we didn't succeed withdrawing the amount of {0}. on your credit card, and your current payment agreement stays unchanged for now.",
  clientPortalSetledLoanFailedSubtitle2:
    ' If you want to make a redemption from another payment card, you can change the associated payment cards {0}.',
  clientPortalSetledLoanFailedSubtitle2Link: 'here',
  clientPortalSetledLoanSuccessTitle: 'Payment completed',
  clientPortalSetledLoanSuccessSubtitle:
    ' The remaining debt on your Sundhed+ Credit has now been written down by {0}, and your payment agreement has been rescheduled.',
  clientPortalSuccessSetledLoanP1:
    'You no longer owe money to Sundhed+, and your remaining balance and payment agreement has been settled. We will no longer withdraw money from your credit card.',

  // restructure loan client portal
  restructureLoanProcessingLoadingTitle: 'New payment agreement being created',
  restructureLoanProcessingLoadingSubtitle: 'Please wait...',
  restructureLoanProcessingFailedTitle:
    'Creation of new payment agreement failed',
  restructureLoanProcessingFailedSubtitle:
    'Sadly, we did not get success creating your new payment agreement due to technical difficulties, and your payment agreement remains unchanged.',
  restructureLoanProcessingFailedSubtitle2:
    'Please try again after pressing "Ok". Otherwise feel free to reach out to Sundhed+ customer service at email, {0}, or by phone, {1}, if the problem persists.',
  restructureLoanProcessingFailedButton: 'Ok',
  restructureLoanProcessingSuccessTitle: 'New payment agreement created',
  restructureLoanProcessingSuccessSubtitle:
    'A new payment agreement with a term of {0} months and a monthly payment of {1} has now been created.',
  restructureLoanProcessingSuccessSubtitle2:
    'The payment agreement replaces your previous payment agreement, and you will be charged {0} on the 1st of each month going forward.',
  restructureLoanProcessingSuccessSubtitle3:
    'We have added the service fee for the conversion of {0} to your outstanding debt. Your current outstanding debt is {1}.',
  restructureLoanProcessingSuccessButton: 'Ok',

  // check payment remarks status
  paymentRemarksSuccessHeadline: 'No payment remarks',
  paymentRemarksSuccessMessage:
    'The client is now checked, and no payment remarks was found.',
  paymentRemarksFailedHeadline: 'Payment remarks found',
  paymentRemarksFailedMessage:
    'The patient was found with payment remarks, please be careful!',
  applicationEndpointRequestModalBtnText: 'Ok',

  //continue aplication status
  continueApplicationSuccessHeadline:
    'Continue application email has been sent',
  continueApplicationSuccessMessage:
    'The email to continue the application was successfully sent to the client',
  continueApplicationFailedHeadline: 'Continue application email failed',
  continueApplicationFailedMessage:
    'The email to continue the application failed',
};

export default en;
