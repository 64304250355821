// routes
export const ROUTE_HOME = '/';
export const ROUTE_FORGOT_PASSWORD = `${ROUTE_HOME}forgotPassword`;
export const ROUTE_NEW_PASSWORD = `${ROUTE_HOME}newPassword`;
export const ROUTE_RESET_PASSWORD = `${ROUTE_HOME}resetPassword`;
export const ROUTE_LEADS = `${ROUTE_HOME}leads`;
export const ROUTE_COMPANIES = `${ROUTE_HOME}companies`;
export const ROUTE_UPDATE_CARD = `${ROUTE_HOME}creditcard`;
export const ROUTE_PATIENTS_DUNNING = `${ROUTE_HOME}patientsDunning`;
export const ROUTE_ACCEPTANCE_LOAN = `${ROUTE_HOME}acceptLoan`;
export const ROUTE_LOAN_CALCULATOR = `${ROUTE_HOME}loanCalculator`;
export const ROUTE_LIVE_AGENT = `${ROUTE_HOME}liveagent-plugin`;
export const ROUTE_COMPANY_CHAINS = `${ROUTE_HOME}companyChains`;
export const ROUTE_USER_REGISTRATION_FLOW = `${ROUTE_HOME}userRegistration`;
export const ROUTE_BOOKING_REQUEST = `${ROUTE_HOME}booking-request`;
export const ROUTE_MIGRATE_CREDIT = `${ROUTE_HOME}migrate-to-sundhedplus`;
export const ROUTE_SETTINGS = `${ROUTE_HOME}settings`;
export const ROUTE_UNSUBSCRIBE_BOOKING_REQUESTS = `${ROUTE_HOME}unsubscribeBookingRequests`;
export const ROUTE_CLINIC_CLEAR_COOKIES = `${ROUTE_HOME}clinicClearCookies`;
export const ROUTE_CLIENT_CLEAR_COOKIES = `${ROUTE_HOME}clientClearCookies`;
export const ROUTE_ONBOARDING = `${ROUTE_HOME}onboarding`;
export const ROUTE_SMARTLINK_ONBOARDING = `${ROUTE_HOME}smartlinkonboarding`;
export const ROUTE_ONBOARDING_EXISTING_MEMBER = `${ROUTE_HOME}applyForCreditAsMember`;
export const ROUTE_ONBOARDING_RENEW_CREDIT = `${ROUTE_HOME}renewalCredit`;
export const ROUTE_DOCUMENTATION = `${ROUTE_HOME}documentation`;
export const ROUTE_CREDIT_APPLICATIONS = `${ROUTE_HOME}creditApplications`;
export const ROUTE_MIT_SUNDHEDPLUS = `${ROUTE_HOME}main`;
export const ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE = `${ROUTE_HOME}memberProfile`;
export const ROUTE_MIT_SUNDHEDPLUS_HELP = `${ROUTE_HOME}help`;
export const ROUTE_MAIN_LOGIN = `${ROUTE_HOME}login`;
export const ROUTE_LINK_MISSING = `${ROUTE_HOME}linkMissing`;
export const ROUTE_START_CLIENT_PORTAL = `${ROUTE_HOME}startClientPortal`;
export const ROUTE_CLIENT_PARTIAL_REDEMPTION = `${ROUTE_HOME}partialRedemption`;
