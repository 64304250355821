// eslint has too many complains for this file so I ignore it :)
/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { strings } from '../../constants/localization';
import { moment } from '../../constants';
import {
  currencyFormatDA,
  numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator,
  isAdmin,
} from '../../constants/utils';
import { brandConfig } from '../../config';
import { invoicesStatusMap } from '../../constants/mappings';
import PaymentAgreementSignature from './PaymentAgreementSignature';
import { formatInterestRate } from '../../constants/utils';

const paymentPlanDocStyles = {
  fontFamily: 'Arial, sans-serif',
  margin: '0 10mm',
};
const titleStyles = {
  color: '#000',
  fontSize: '24px',
  marginBottom: '25px',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center',
};
const subtitleStyles = {
  textAlign: 'center',
  textDecoration: 'underline',
};
const sectionStyles = {
  color: '#000',
  paddingBottom: '25px',
  fontFamily: 'Arial, sans-serif',
  lineHeight: '35px',
};
const contactDetailsStyles = {
  float: 'left',
  width: '50%',
  lineHeight: '30px',
  marginBottom: '100px',
};

const tableLine = {
  width: '100%',
  lineHeight: '20px',
  minHeight: '30px',
  borderBottom: '1px solid #000',
  display: 'flex',
  alignItems: 'center',
};
const tableLineHeading = {
  width: '100%',
  lineHeight: '20px',
  minHeight: '30px',
  paddingBottom: '30px',
  borderBottom: '1px solid #000',
};

const tableCell = {
  width: '18%',
  display: 'inline-block',
};

const tableCellIndex = {
  width: '10%',
  display: 'inline-block',
};

const tableCellLeftAlign = {
  width: '18%',
  display: 'inline-block',
  textAlign: 'left',
};

const tableCellColspan3 = {
  width: '49.8%',
  display: 'inline-block',
};

class InvoicePaymentPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceDetails: props.invoiceDetails,
    };
    this.shouldAddNewPage = this.shouldAddNewPage.bind(this);
  }

  // this function was created to break a line when the add page is true so the signature component will never show in the middle of two pages
  shouldAddNewPage(duration) {
    const ranges = [
      { start: 1, end: 16, addPage: false },
      { start: 17, end: 26, addPage: true },
      { start: 27, end: 48, addPage: false },
      { start: 49, end: 58, addPage: true },
      { start: 59, end: 80, addPage: false },
      { start: 81, end: 90, addPage: true },
      { start: 91, end: 112, addPage: false },
      { start: 113, end: 122, addPage: true },
      { start: 123, end: 144, addPage: false },
      { start: 145, end: 154, addPage: true },
      { start: 155, end: 176, addPage: false },
      { start: 177, end: 186, addPage: true },
      { start: 187, end: 208, addPage: false },
    ];

    for (let range of ranges) {
      if (duration >= range.start && duration <= range.end) {
        return range.addPage;
      }
    }
    return false;
  }

  renderCreditDetails() {
    let invoice = {};
    if (this.state.invoiceDetails) {
      invoice = this.state.invoiceDetails;
    }

    const invoiceBrand = invoice.brand === 'Denti' ? 'Denti' : 'Sundhed+';
    return (
      <div className='creditDetails' style={sectionStyles}>
        <div>
          <span>{strings.productType}: </span>
          <span>
            {invoiceBrand} {strings.productTypeKredit}{' '}
          </span>
        </div>
        <div>
          <span>{strings.agreementNumber}: </span>
          <span>{invoice.number}</span>
        </div>
        <div>
          <span>{strings.creditAmount}: </span>
          <span>{currencyFormatDA(invoice.amount, false)} kr</span>
        </div>
        <div>
          <span>{strings.duration}: </span>
          <span>
            {invoice.duration} {strings.months}
          </span>
        </div>
        <div>
          <span>{strings.establishmentFee}: </span>
          <span>{currencyFormatDA(invoice.establishmentFee, false)} kr</span>
        </div>
        <div>
          <span>{strings.annualInterest}: </span>
          <span>{formatInterestRate(invoice.interestRate)}</span>
        </div>
        <div>
          <span>{strings.apr}: </span>
          <span>{formatInterestRate(invoice.apr)}</span>
        </div>
        <div>
          <span>{strings.totalCreditCost}: </span>
          <span>{currencyFormatDA(invoice.totalCreditCost, false)} kr</span>
        </div>
        <div>
          <span>{strings.monthlyPaymentsSum}: </span>
          <span>{currencyFormatDA(invoice.monthlyPaymentsSum, false)} kr</span>
        </div>
        <div>
          <span>{strings.startDate}: </span>
          <span>{moment(invoice.startDate).format('DD.MM.YY')}</span>
        </div>
        <div>
          <span>{strings.endDate}: </span>
          <span>{moment(invoice.endDate).format('DD.MM.YY')}</span>
        </div>
      </div>
    );
  }

  static renderCreditorContactDetails() {
    return (
      <div style={sectionStyles}>
        <h3>
          <strong>{strings.creditor}</strong>
        </h3>
        <div className='contactDetails'>
          <div>
            <span>{brandConfig.creditorName}</span>
          </div>
          <div>
            <span>{brandConfig.creditorAddress}</span>
          </div>
          <div>
            <span>{brandConfig.creditorLocation}</span>
          </div>
          <div>
            <span>
              {strings.companyRegistration}:{' '}
              {brandConfig.companyRegistrationNumber}
            </span>
          </div>
          <div>
            <span>
              {strings.phone}: {brandConfig.contactPhone}
            </span>
          </div>
          <div>
            <span>
              {strings.email}: {brandConfig.contactEmail}
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderClientContactDetails() {
    let client = {};
    if (this.state.invoiceDetails) {
      client = this.state.invoiceDetails.client;
    }
    return (
      <div style={sectionStyles}>
        <h3>
          <strong>{strings.debtor}</strong>
        </h3>

        <div className='patientContactDetails'>
          <div>
            <span>
              {client.firstName} {client.lastName}
            </span>
          </div>
          <div>
            <span>{client.address}</span>
          </div>
          <div>
            <span>
              {client.zip} {client.city} {client.district}
            </span>
          </div>
          <div>
            <span>
              {strings.ssno}: {client.cpr}
            </span>
          </div>
          <div>
            <span>
              {strings.phone}: {client.phone}
            </span>
          </div>
          <div>
            <span>
              {strings.email}: {client.email}
            </span>
          </div>
        </div>
      </div>
    );
  }

  displayPaymentStatus(payment) {
    const status = payment.status;

    switch (status) {
      case 'WAITING':
        if (moment(payment.paymentDate).isAfter()) {
          return '';
        }
        return strings.overdue;
      case 'REEPAY':
        if (moment(payment.paymentDate).isAfter()) {
          return '';
        }
        return strings.overdue;
      case 'DUNNING':
      case 'FAILED':
        return strings.overdue;
      case 'PAYED':
        return strings.paid;
        break;
      default:
        return strings[invoicesStatusMap[payment.status].statusText];
    }
  }

  renderPayments() {
    let invoice = {};
    if (this.state.invoiceDetails) {
      invoice = this.state.invoiceDetails;
    }
    let totalInterestAmount;
    let totalReepaymentsAmount;
    let totalPaymentsAmount;

    let totalInterestPaidOutAmount;
    let totalRepaymentsPaidOutAmount;
    let totalAmountsPaidOutAmount;

    let lastPaidOutPayments;
    let lastPaidOutPayment;

    if (invoice) {
      /* total for interests */
      const interestSum = _.sumBy(invoice.payments, (item) =>
        _.ceil(item.interest, 2)
      );

      totalInterestAmount = parseFloat(interestSum.toFixed(2));

      /* total for reepayment */
      const repaymentsSum = _.sumBy(invoice.payments, (item) =>
        _.ceil(item.repayments, 2)
      );
      totalReepaymentsAmount = parseFloat(repaymentsSum.toFixed(2));

      /* total for amount */
      const amountSum = _.sumBy(invoice.payments, (item) =>
        _.ceil(item.amount, 2)
      );
      totalPaymentsAmount = parseFloat(amountSum.toFixed(2));

      const interestPaidOutSum = _.sumBy(invoice.payments, (payment) =>
        payment.status === 'PAYED' ? _.ceil(payment.interest, 2) : 0
      );
      totalInterestPaidOutAmount = parseFloat(interestPaidOutSum.toFixed(2));

      /* total paid out reepayment */
      const repaymentsPaidOutSum = _.sumBy(invoice.payments, (payment) =>
        payment.status === 'PAYED' ? _.ceil(payment.repayments, 2) : 0
      );
      totalRepaymentsPaidOutAmount = parseFloat(
        repaymentsPaidOutSum.toFixed(2)
      );

      /* total paid out amount */
      const amountPaidOutSum = _.sumBy(invoice.payments, (payment) =>
        payment.status === 'PAYED' ? _.ceil(payment.amount, 2) : 0
      );
      totalAmountsPaidOutAmount = parseFloat(amountPaidOutSum.toFixed(2));

      lastPaidOutPayments = _.filter(invoice.payments, (payment) => {
        if (payment.status === 'PAYED') {
          return payment;
        }
      });
      lastPaidOutPayment =
        lastPaidOutPayments.length > 0 ? lastPaidOutPayments.pop() : null;
    }
    return (
      <div>
        <h2 style={subtitleStyles}>{strings.paymentSummary}</h2>
        <div style={tableLineHeading}>
          <span style={tableCellIndex}>#</span>
          <span style={tableCell}>{strings.date}</span>
          {this.props.currentAgreement && (
            <span style={tableCell}>{strings.paymentSummaryStatus}</span>
          )}
          <span style={tableCellLeftAlign}>{strings.paymentInterest}</span>
          <span style={tableCellLeftAlign}>{strings.paymentInstallment}</span>
          <span style={tableCellLeftAlign}>{strings.payment}</span>
        </div>
        {_.map(invoice.payments, (payment, index) => {
          return (
            <div key={index + 1} style={tableLine}>
              <span style={tableCellIndex}>{index + 1}</span>
              <span style={tableCell}>
                {moment(payment.paymentDate).format('DD.MM.YY')}
              </span>
              {this.props.currentAgreement && (
                <span style={tableCell} className='creditAmount'>
                  {this.displayPaymentStatus(payment)}
                </span>
              )}
              <span style={tableCellLeftAlign} className='establishmentFee'>
                {currencyFormatDA(payment.interest, false)}
              </span>
              <span style={tableCellLeftAlign} className='establishmentFee'>
                {currencyFormatDA(payment.repayments, false)}
              </span>
              <span style={tableCellLeftAlign} className='performance'>
                {currencyFormatDA(payment.amount, false)}
              </span>
            </div>
          );
        })}

        {/* TOTAL      */}
        <div style={tableLine}>
          <span style={tableCellIndex} />
          <span style={tableCell}>{strings.inTotal}</span>
          {this.props.currentAgreement && <span style={tableCell} />}
          <span style={tableCellLeftAlign}>
            {numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
              totalInterestAmount,
              false
            )}
          </span>
          <span style={tableCellLeftAlign}>
            {numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
              totalReepaymentsAmount,
              false
            )}
          </span>
          <span style={tableCellLeftAlign}>
            {numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
              totalPaymentsAmount
            )}
          </span>
        </div>

        <div style={tableLine}></div>

        {/* TOTAL PAID   */}
        {this.props.currentAgreement && (
          <>
            <div style={tableLine}>
              <span style={tableCellIndex} />
              <span style={tableCell}>{strings.paidInTotal}:</span>
              <span style={tableCell} />
              <span style={tableCellLeftAlign}>
                {numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
                  totalInterestPaidOutAmount,
                  false
                )}
              </span>
              <span style={tableCellLeftAlign}>
                {numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
                  totalRepaymentsPaidOutAmount,
                  false
                )}
              </span>
              <span style={tableCellLeftAlign}>
                {numberWithDotAsThousandsSeparatorsAndCommaAsDecimalSeparator(
                  totalAmountsPaidOutAmount,
                  false
                )}
              </span>
            </div>

            {/* // REMAINING DEBT  */}
            <div style={tableLine}>
              <span style={tableCellIndex} />
              <span style={tableCellColspan3}>
                {lastPaidOutPayment !== null
                  ? strings.formatString(
                      strings.remainingDebt,
                      moment(lastPaidOutPayment.paymentDate).format('DD.MM.YY')
                    )
                  : strings.formatString(
                      strings.remainingDebt,
                      moment(invoice.startDate).format('DD.MM.YY')
                    )}
              </span>
              <span style={tableCell} />
              <span style={tableCellLeftAlign}>
                {lastPaidOutPayment !== null
                  ? currencyFormatDA(lastPaidOutPayment.remainingBalance, false)
                  : currencyFormatDA(
                      invoice.amount + invoice?.establishmentFee || 0,
                      false
                    )}
              </span>
            </div>
          </>
        )}
      </div>
    );
  }

  renderFooterDetails() {
    let invoice = {};
    if (this.state.invoiceDetails) {
      invoice = this.state.invoiceDetails;
    }
    return (
      <div>
        <br /> <br />
        <p>{strings.important}:</p>
        <p>
          {strings.contractDetails1}
          <br />
          {strings.contractDetails2}
        </p>
        <ol>
          {invoice.interestRate !== 0 && <li>{strings.contractNote1}</li>}
          <li>{strings.contractNote2}</li>
          <li>
            {strings.contractNote3}
            <br />
            {strings.contractNote4}
          </li>
        </ol>
      </div>
    );
  }

  renderDone() {
    let patient = {};
    if (this.state.invoiceDetails) {
      patient = this.state.invoiceDetails.client;
    }
    const { invoiceDetails } = this.state;

    const duration = invoiceDetails ? invoiceDetails.duration : 0;
    const addPageBreak = this.shouldAddNewPage(duration);

    return (
      <div style={paymentPlanDocStyles}>
        <hr />
        <h1 style={titleStyles}>
          {strings.paymentAgreement}{' '}
          <b>
            {patient.firstName} {patient.lastName}
          </b>
          ({patient.cpr}) {strings.and} {brandConfig.creditorName}{' '}
          {'(37701149)'}
        </h1>
        <hr />
        <div className='contactDetailsWrapper cf'>
          {this.renderCreditDetails()}
          <hr />
          <div className='contactDetailsWrapper cf'>
            <h2 style={subtitleStyles}>
              <strong>{strings.contactDetails}</strong>
            </h2>
            <div style={contactDetailsStyles}>
              {InvoicePaymentPlan.renderCreditorContactDetails()}
            </div>
            <div style={contactDetailsStyles}>
              {this.renderClientContactDetails()}
            </div>
          </div>
          <br />
          <br />
          <hr />

          {this.renderPayments()}
          {/* Show the loan agreement only for admin users */}
          {!this.props.currentAgreement &&
            isAdmin(this.props.user) &&
            this.props.signatureData && (
              <>
                {addPageBreak && <div style={{ pageBreakBefore: 'always' }} />}
                <PaymentAgreementSignature
                  invoiceDetails={this.state.invoiceDetails}
                  signatureData={this.props.signatureData}
                />
              </>
            )}
          {this.renderFooterDetails()}
        </div>
      </div>
    );
  }

  render() {
    return this.renderDone();
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

InvoicePaymentPlan.propTypes = {
  invoiceDetails: PropTypes.object,
};

export default connect(mapStateToProps, {})(InvoicePaymentPlan);
