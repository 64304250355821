import { newInvoicesStatusMap } from '../../../constants/mappings';
import { momentFormatLong } from '../../../constants';
import { currencyFormatDA } from '../../../constants/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { strings } from '../../../constants/localization';
import DentiModal from '../../common/DentiModal';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line
import { openAcceptanceFlowModalFunction } from '../../../actions/memberPortalActions';

interface BillingHistoryListItemProps {
  createdDate: string;
  invoiceAmount: number;
  companyName: string;
  status: string;
  interestRate?: number;
  setVisibleServiceModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface installmentLoadDataProps {
  creditFacilityData: {
    installmentLoanDataList: [
      {
        invoiceUuid: string;
        status: string;
        expiredDate: string;
      }
    ];
  };
}

const BillingHistoryListItem: React.FC<BillingHistoryListItemProps> = ({
  createdDate,
  invoiceAmount,
  companyName,
  status,
  interestRate,
  setVisibleServiceModal,
}) => {
  const modalAcceptanceFlow = useSelector(
    (state: {
      memberPortal: {
        openAcceptanceFlowModal: boolean;
      };
    }) => state.memberPortal.openAcceptanceFlowModal
  );

  const clientCreditInformation = useSelector(
    (state: {
      memberPortal: { clientDataInformation: installmentLoadDataProps };
    }) => state.memberPortal?.clientDataInformation
  );
  const filterWaitingForSignatureList =
    clientCreditInformation?.creditFacilityData.installmentLoanDataList.filter(
      (agreement) => agreement.status === 'WAITING_FOR_SIGNATURE'
    );

  const [invoiceId, setInvoiceId] = useState<string>('');
  const [openAcceptanceFlowModal, setOpenAcceptanceFlowModal] =
    useState(modalAcceptanceFlow);
  const dispatch = useDispatch();

  const { userAgent } = window.navigator;

  // Set device to "mobile" if "Mobi" exists in userAgent else set device to "desktop"
  const device = userAgent.includes('Mobi') ? 'mobile' : 'desktop';
  const currentUrl =
    window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : `https://${process.env.REACT_APP_DAM_URL}`;

  const paidOutDate = '';
  const shownStatus = (() => {
    if (status === 'WAITING_FOR_SIGNATURE') {
      const expiredDate = filterWaitingForSignatureList[0]?.expiredDate;
      if (expiredDate && moment(expiredDate).isBefore(moment())) {
        return 'EXPIRED';
      }
      return 'PENDING';
    }
    switch (status) {
      case 'WAITING_FOR_SIGNATURE':
        return 'PENDING';
      case 'ACCEPTED':
        if (!paidOutDate) {
          if (interestRate === 0) {
            return 'APPROVED_ZERO_INTEREST';
          }
          return 'APPROVED';
        }
        if (paidOutDate) {
          if (interestRate === 0) {
            return 'PAYED_OUT_ZERO_INTEREST';
          }
          return 'PAYED_OUT';
        }
        break;
      case 'PAYED':
        if (paidOutDate) {
          if (interestRate === 0) {
            return 'PAYED_OUT_ZERO_INTEREST';
          }
          return 'PAYED_OUT';
        }
        break;
      case 'CONSOLIDATED':
      case 'RESTRUCTURED':
      case 'LATE_PAYMENT':
      case 'SETTLED':
        if (!paidOutDate) {
          return 'APPROVED';
        }
        if (paidOutDate) {
          return 'PAYED_OUT';
        }
        break;
      case 'NOT_ACCEPTED':
        return 'DECLINED';
      default:
        return 'UNDEFINED';
    }
    return 'UNDEFINED';
  })();

  useEffect(() => {
    if (filterWaitingForSignatureList) {
      setInvoiceId(filterWaitingForSignatureList[0]?.invoiceUuid);
    }
  }, [filterWaitingForSignatureList]);

  const handleClick = () => {
    if (status === 'WAITING_FOR_SIGNATURE') {
      const url = `${currentUrl}/acceptLoan?id=${invoiceId}&source=portal&device=${device}`;

      if (device === 'mobile') {
        // Open the URL in a new tab for screens smaller than 600px
        window.open(url, '_blank');
      } else {
        // Open the modal for larger screens
        setOpenAcceptanceFlowModal(!openAcceptanceFlowModal);
      }
    }
  };

  useEffect(() => {
    if (openAcceptanceFlowModal) {
      dispatch(openAcceptanceFlowModalFunction(true));
      setVisibleServiceModal(true);
    } else {
      dispatch(openAcceptanceFlowModalFunction(false));
      setVisibleServiceModal(false);
    }
  }, [openAcceptanceFlowModal]);

  const expiredDate = filterWaitingForSignatureList[0]?.expiredDate;
  const isExpired = moment(expiredDate).isBefore(moment());

  return (
    <>
      <div
        id='billing-list-item'
        className={`table-row-div ${
          status === 'WAITING_FOR_SIGNATURE' && !isExpired ? 'clickable' : ''
        }`}
        onClick={!isExpired ? handleClick : null}
      >
        <div className='table-cell-div'>
          {moment(createdDate).format(momentFormatLong).toLowerCase()}
        </div>
        <div className='table-cell-div'>{companyName}</div>
        <div
          className={`table-cell-div ${
            newInvoicesStatusMap[shownStatus] !== undefined
              ? newInvoicesStatusMap[shownStatus].className
              : ``
          }`}
        >
          {strings[newInvoicesStatusMap[shownStatus].statusText]}
        </div>
        <div className='table-cell-div'>
          {invoiceAmount && currencyFormatDA(invoiceAmount, true, true)}
        </div>
      </div>
      {openAcceptanceFlowModal && (
        <DentiModal
          modalClass='modal-dialog-scrollable modal-dialog-centered'
          modalClassName='acceptance-iframe-modal'
          close={() => {
            setOpenAcceptanceFlowModal(false);
            window.location.reload();
          }}
          closeBtn={true}
        >
          <iframe
            id='acceptance-iframe'
            title='AcceptanceFlow'
            src={`${currentUrl}/acceptLoan?id=${invoiceId}&source=portal&device=${device}`}
            // width='530px'
            // height='700px'
            scrolling='no'
            style={{
              border: 'none',
              overflow: 'hidden !important',
              width: '100%',
              minHeight: '780px',
              padding: '0',
              margin: '0',
            }}
            onLoad={() => {
              const iframe = document.getElementById(
                'acceptance-iframe'
              ) as HTMLIFrameElement;
              iframe.contentWindow?.postMessage(
                { openAcceptanceFlowModal },
                '*'
              );
            }}
          />
        </DentiModal>
      )}
    </>
  );
};

export default BillingHistoryListItem;
