import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Button, Label } from 'reactstrap';
import Slider from 'rc-slider';
import { moment } from '../../constants';
import { strings } from '../../constants/localization';
import {
  formatCurrencyDaStandard,
  paymentListMoment,
  paymentListMomentRestructureLoans,
} from '../../constants/utils';
import 'rc-slider/assets/index.css';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ErrorPage from '../common/ErrorPage';
import { creditMinDurationMin, creditMaxDuration } from '../../config';
import { AcceptanceLoanData, LoanDataOutput } from '../interfaces';

interface StepTwoProps {
  acceptLoan: AcceptanceLoanData;
  nextStep: (value: number | boolean | object | null) => void;
  previousStep: () => void;
  rejectLoan: () => void;
  updateProcessData: (data: LoanDataOutput) => void;
  setSkipSliderStep: (skip: boolean) => void;
}

interface PaymentDetail {
  monthlyPayment: number;
  apr: number;
  firstPaymentDate?: string;
  lastPaymentDate?: string;
  duration?: number;
  extraPayment?: number;
}

const StepTwo: React.FC<StepTwoProps> = ({
  acceptLoan,
  nextStep,
  previousStep,
  rejectLoan,
  updateProcessData,
  setSkipSliderStep,
}) => {
  const brandEnvVariable = process.env.REACT_APP_BRAND;
  const [loanDetails, setLoanDetails] =
    useState<AcceptanceLoanData>(acceptLoan);
  const minDuration = loanDetails.minimumDuration ?? creditMinDurationMin;
  const maxDuration = loanDetails.interestRate > 0 ? creditMaxDuration : 24;
  const [duration, setDuration] = useState(
    acceptLoan.restructured
      ? acceptLoan.defaultDuration
      : acceptLoan.minimumDuration
  );
  const [reepayInvoiceSettled, setReepayInvoiceSettled] = useState(
    acceptLoan.reepayInvoiceSettled
  );
  const [reepayInvoiceDeleted, setReepayInvoiceDeleted] = useState(
    acceptLoan.restructured && acceptLoan.status === 'DELETED'
  );

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetail>({
    monthlyPayment: 0,
    apr: 0,
  });
  const [totalCreditCost, setTotalCreditCost] = useState(0);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [restructuredLoan] = useState(acceptLoan.restructured);
  const [skipStep, setSkipStep] = useState(false);
  const firstPaymentDate = moment()
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastPaymentDate = moment(firstPaymentDate)
    .add(duration, 'months')
    .format('DD.MM.YYYY');

  const handlePayments = (
    firstPaymentDate: string,
    interestRate: string | number,
    loanAmount: number,
    rangeDuration: number,
    establishmentFee: number
  ) => {
    const paymentResults = !restructuredLoan
      ? paymentListMoment(
          firstPaymentDate,
          parseFloat(interestRate.toString()),
          loanAmount,
          rangeDuration,
          parseFloat(establishmentFee.toString())
        )
      : paymentListMomentRestructureLoans(
          firstPaymentDate,
          parseFloat(interestRate.toString()),
          loanAmount + establishmentFee,
          rangeDuration
        );
    setPaymentDetails({
      monthlyPayment: paymentResults.monthlyPayment,
      apr: paymentResults.apr,
      firstPaymentDate: firstPaymentDate,
      lastPaymentDate: lastPaymentDate,
      duration: rangeDuration,
      extraPayment: establishmentFee,
    });
  };

  const handleMonthlyPayment = (rangeDuration) => {
    let debouncedFn;

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        handlePayments(
          loanDetails.firstPaymentDate,
          loanDetails.interestRate,
          loanDetails.loanAmount + loanDetails.remainingBalanceOnExistingLoans,
          rangeDuration,
          loanDetails.establishmentFee
        );
      }, 10);
    }

    debouncedFn();
  };

  // add class name for custom styling on slider step
  useEffect(() => {
    const flowContainer = document.querySelector('.flow-container');
    if (flowContainer) {
      flowContainer.classList.add('acceptance-slider-step');
    }
    return () => {
      if (flowContainer) {
        flowContainer.classList.remove('acceptance-slider-step');
      }
    };
  }, []);

  useEffect(() => {
    const maxAcceptedLoanPeriod =
      acceptLoan.interestRate > 0 ? creditMaxDuration : 24;
    if (acceptLoan !== undefined) {
      setLoanDetails(acceptLoan);
      const minimumUsedDuration = Math.max(
        acceptLoan.minimumDuration,
        acceptLoan.defaultDuration
      );

      // show starting mark for resturtured loans and normal loans
      if (loanDetails.restructured) {
        if (
          loanDetails.defaultDuration >= loanDetails.minimumDuration &&
          loanDetails.defaultDuration <= maxAcceptedLoanPeriod
        ) {
          setDuration(loanDetails.defaultDuration);
        }
      } else {
        setDuration(minimumUsedDuration);
      }
      setReepayInvoiceSettled(acceptLoan.reepayInvoiceSettled);
      setReepayInvoiceDeleted(
        acceptLoan.restructured && acceptLoan.status === 'DELETED'
      );
      setSkipStep(
        acceptLoan?.restructured &&
          acceptLoan.reepayInvoiceSettled &&
          acceptLoan?.durationLocked
      );

      if (acceptLoan.firstPaymentDate !== undefined) {
        handlePayments(
          acceptLoan.firstPaymentDate,
          acceptLoan.interestRate,
          acceptLoan.loanAmount + acceptLoan.remainingBalanceOnExistingLoans,
          minimumUsedDuration,
          acceptLoan.establishmentFee
        );
      }
    }
    // eslint-disable-next-line
  }, [acceptLoan]);

  useEffect(() => {
    const totalRepay = duration * paymentDetails.monthlyPayment;

    setTotalRepayment(totalRepay);

    const totalCost =
      totalRepay -
      loanDetails.loanAmount -
      loanDetails.remainingBalanceOnExistingLoans;
    setTotalCreditCost(totalCost);
    // eslint-disable-next-line
  }, [duration, paymentDetails.monthlyPayment]);

  const continueStep = () => {
    const processData = {
      loanAmount:
        loanDetails.loanAmount + loanDetails.remainingBalanceOnExistingLoans,
      duration,
      setupFee: loanDetails.establishmentFee,
      interestRate: loanDetails.interestRate,
      apr: paymentDetails.apr,
      totalCreditCost,
      totalRepayment,
      dateEstablishmentFee: moment().format('DD-MM-YYYY'),
      monthlyPayment: paymentDetails.monthlyPayment,
      firstInstallmentDate: moment(
        loanDetails.firstPaymentDate,
        'YYYY-MM-DD'
      ).format('DD-MM-YYYY'),
      lastInstallmentDate: moment(loanDetails.firstPaymentDate, 'YYYY-MM-DD')
        .add(duration - 1, 'months')
        .format('DD-MM-YYYY'),
    };
    updateProcessData(processData);
    nextStep({
      duration: duration,
    });
  };

  const rejectStep = () => {
    rejectLoan();
  };

  const onSliderChange = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;

    setDuration(newValue);
    handleMonthlyPayment(newValue);
  };

  let renderSituation;

  if (acceptLoan.restructured && !reepayInvoiceSettled) {
    // reepay invoice not settled, then show loading payment message
    if (reepayInvoiceDeleted) {
      // error page, couldn't settle the invoice
      renderSituation = (
        <ErrorPage>
          <p>{strings.reepayInvoiceSettledError}</p>
        </ErrorPage>
      );
    } else {
      renderSituation = (
        <div className='m-auto'>
          <LoadingMessage message={strings.reepayInvoiceSettleMessage} />
        </div>
      );
    }
  } else if (skipStep) {
    // skip slider step
    setSkipSliderStep(true);
    continueStep();
    return null;
  } else {
    renderSituation = (
      <>
        <div id='modal-root' />
        <div className='step-content step-two'>
          <h1 className='step-title'>
            {strings.partialRedemptionAdjustMonthlyPaymentTitle}
          </h1>
          <div className='subtitle'>
            <p>
              {strings.formatString(
                strings.acceptLoanStep2Headline,
                maxDuration
              )}
            </p>
          </div>
          <Label className='input-label center'>
            {strings.partialRedemptionAdjustMonthlyPaymentInputLabel}
          </Label>
          <div className='slider-container'>
            <div className='container-content'>
              <div className='amount-info'>
                {formatCurrencyDaStandard(paymentDetails?.monthlyPayment)}
              </div>

              <div className='slider'>
                <Slider
                  // tipFormatter={percentFormatter}
                  value={duration}
                  step={1}
                  min={minDuration}
                  max={maxDuration}
                  onChange={(value) => onSliderChange(value)}
                  dots={false}
                  styles={{
                    rail: {
                      backgroundColor: `${
                        brandEnvVariable === 'Denti' ? '#339ca6' : '#E2DBD9'
                      }`,
                      height: 10,
                      borderColor: '#ffffff',
                      borderWidth: 2,
                      borderStyle: 'solid',
                    },
                    handle: {
                      backgroundColor: `${
                        brandEnvVariable === 'Denti' ? '#FFF' : '#D8BD98'
                      }`,
                      borderColor: '#ffffff',
                      height: 36,
                      width: 36,
                      borderWidth: 3,
                      marginTop: -12,
                      zIndex: 2999,
                      opacity: 1,
                    },
                    track: {
                      backgroundColor: `${
                        brandEnvVariable === 'Denti' ? '#e1dede' : '#D8BD98'
                      }`,
                      height: 10,
                      borderColor: `${
                        brandEnvVariable === 'Denti' ? '#f8f8f8' : '#ffffff'
                      }`,
                      borderWidth: 2,
                      borderStyle: 'solid',
                    },
                  }}
                />
              </div>
              <div className='slider-information'>
                {strings.partialRedemptionAdjustMonthlyPaymentSliderInformation}
              </div>
            </div>
          </div>
          <div className='payment-info'>
            <Label>
              {strings.partialRedemptionAdjustMonthlyPaymentPeriodMonths}
            </Label>
            <span>
              {strings.formatString(
                strings.partialRemptionPaymentDuration,
                duration
              )}
            </span>
          </div>
          <div className='payment-info'>
            <Label>
              {strings.partialRedemptionAdjustMonthlyPaymentLastPaymentDate}
            </Label>
            <span>{lastPaymentDate}</span>
          </div>
          {(acceptLoan?.remainingBalanceOnExistingLoans > 0 ||
            acceptLoan?.restructured === true) && (
            <div className='yellow-card'>
              {`${strings.formatString(
                strings.acceptLoanStep2Note,
                duration
              )} ${strings.formatString(
                strings.acceptLoanStep2NoteAdditional,
                formatCurrencyDaStandard(
                  acceptLoan.restructured
                    ? acceptLoan.loanAmount + acceptLoan.extraPayment || 0
                    : acceptLoan?.remainingBalanceOnExistingLoans
                ) as string | number
              )}`}
            </div>
          )}

          <div className='step-actions'>
            <Button
              color='light'
              className='reject'
              onClick={() =>
                acceptLoan.restructured ? rejectStep() : previousStep()
              }
            >
              {acceptLoan.restructured ? strings.declineLoan : strings.goBack}
            </Button>
            <Button
              color='blue'
              className='continue'
              onClick={() => continueStep()}
            >
              {strings.continueStep}
            </Button>
          </div>
        </div>
      </>
    );
  }
  return renderSituation;
};

export default StepTwo;
