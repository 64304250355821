import { momentFormatLong } from '../../../constants';
import { currencyFormatDA, formatInterestRate } from '../../../constants/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DentiModal from '../../common/DentiModal';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line
import { getClientPaymentPlan } from '../../../actions/memberPortalActions';
import LoanDetails from '../../invoices/LoanDetails';
import { strings } from '../../../constants/localization';
interface PaymentAgreementsListItemProps {
  setVisibleServiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  createdDate: string;
  montlhyPayment: number;
  interestRate: number;
  remainingBalance: number;
  loanAmount: number;
  loanNumber: string;
}

const PaymentAgreementsListItem: React.FC<PaymentAgreementsListItemProps> = ({
  createdDate,
  montlhyPayment,
  interestRate,
  remainingBalance,
  loanAmount,
  loanNumber,
  setVisibleServiceModal,
}) => {
  const [loanPlanModal, setLoanPlanModal] = useState<boolean>(false);
  const [loanPlan, setLoanPlan] = useState<object>();
  const dispatch = useDispatch();

  const openLoanDetailsModal = () => {
    setLoanPlanModal(!loanPlanModal);

    dispatch(getClientPaymentPlan(loanNumber)).then((response) => {
      setLoanPlan(response.payload.data);
    });
  };

  useEffect(() => {
    setVisibleServiceModal(loanPlanModal);
  }, [loanPlanModal]);

  return (
    <>
      <div
        className='table-row-div'
        // removed loan-list-item className while we can´t click at the item for the first release version
        id='list-group-item'
        onClick={() => openLoanDetailsModal()}
      >
        <div className='table-cell-div'>
          {moment(createdDate).format(momentFormatLong).toLowerCase()}
        </div>

        <div className='table-cell-div '>
          {montlhyPayment && currencyFormatDA(montlhyPayment, true, true)}
        </div>
        <div className='table-cell-div'>{formatInterestRate(interestRate)}</div>
        <div className='table-cell-div'>
          {remainingBalance && currencyFormatDA(remainingBalance, true, true)}
        </div>
        <div className='table-cell-div'>
          {loanAmount && currencyFormatDA(loanAmount, true, true)}
        </div>

        <div
          className='table-cell-div'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='actions-menu'>
            <div
              className='loan-details-menu'
              title={strings.mitCreditAgreementKebabMenuOptionShowDetails}
              onClick={openLoanDetailsModal}
            >
              <span className='icon'>
                <i className='lnil lnil-search-alt ' />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Loan details modal */}
      {loanPlanModal && Object.keys(loanPlan || {}).length > 0 && (
        <DentiModal
          key={2}
          close={() => setLoanPlanModal(false)}
          modalClass='loan-details-modal modal-dialog-scrollable modal-dialog-centered'
          modalClassName='loan-details-action'
          // closeBtn
          footerCloseBtn
          footerCloseBtnText={strings.loanDetailsClose}
        >
          <LoanDetails
            loanNumber={loanNumber}
            loanDetails={loanPlan}
            isClientPortal={true}
          />
        </DentiModal>
      )}
    </>
  );
};

export default PaymentAgreementsListItem;
