import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'reactstrap';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import { ReactComponent as InformationIcon } from '../../../resources/icons/info-circle.svg';
import { strings } from '../../../constants/localization';
import { formatCurrencyDaStandard } from '../../../constants/utils';
import { useDispatch } from 'react-redux';
import { restructureLoan } from '../../../actions/memberPortalActions';
import { ClientPortalNewPaymentDetailProps } from 'components/interfaces';
import { Sundhedplus } from 'config';

interface RestructureProcessingProps {
  newPaymentDetails: ClientPortalNewPaymentDetailProps;
  serviceFee: number;
  startDate?: string;
  closeModal?: () => void;
  hasRedirect?: boolean;
}

const RestructureProcessing: React.FC<RestructureProcessingProps> = ({
  newPaymentDetails,
  serviceFee,
  startDate,
  closeModal,
  hasRedirect,
}) => {
  const [status, setStatus] = useState<'loading' | 'failed' | 'success'>(
    'loading'
  );

  const dispatch = useDispatch();
  const hasDispatched = useRef(false);
  const { duration, monthlyPayment, remainingBalance } =
    newPaymentDetails || {};

  useEffect(() => {
    if (!hasDispatched.current) {
      setStatus('loading');
      const loanDetails = {
        duration: duration,
        setupFee: serviceFee,
        startDate: startDate,
      };
      dispatch(restructureLoan(loanDetails))
        .then((response) => {
          if (response.status === 200) {
            setStatus('success');
          } else {
            setStatus('failed');
          }
        })
        .catch((e) => {
          console.log('Error', e);
          setStatus('failed');
        });
      hasDispatched.current = true;
    }
  }, [dispatch]);

  const handleOkButton = () => {
    if (hasRedirect) {
      window.close();
    } else {
      closeModal && closeModal();
      window.location.reload();
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return (
          <div className='step-content payment-processing'>
            <h1 className='step-title center'>
              {strings.restructureLoanProcessingLoadingTitle}
            </h1>
            <p className='subtitle'>
              {strings.partialRedemptionPaymentProcessingLoadingSubtitle}
            </p>
            <div className='loading-container'>
              <LoadingMessage />
            </div>
          </div>
        );

      case 'failed':
        return (
          <div className='step-content payment-processing'>
            <div className='content-information'>
              <div className='icon-container'>
                <InformationIcon className='failed-icon' />
                <h1 className='step-title center'>
                  {strings.restructureLoanProcessingFailedTitle}
                </h1>
              </div>
              <p className='subtitle'>
                {strings.restructureLoanProcessingFailedSubtitle}
              </p>
              <p className='subtitle'>
                {strings.formatString(
                  strings.restructureLoanProcessingFailedSubtitle2,
                  <a href={`mailto:${Sundhedplus.contactEmail}`}>
                    {Sundhedplus.contactEmail}
                  </a>,
                  <a href={`tel:${Sundhedplus.contactPhone}`}>
                    {Sundhedplus.contactPhoneHtmlFormated}
                  </a>
                )}
              </p>
            </div>
            <div className='button-container'>
              <Button color='blue' onClick={handleOkButton}>
                {strings.restructureLoanProcessingFailedButton}
              </Button>
            </div>
          </div>
        );

      case 'success':
        return (
          <div className='step-content payment-processing'>
            <div className='content-information'>
              <div className='icon-container'>
                <i className='lnil lnil-checkmark-circle icon' />
              </div>
              <h1 className='step-title center'>
                {strings.restructureLoanProcessingSuccessTitle}
              </h1>

              <p className='subtitle sucess-message'>
                {strings.formatString(
                  strings.restructureLoanProcessingSuccessSubtitle,
                  duration.toString(),
                  formatCurrencyDaStandard(
                    parseFloat(monthlyPayment?.toFixed(2))
                  )
                )}
              </p>

              <p className='subtitle sucess-message'>
                {strings.formatString(
                  strings.restructureLoanProcessingSuccessSubtitle2,
                  formatCurrencyDaStandard(
                    parseFloat(monthlyPayment?.toFixed(2))
                  )
                )}
              </p>

              <p className='subtitle sucess-message'>
                {strings.formatString(
                  strings.restructureLoanProcessingSuccessSubtitle3,
                  formatCurrencyDaStandard(serviceFee),
                  formatCurrencyDaStandard(
                    parseFloat(remainingBalance?.toFixed(2)) + serviceFee
                  )
                )}
              </p>
            </div>
            <div className='button-container'>
              <Button color='blue' onClick={handleOkButton}>
                {strings.partialRedemptionPaymentProcessingSuccessButton}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default RestructureProcessing;
