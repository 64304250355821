import React from 'react';
import { Button } from 'reactstrap';
interface ServicesActionEntryProps {
    headline: string;
    description: string | React.ReactNode;
    buttonText: string;
    buttonCallback: () => void;
    feeText: string | string[];
}

const ServicesActionEntry: React.FC<ServicesActionEntryProps> = ({
    headline,
    description,
    buttonText,
    buttonCallback,
    feeText
}) => {
    return (
        <div className='services-action'>
            <div className='left-side'>
                <h6>
                    {headline}
                </h6>
                <p>
                    {description}
                </p>
            </div>
            <div className='right-side'>
                <Button
                    className='btn btn-blue btn-success'
                    onClick={() => buttonCallback()}
                >
                    {buttonText}
                </Button>
                <div className='service-fee'><span>{feeText}</span></div>
            </div>
        </div>
    );
};

export default ServicesActionEntry;
