import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { formatDanishWithoutFractionDigits } from '../../../constants/utils';
// eslint-disable-next-line
import ServicesActionEntry from './ServicesActionEntry';
// eslint-disable-next-line
import { useServiceActionContext } from './ServiceActionProvider';
import {
  portalServiceFeeCustomizePayment,
  portalServiceFeeFreeMonth,
} from '../../../config';

const ServicesActions: React.FC = () => {
  const {
    openPartialPaymentModal,
    openSettleLoanModal,
    openRestructureLoanModal,
    openFreeMonthModal,
  } = useServiceActionContext();

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const formattedNextMonth = new Intl.DateTimeFormat(
    strings.getLanguage() === 'en' ? 'en-US' : 'da-DK',
    { month: 'long' }
  ).format(nextMonth);

  return (
    <Card className='custom-card'>
      <CardBody className='credit-info-card'>
        <div className='title'>{strings.mitServicesSectionTitle}</div>
        <ServicesActionEntry
          headline={strings.mitServicesSectionPartialRedemptionHeadline}
          description={strings.mitServicesSectionPartialRedemptionDescription}
          buttonText={strings.mitServicesSectionPartialRedemptionButton}
          buttonCallback={openPartialPaymentModal}
          feeText={strings.formatString(
            strings.mitServicesSectionFee,
            formatDanishWithoutFractionDigits(0)
          )}
        />

        <ServicesActionEntry
          headline={strings.mitServicesSectionCustomizePaymentHeadline}
          description={strings.formatString(
            strings.mitServicesSectionCustomizePaymentDescription,
            <a onClick={openRestructureLoanModal}>
              {strings.mitServicesSectionCustomizePaymentDescriptionLink}
            </a>
          )}
          buttonText={strings.mitServicesSectionCustomizePaymentButton}
          buttonCallback={openRestructureLoanModal}
          feeText={strings.formatString(
            strings.mitServicesSectionFee,
            formatDanishWithoutFractionDigits(portalServiceFeeCustomizePayment)
          )}
        />

        <ServicesActionEntry
          headline={strings.mitServicesSectionFreeMonthHeadline}
          description={strings.formatString(
            strings.mitServicesSectionFreeMonthDescription,
            formattedNextMonth
          )}
          buttonText={strings.mitServicesSectionFreeMonthButton}
          buttonCallback={openFreeMonthModal}
          feeText={strings.formatString(
            strings.mitServicesSectionFee,
            formatDanishWithoutFractionDigits(portalServiceFeeFreeMonth)
          )}
        />

        <ServicesActionEntry
          headline={strings.mitServicesSectionSettleHeadline}
          description={strings.mitServicesSectionSettleDescription}
          buttonText={strings.mitServicesSectionSettleButton}
          buttonCallback={openSettleLoanModal}
          feeText={strings.formatString(
            strings.mitServicesSectionFee,
            formatDanishWithoutFractionDigits(0)
          )}
        />
      </CardBody>
    </Card>
  );
};

export default ServicesActions;
