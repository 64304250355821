import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Button, Row, Col } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { brandConfig } from '../../config';
import { strings } from '../../constants/localization';
import { ROUTE_MAIN_LOGIN } from '../../constants/routes';
import { resetPassword, changePassword } from '../../actions/userActions';
import { toggleServerModalError } from '../../actions/uiElementsActions';
import { emailValidation } from '../../constants/utils';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ServerModalError from '../common/ServerModalError';
import ErrorPage from '../common/ErrorPage';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const serverError = useSelector((state) => state.ui.serverError);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isResetLinkValid, setIsResetLinkValid] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(0); // 0 - not submitted, 1 - success, 2 - error
  const [focusedField, setFocusedField] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [username, setUsername] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [isMinLengthValid, setMinLengthValid] = useState(false);
  const [isUppercaseValid, setUppercaseValid] = useState(false);
  const [isLowercaseValid, setLowercaseValid] = useState(false);
  const [isNumberValid, setNumberValid] = useState(false);
  let formClassName = '';

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      repeatPassword: '',
    },
    validateOnMount: true,
    validate: (values) => {
      const errors = {};
      const { newPassword, repeatPassword } = values;

      if (!newPassword) {
        errors.newPassword = strings.required;
      } else {
        // Password validation criteria
        const minLengthRegex = /^.{8,}$/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;

        setMinLengthValid(minLengthRegex.test(newPassword));
        setUppercaseValid(uppercaseRegex.test(newPassword));
        setLowercaseValid(lowercaseRegex.test(newPassword));
        setNumberValid(digitRegex.test(newPassword));

        if (
          !minLengthRegex.test(newPassword) ||
          !uppercaseRegex.test(newPassword) ||
          !lowercaseRegex.test(newPassword) ||
          !digitRegex.test(newPassword)
        ) {
          errors.newPassword = strings.formatString(
            strings.passwordStrength,
            '8'
          );
        }
      }

      if (!repeatPassword) {
        errors.repeatPassword = strings.required;
      } else if (repeatPassword !== newPassword) {
        errors.repeatPassword = strings.passwordDontMatch;
      }

      return errors;
    },
    onSubmit: (values) => {
      setLoadingSubmit(true);
      dispatch(
        resetPassword({
          uuid,
          username,
          password: values.repeatPassword,
        })
      )
        .then(() => {
          setSubmitStatus(1);
        })
        .catch(() => {
          setSubmitStatus(2);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    },
  });

  // check if the reset link is still valid by making a request with the empty password when the component mounts
  useEffect(() => {
    document.body.classList.add('public-content');
    const link = window.location.href;
    const url = new URL(link);
    const uuid = url.searchParams.get('u');
    const username = url.searchParams.get('username');

    dispatch(
      resetPassword({
        uuid,
        username,
        password: '',
      })
    ).catch((e) => {
      dispatch(toggleServerModalError(false));
      if (
        e.response.data?.cause ===
        'Bad Request: Password does not meet minimum requirements'
      ) {
        setIsResetLinkValid(true);
      } else {
        //cause: "Bad Request: User is not found"
        setIsResetLinkValid(false);
      }
      setLoading(false);
    });

    setUuid(uuid);
    setUsername(username);

    // validate if the email is valid
    // returns the error message if not, otherwise returns empty
    if (emailValidation(username)) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }

    return () => {
      document.body.classList.remove('public-content');
    };
  }, []);

  // detect if the user are using a IOS device then prevent the zoom in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const sendNewLink = () => {
    if (username) {
      dispatch(changePassword(username))
        .then(() => {
          setSubmitStatus(1);
          history.push({
            pathname: ROUTE_MAIN_LOGIN,
            sendMessage: true,
          });
        })
        .catch(() => {
          setSubmitStatus(2);
        });
    } else {
      setSubmitStatus(2);
    }
  };

  const renderFormText = () => {
    switch (submitStatus) {
      case 1:
        formClassName = 'success';
        return (
          <div>
            <div className='logo'>
              <h1>{strings.successTitle}</h1>
            </div>
            <div className='reset-headline'>{strings.sucessMessage}</div>
          </div>
        );
      case 2:
        formClassName = 'error';
        return (
          <div>
            <div className='logo'>
              <h1>{strings.errorTitle}</h1>
            </div>
            <div className='reset-headline'>{strings.errorMessage}</div>
          </div>
        );
      default:
        return (
          <div>
            <div className='logo'>
              <h1>{strings.title}</h1>
            </div>
            <div className='reset-headline'>{strings.headline}</div>
          </div>
        );
    }
  };

  const renderCriteriaValidationIcon = (isValid) => {
    return isValid ? (
      <i className='lnir lnir-checkmark-circle' />
    ) : (
      <i className='lnir lnir-circle-minus' />
    );
  };

  const renderForm = () => {
    return (
      <div id='old-login' className='user-login reset-password'>
        <div className='form-wrapper'>
          {renderFormText()}
          <form
            onSubmit={formik.handleSubmit}
            id='resetPassword'
            className={`cf ${formClassName}`}
          >
            {submitStatus === 0 ? (
              <>
                {/* NEW PASSWORD INPUT */}
                <div
                  className={`form-group newPassword ${
                    focusedField === 'newPassword' ? 'active' : ''
                  } ${
                    formik.touched.newPassword && formik.errors.newPassword
                      ? 'has-error'
                      : ''
                  }`}
                >
                  <span className='di di-password' />
                  <label htmlFor='newPassword'>{strings.newPassword}</label>
                  <input
                    type='password'
                    id='newPassword'
                    className={`form-group ${
                      formik.touched.newPassword ? 'has-error' : ''
                    }`}
                    name='newPassword'
                    placeholder={strings.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus('newPassword')}
                    value={formik.values.newPassword}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className='text-error'>
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>

                {/* REPEAT PASSWORD INPUT */}
                <div
                  className={`form-group repeatPassword ${
                    focusedField === 'repeatPassword' ? 'active' : ''
                  } ${
                    formik.touched.repeatPassword &&
                    formik.errors.repeatPassword
                      ? 'has-error'
                      : ''
                  }`}
                >
                  <span className='di di-password' />
                  <label htmlFor='repeatPassword'>
                    {strings.repeatPassword}
                  </label>
                  <input
                    type='password'
                    id='repeatPassword'
                    name='repeatPassword'
                    placeholder={strings.repeatPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus('repeatPassword')}
                    value={formik.values.repeatPassword}
                  />
                  {formik.touched.repeatPassword &&
                  formik.errors.repeatPassword ? (
                    <div className='text-error'>
                      {formik.errors.repeatPassword}
                    </div>
                  ) : null}
                </div>

                {/* VALIDATION CRITERIA MESSAGES */}

                <Row className='resetPasswordValidation'>
                  <Col sm='6' className={isMinLengthValid ? 'valid' : ''}>
                    {renderCriteriaValidationIcon(isMinLengthValid)}
                    {strings.userRegistrationPasswordRequermets1}
                  </Col>
                  <Col sm='6' className={isUppercaseValid ? 'valid' : ''}>
                    {renderCriteriaValidationIcon(isUppercaseValid)}
                    {strings.userRegistrationPasswordRequermets2}
                  </Col>
                </Row>
                <Row className='resetPasswordValidation'>
                  <Col sm='6' className={isLowercaseValid ? 'valid' : ''}>
                    {renderCriteriaValidationIcon(isLowercaseValid)}
                    {strings.userRegistrationPasswordRequermets3}
                  </Col>
                  <Col sm='6' className={isNumberValid ? 'valid' : ''}>
                    {renderCriteriaValidationIcon(isNumberValid)}
                    {strings.userRegistrationPasswordRequermets4}
                  </Col>
                </Row>
                <button
                  type='submit'
                  className={`${!formik.isValid ? 'not-modified' : ''}`}
                >
                  {loadingSubmit ? (
                    <ButtonLoader />
                  ) : (
                    strings.changePasswordButton
                  )}
                </button>
              </>
            ) : (
              <Link to={ROUTE_MAIN_LOGIN} className='login-button btn btn-blue'>
                {strings.goToLogin}
              </Link>
            )}
          </form>
          {serverError && <ServerModalError />}
          <div className='login-bottom'>
            <div className='help'>
              <p className='login-help-text'>{strings.loginHelp}</p>
              <p className='login-help-number'>
                {brandConfig.contactPhoneHtmlFormated}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInvalidLink = () => {
    return (
      <ErrorPage>
        <h1>{strings.resetLinkExpiredTitle}</h1>
        <p className='small-margin-bottom'>
          {strings.resetLinkExpiredSubtitle}
        </p>
        <ul>
          <li>{strings.resetLinkExpiredReason1}</li>
          <li>{strings.resetLinkExpiredReason2}</li>
          <li>{strings.resetLinkExpiredReason3}</li>
        </ul>
        {validEmail ? (
          <Button className='btn btn-blue btn-center' onClick={sendNewLink}>
            {strings.linkExpiredButton}
          </Button>
        ) : (
          <p>
            {strings.formatString(
              strings.resetLinkParagraph,
              <a href={`mailto:${brandConfig.contactEmail}`}>
                {brandConfig.contactEmail}
              </a>,
              <a href={`tel:${brandConfig.contactPhone}`}>
                {brandConfig.contactPhoneHtmlFormated}
              </a>
            )}
          </p>
        )}
      </ErrorPage>
    );
  };

  return loading ? (
    <LoadingMessage />
  ) : isResetLinkValid ? (
    renderForm()
  ) : (
    renderInvalidLink()
  );
};

export default ResetPassword;
