import { useSelector } from 'react-redux';
import { SERVER_URL } from 'constants';

const useClientPortalCreditCardUpdateLink = () => {
  const latestAcceptedLoan = useSelector(
    (state) => state?.memberPortal?.latestAcceptedLoan
  );
  if (latestAcceptedLoan) {
    return `${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${latestAcceptedLoan?.number}`;
  }
  return window.location.href;
};

export default useClientPortalCreditCardUpdateLink;
